import AppConfig from "../../constants/AppConfig";
import quizTypes from "../types/quiz";
import { summeryObj, getQuizSummery, getInitialQuizSummery } from "../../services/quizSummery";
import { getIdx, calculateElapsedTime } from "../../services/globalService";

export const initialState = {
  level: AppConfig.LEVEL0,
  prevCategory: "",
  currentCategory: "",
  questions: [],
  config: {},
  previousSession: {},
  Min_Questions: 0,
  categorySequence: ["Technical", "Presentation"],
  showSummary: false,
  submitAnswers: false,
  isquizLoading: false,
  quizSummery: summeryObj,
  sessionByLevel: "",
  codingChallengeSubmissionId: null,
  codingChallengeSubmissionDetail: null,
  testResult: null,
  codingInput: "",
  codingText: "",
  selectedAnswers: [],
  isPlaying: true,
  sessionReq: null,
  Time_Mins: 0,
  unansweredQuestions: 0,
  summary: [],
  quizSelectionTopic: "",
  quizSelectionPopupStatus: false,
  quizSelectionPopupDisable: false,
  elapsedTime: 0,
  userMetaDataReq: "",
  isFromEditProfile: false,
  levelIndicator: "",
  renderTimer: false,
  prevTime: 0,
  selectedQuesNdAns: [],
  apiError: null,
  l0testResult: null,
  codingStatus: [],
  statuscodingsubmission: null,
  testresultstatus: null,
  quizTimeout: "empty",
  candidateCameraCapture: null,
  deviceMetaDataPermission: {
    fullScreenMode: "required",
    geoLocationPermission: "required",
    cameraAndMicPermission: "required",
  },
};

function quiz(state = initialState, action) {
  // console.log("@reducer quiz", action.type);
  switch (action.type) {
    case quizTypes.GET_QUESTIONS:
    case quizTypes.GET_CODING_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
        config: action.config,
        Min_Questions: action?.config?.Min_Questions,
        isquizLoading: false,
        Time_Mins: action?.config?.Time_Mins,
        unansweredQuestions: action?.config?.Total_Questions,
        previousSession: action.previousSession,
        // categorySequence:action?.config["Sequence "].split(", "),
        quizSummery: getInitialQuizSummery(action.payload, ["Technical", "Presentation"], action.previousSession),
      };
    case quizTypes.GET_QUESTIONS_REQUESTED:
      return {
        ...state,
        isquizLoading: true,
      };
    case quizTypes.GET_TRACKING:
      return {
        ...state,
        tracking: action.payload,
        unansweredQuestions: state.quizSummery.totalQuestions - state.quizSummery.answeredQuestions,
        // isquizLoading: false,
      };
    case quizTypes.GET_TRACKING_REQUESTED:
      return {
        ...state,
        qTrackReq: action.payload,
        // isquizLoading: true,
      };
    case quizTypes.UPDATE_PREV_CATEGORY:
      return {
        ...state,
        prevCategory: action.payload,
      };
    case quizTypes.UPDATE_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      };
    case quizTypes.UPDATE_LEVEL:
      return {
        ...state,
        level: action.payload,
      };
    case quizTypes.UPDATE_SHOW_SUMMARY:
      return {
        ...state,
        showSummary: action.payload,
      };
    case quizTypes.UPDATE_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case quizTypes.SUBMIT_ANSWERS:
      return {
        ...state,
        submitAnswers: action.payload,
      };
    case quizTypes.CALCULATE_QUIZ_SUMMERY:
      return {
        ...state,
        quizSummery: getQuizSummery(action.payload, ["Technical", "Presentation"]),
        unansweredQuestions: state.quizSummery.totalQuestions - state.quizSummery.answeredQuestions,
      };
    case quizTypes.RESET_QUIZ_SUMMERY:
      return {
        ...state,
        sessionByLevel: "",
        // testResult: null,
        // testSubmissionReq: null,
        tracking: null,
        qTrackReq: null,
        submitAnswers: false,
        showSummary: false,
        selectedAnswers: [],
        sessionReq: null,
        summary: [],
        quizSummery: getQuizSummery("reset"),
      };
    case quizTypes.RESET_QUIZ_QUESTIONS:
      return {
        ...state,
        questions: [],
      };
    case quizTypes.RESET_TEST_RESULT:
      return {
        ...state,
        testResult: null,
        testresultstatus: null,
      };
    case quizTypes.CREATE_SESSION:
      return {
        ...state,
        sessionByLevel: action.payload,
        isquizLoading: false,
      };
    case quizTypes.CREATE_SESSION_REQUESTED:
      return {
        ...state,
        sessionReq: action.payload,
        isquizLoading: true,
      };
    case quizTypes.SUBMIT_CODING_CHALLENGE:
      return {
        ...state,
        codingChallengeSubmissionDetail: null,
        codingChallengeSubmissionId: null,
        codingStatus: null,
        challengeReq: action.payload,
        isquizLoading: true,
      };
    case quizTypes.CODING_CHALLENGE_SUBMITTED:
      return {
        ...state,
        codingChallengeSubmissionId: action.payload,
        isquizLoading: false,
      };
    case quizTypes.SUBMIT_CODING_CHALLENGE_STATUS:
      // console.log(action.payload)
      return {
        ...state,
        codingStatus: action.payload,
        isquizLoading: false,
      };
    case quizTypes.GET_CODING_SUBMISSION_DETAIL:
      return {
        ...state,
        challengeSubmissionReq: action.payload,
        statuscodingsubmission: null,
        isquizLoading: true,
      };
    case quizTypes.UPDATE_CODING_SUBMISSION_DETAIL:
      // console.log(action.payload)
      return {
        ...state,
        codingChallengeSubmissionDetail: action.payload,
        codingChallengeSubmissionId: null,
        statuscodingsubmission: null,
        isquizLoading: false,
      };
    case quizTypes.STATUS_CODING_SUBMISSION:
      return {
        ...state,
        isquizLoading: false,
        statuscodingsubmission: action.payload,
      };
    case quizTypes.RESET_CODING_SUBMISSION_DETAIL:
      return {
        ...state,
        codingChallengeSubmissionDetail: null,
        codingChallengeSubmissionId: null,
        codingStatus: null,
        isquizLoading: false,
        statuscodingsubmission: null,
      };
    case quizTypes.RESET_CODING:
      return {
        ...state,
        codingInput: "",
        codingText: "",
      };
    case quizTypes.SUBMIT_CODING_ANSWERS:
    case quizTypes.SUBMIT_TEST_RESULTS:
      return {
        ...state,
        testSubmissionReq: action.payload,
        isquizLoading: true,
      };
    case quizTypes.TEST_RESULTS_SUBMITTED:
      return {
        ...state,
        skills: action.payload.skills,
        sessionByLevel: "",
        questions: [],
        testResult: { ...state.l0testResult, ...action.payload },
        testresultstatus: null,
        isquizLoading: false,
      };
    case quizTypes.SUBMIT_RESULT_STATUS:
      return {
        ...state,
        isquizLoading: false,
        testresultstatus: action.payload,
      };

    case quizTypes.RECREATE_USER_SUBMIT_RESULTS:
      return {
        ...state,
        testSubmissionReq: action.testResult,
        tmpUserProfile: action.userProfile,
        isquizLoading: false,
      };

    case quizTypes.SET_CODING_TEXT:
      return {
        ...state,
        codingText: action.payload,
      };
    case quizTypes.SET_CODING_INPUT:
      return {
        ...state,
        codingInput: action.payload,
      };
    case quizTypes.SUBMIT_RESULTS:
      return {
        ...state,
        quesResults: action.payload,
        isquizLoading: false,
      };
    case quizTypes.UPDATE_SUBMITTED_ANSWER:
      state.selectedAnswers.push(action.payload);
      return {
        ...state,
      };
    case quizTypes.PUSH_SUBMITTED_ANSWER:
      var idx = getIdx("Question_ID", action.payload.Question_ID, state.selectedAnswers);
      if (idx > -1) {
        state.selectedAnswers.splice(idx, 1);
        state.selectedAnswers.splice(idx, 0, action.payload);
      } else {
        state.selectedAnswers.push(action.payload);
      }
      return {
        ...state,
      };
    case quizTypes.POP_SUBMITTED_ANSWER:
      state.selectedAnswers.pop();
      return {
        ...state,
      };
    case quizTypes.TIMER_PLAYING:
      return {
        ...state,
        isPlaying: action.payload,
      };
    case quizTypes.SET_TIMER:
      return {
        ...state,
        Time_Mins: action.payload,
      };
    case quizTypes.QUIZ_SELECTION_TOPIC:
      return {
        ...state,
        quizSelectionTopic: action.payload,
      };
    case quizTypes.QUIZ_SELECTION_POPUP_STATUS:
      return {
        ...state,
        quizSelectionPopupStatus: action.payload,
      };
    case quizTypes.QUIZ_SELECTION_POPUP_DISABLE:
      return {
        ...state,
        quizSelectionPopupDisable: action.payload,
      };
    case quizTypes.SELECT_LANGUAGE:
      return {
        ...state,
        select_language: action.payload,
      };
    case quizTypes.SET_USER_METADATA_REQ:
      return {
        ...state,
        userMetaDataReq: action.payload,
      };
    case quizTypes.SET_ELAPSED_TIME:
      return {
        ...state,
        elapsedTime: calculateElapsedTime(action.payload, state),
      };
    case quizTypes.RESET_ELAPSED_TIME:
      return {
        ...state,
        elapsedTime: 0,
      };
    case quizTypes.CLEAR_QUIZ_STATE:
      return {
        ...state,
        ...initialState,
      };
    case quizTypes.SET_EDIT_PROFILE:
      return {
        ...state,
        isFromEditProfile: action.payload,
      };
    case quizTypes.SET_SELECTED_QUES_ND_ANS:
      return {
        ...state,
        selectedQuesNdAns: action.payload,
      };
    case quizTypes.UPDATE_LEVEL_INDICATOR:
      return {
        ...state,
        levelIndicator: action.payload,
      };
    case quizTypes.SET_PREV_TIME:
      return {
        ...state,
        prevTime: action.payload,
      };
    case quizTypes.GET_L2_QUESTIONS:
      return {
        ...state,
        isquizLoading: true,
      };
    case quizTypes.GET_L3_QUESTIONS:
      return {
        ...state,
        isquizLoading: true,
      };
    case quizTypes.GET_L3_PRESENTATION:
      return {
        ...state,
        isquizLoading: true,
      };

    case quizTypes.CANDIDATE_CAMERA_CAPTURE_SUCCESS:
      return { ...state, candidateCameraCapture: action.payload };

    case quizTypes.GET_QUESTIONS: // FK cleanup duplicate case label july 2023
      return {
        ...state,
        isquizLoading: false,
      };
    case quizTypes.SET_API_ERROR:
      return { ...state, isquizLoading: false, apiError: action.payload };
    case quizTypes.SET_L0_TEST_RESULT:
      return { ...state, isquizLoading: false, l0testResult: action.payload };
    case quizTypes.UPDATE_QUIZ_TIMEOUT_FLAG:
      return {
        ...state,
        quizTimeout: action.payload,
      };
    default:
      return state;
  }
}

export default quiz;
