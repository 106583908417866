import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  title: {
    //width: '80%',
    height: "20px",
    color: "#787878",
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px !important",
    display: "table",

    "& .MuiTypography-root": {
      fontSize: "14px !important",
    },

    [theme.breakpoints.up("tablet")]: {
      /// display: 'flex',
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  circleParentCls: {
    [theme.breakpoints.up("tablet")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  circleChildCls: {
    height: 12,
    width: 12,
    borderRadius: "50%",
    border: "1px solid #C4C4C4",
    margin: "2px 2px",
  },
  sectionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
  },
  linksty: theme.typography.link2,
}));
const noOfStars = [...Array(7).keys()];
function Sections(props) {
  const classes = useStyles();
  const getColorCode = (dots) => {
    if (dots == 1) {
      return "#DD2E2E";
    } else if (dots == 2) {
      return "#FAC06A";
    } else if (dots == 3) {
      return "#FAC06A";
    } else if (dots == 4) {
      return "#FAC06A";
    } else if (dots == 5) {
      return "#6aa84f";
    } else if (dots == 6) {
      return "#6aa84f";
    } else if (dots == 7) {
      return "#6aa84f";
    } else {
      return "#0e4cea";
    }
  };
  return props?.data?.map((item) => {
    return (
      <div className={classes.sectionWrapper}>
        <Typography variant2="link2" className={`${classes.title}${classes.linksty}`}>
          {item.skill || item.Topic}
        </Typography>
        <div className={classes.circleParentCls}>
          {noOfStars?.map((rec, index) => {
            return (
              <div
                style={{ backgroundColor: index <= item.Dots - 1 ? getColorCode(item.Dots) : "" }}
                className={classes.circleChildCls}
              ></div>
            );
          })}
        </div>
      </div>
    );
  });
}
export default Sections;
