import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Link, Backdrop } from "@mui/material";
import "../../../pages/home/home.css";
import Button from "../../controls/Button";
import Timer from "../../Timer/Timer";
import { theme } from "../../../styles/theme";
import quiz from "../../../redux/actions/quiz";
import AppConfig from "../../../constants/AppConfig";
import Popup from "../../Popup";
import LOneExampleContent from "../../dialogContent/LOneExampleContent";
import Select from "../../controls/Select";
// import _ from "lodash";
import CodeEditor from "../../CodeEditor";
import auth from "../../../redux/actions/auth";
import { getObject, getObjectThatContains } from "../../../services/globalService";
import RoundedLoadingIndicator from "../../RoundedLoadingIndicator";
import LoadingMessages from "../../LoadingMessage";
import ExitDialog from "../../Exitmodel";
import SubmitConfirmationDialog from "../../../components/SubmitConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import BayoneLogo from "../../../assets/svg/bayonelogo.svg";
import ErrorSnackbar from "../../../components/controls/Errormessage";
// import { config } from '../../../config';
// import ReactWebcam from "../../ReactWebcam";
import SessionTracking from "../../SessionTracking";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    height: "100vh",
  },
  paddinght: {
    //paddingBottom:'85px'
  },
  paddingdt: {
    paddingBottom: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "64px !important",
      paddingRight: "64px !important",
    },
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  main: {
    marginLeft: "auto",
    marginRight: "auto",
    // width: "60%",
  },
  item1: {
    height: "60px",
    // position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  item2: {
    marginTop: "16px",
    paddingBottom: "16px",
    borderBottom: "0.5px solid #EBEFF8",
    height: 48,
  },
  item3: {
    // marginTop: "32px",
  },
  item4: {
    marginTop: "16px",
  },
  answer: {
    marginTop: "24px !important",
    marginBottom: "30px !important",
  },
  info: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
  },
  // center: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  item5: {
    height: "80px",
    // backgroundColor: "#ffffff",
  },
  pageInfo: {
    height: "36px",
    width: "145px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.main}`,
    marginTop: "36px",
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  next: {
    "& button": {
      float: "right",
    },
  },
  progess: {
    color: theme.palette.btnPrimary.tertiaryText,
    marginTop: "24px !important",
    marginBottom: "8px !important",
  },
  font16: {
    fontSize: theme.typography.link1.fontSize,
    color: theme.palette.btnPrimary.tertiaryText,
  },
  questionArea: {
    display: "flex",
  },
  qIndex: {
    // flex: "10%"
  },
  qTitle: {
    flex: "90%",
  },
  sidebar: {
    width: 500,
  },
  actionLink: {
    marginTop: 16,
  },
  questionDesc: {
    whiteSpace: "pre-wrap",
  },
  stickToBottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  justifyButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  nextBtn: {
    marginLeft: "5px",
    "& button": {
      //float: "left",
      height: 40,
    },
  },
  alignItemsCls: {
    alignItems: "center",
  },
  codeEditor: {
    //paddingTop: "0px !important",
    borderLeft: "0.5px solid #EBEFF8",
    paddingLeft: "16px",
  },
  logoCls: {
    height: "30px",
  },
  exit: {
    color: "#787878 !important",
    //theme.palette.neutrals.grayDark,
  },
  grayText: {
    color: "#787878 !important",
    //theme.palette.neutrals.grayDark,
  },
  loader: {
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: "50%",
  },
  regularText: theme.typography.b2Regular,
  textMedium: theme.typography.b1Medium,
  linkUnderline: {
    textDecoration: "none !important",
    marginRight: "10px !important",
  },
  webCam: {
    position: "absolute",
    bottom: "4rem",
    left: "auto",
  },
});
// FK cleanup comments july 2023
function CodingContainer(props) {
  // eslint-disable-next-line
  const { quizProp, routeParams, qIndex, ...other } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [exitopen, setExitopen] = useState(false);
  // const counterRef = useRef();
  const [langId, setLangId] = useState("");
  const [isPageChange, setPageChange] = useState(false);
  const roleState = useSelector((state) => state?.role);
  const { categoryTheme } = AppConfig;
  // const { l1 } = stepToWelcomeScreenMap;
  const dispatch = useDispatch();
  const history = useNavigate();
  // const theme = useTheme();
  const classes = useStyles();
  const quesRes = useSelector((state) => state.quiz.questions);
  // const qTrackRes = useSelector((state) => state.quiz.tracking);
  const authState = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);
  // let selectedLanguage = 'JavaScript';
  let [selectedLanguage, setSelectedLanguage] = useState("JavaScript");
  const [showCodingDialog, setShowCodingDialog] = useState(false);
  const [answeredQuestions, setAnsweredQuestion] = useState(0);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openshowBar, setOpenshowBar] = useState(false);
  const [submitAnswer, setSubmitAnswer] = useState(false);
  const codingChallengeSubmissionDetail = useSelector((state) => state.quiz.codingChallengeSubmissionDetail);
  const monacoRef = useRef(null);
  const [timerMin, setTimerMin] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [fullSubmit, setFullSubmit] = useState(false);
  const [submitDialogContent, setSubmitDialogContent] = useState("");
  const [isShowSubmitDialog, setShowSubmitDialog] = useState(false);
  const [timeoutType, setTimeoutType] = useState("");
  let message = `Your session is about to expire, hurry!`;
  let category = "Coding";
  let value = `${qIndex} of ${quesRes.length}`;
  let question = quizProp.quesObject.Question_Name;
  let questionDesc = quizProp.quesObject.Question_Description;
  const [timeoutMessage, setTimeoutMessage] = useState("");
  // let codeTpl = quizState.codingText || // fk cleanup comments july 2023
  //   quizProp.quesObject[
  //     AppConfig.compilerToTplFieldMap[authState.codingLang.lang]
  //   ];
  let questionID = quizProp.quesObject.Question_ID;
  let difficulty = quizProp.quesObject.Difficulty;
  let questionsDescription = quizProp.quesObject.Question_Description;
  let isLastQuestion = qIndex === quesRes.length;
  const themes = [
    { id: 1, value: "vs-dark", label: "Dark" },
    { id: 2, value: "light", label: "Light" },
  ];
  const langs = [
    // { id: 10, value: "java", label: "Java" },
    // { id: 56, value: "javascript", label: "JavaScript" },
    // { id: 116, value: "python", label: "Python 3" },
    // {id:114,value:"go",label:"Golang"},
    // {id:11,value:"c",label:"C"},
    // {id:86,value:"csharp",label:"C#"},
    // {id:1,value:"cpp",label:"C++"},
    // {id:39,value:"scala",label:"Scala"},
    // {id:117,value:"r",label:"R"},
    // {id:85,value:"swift",label:"Swift"},
    // {id:47,value:"kotlin",label:"Kotlin"},
  ];
  const codingLangArr = roleState?.candidateDashboardDetails?.jobRole?.codingLang || [];
  // eslint-disable-next-line
  const [logoUrl, setLogoUrl] = React.useState(process.env.REACT_APP_ASSETS_URL);
  const [domainDetails, setDomainDetails] = useState({});

  // Object.entries(authState?.skillsConfig).forEach(([key, value]) => {
  //   if (value.isLanguageSkill && value.isRoleSkill === true) {
  //     let template =  (value.fullName === "Python") ? "Python3_Template": `${value.fullName}_Template`;
  //     let label = value.fullName;
  //     if(codingLangArr.includes(value.fullName)) {
  //       langs.push({ id: value.speCompilerId, value: value.fullName, label: label, template: template});
  //     }
  //   }
  // });

  Object.entries(authState?.skillsConfig).forEach(([key, value]) => {
    if (value.isLanguageSkill === true) {
      let template = key === "Python" ? "Python3_Template" : `${key}_Template`;
      let label = value.fullName;
      if (codingLangArr.includes(key)) {
        langs.push({ id: value.speCompilerId, value: key, label: label, template: template });
      }
    }
  });

  // sort dropDown Languages
  langs.sort((a, b) => a.label.localeCompare(b.label));

  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  let candidatelist = [];
  if (authState?.candidatelist) {
    let arr = (authState?.candidatelist || []).filter((item, i) => item.uid === userDetails.userId);
    candidatelist.push(arr[0]);
  }

  useEffect(() => {
    if (quizState && quizState.questions) {
      window.addEventListener("load", function () {
        // TARGET THIS SECTION ONLY
        var target = document.getElementById("no-copy");

        // PREVENT CONTEXT MENU FROM OPENING
        target.addEventListener(
          "contextmenu",
          function (evt) {
            evt.preventDefault();
          },
          false
        );

        // PREVENT CLIPBOARD COPYING
        target.addEventListener(
          "copy",
          function (evt) {
            // Change the copied text if you want
            evt.clipboardData.setData("text/plain", "Copying is not allowed on this webpage");
            // Prevent the default copy action
            evt.preventDefault();
          },
          false
        );
      });
    }
    // eslint-disable-next-line
  }, [quizState.questions]);

  useEffect(() => {
    let msg;
    let unansweredQuestions = quizState?.config?.Total_Questions - answeredQuestions;

    if (unansweredQuestions > 0) {
      // msg = `You have run out of time and cannot make any more changes. You have not answered ${unansweredQuestions} questions.You will not be able to attempt it again. The test will automatically submit. `;
      if (timeoutType == "timeout") {
        msg = `You have run out of time and cannot make any more changes. You have not answered ${unansweredQuestions} questions. The test will automatically submit.`;
      } else if (timeoutType == "focusout") {
        msg = `This section was out of focus for more than the permitted time. Now it will automatically submit.`;
      }
    } else {
      // msg = `You have run out of time and cannot make any more changes. You have answered all questions.You will not be able to attempt it again. The test will automatically submit.`;

      if (timeoutType == "timeout") {
        msg = `You have run out of time and cannot make any more changes. You have answered all questions. The test will automatically submit.`;
      } else if (timeoutType == "focusout") {
        msg = `This section was out of focus for more than the permitted time. Now it will automatically submit.`;
      }
    }
    setTimeoutMessage(msg);
    // eslint-disable-next-line
  }, [timeoutType]); //[answeredQuestions]);

  useEffect(() => {
    if (selectedLanguage !== roleState?.candidateDashboardDetails?.jobRole?.codingLang) {
      setSelectedLanguage(roleState?.candidateDashboardDetails?.jobRole?.codingLang[0]);
    }
    let lang = getObjectThatContains("label", selectedLanguage, langs);
    let theme = getObject("label", "Dark", themes);
    if (lang) {
      setLangId(lang.id);
      dispatch(
        auth.setCodingLang({
          lang: lang.value,
          template: lang.template,
          langId: lang.id,
          cid: lang.id,
          // tpl: quizProp.quesObject[AppConfig.compilerToTplFieldMap[lang.value]],
          tpl: quizProp.quesObject[lang.template],
        })
      );
    }
    if (theme) {
      dispatch(
        auth.setCodingTheme({
          theme: theme.value,
          themeId: theme.id,
        })
      );
    }
    // eslint-disable-next-line
  }, [selectedLanguage]);

  useEffect(() => {
    if (quesRes && quesRes.length > qIndex && isPageChange === true) {
      dispatch(quiz.resetCoding());
      dispatch(quiz.resetCodingSubmissionDetail());
      history(`/CodingQuestions/${qIndex + 1}`);
    }
    setPageChange(false);
    // eslint-disable-next-line
  }, [isPageChange]);

  useEffect(() => {
    if (qIndex === 1) {
      dispatch(quiz.setPrevTime(quizState?.config?.Time_Mins * 60));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (quizState.submitAnswers) {
      let contentText = `You cannot attempt this section again if you submit it now. Are you sure you want to submit?`;
      setSubmitDialogContent(contentText);
      setShowSubmitDialog(true);
      return;
    }
  }, [quizState.submitAnswers]);

  useEffect(() => {
    if (quizState.testResult && quesRes.length === 0) {
      dispatch(quiz.resetCoding());
      dispatch(quiz.resetCodingSubmissionDetail());
      history("/candidatedashboard");
      // globalService.resetData();
    }
    setLoadingIndicator(false);
    // eslint-disable-next-line
  }, [quizState.testResult]);

  useEffect(() => {
    if (quizState.apiError && loadingIndicator) {
      setLoadingIndicator(false);
    }
    // eslint-disable-next-line
  }, [quizState.apiError]);

  // AR - code commented on 2023-12-15
  // **
  // useEffect(() => {
  //   if (roleState && roleState?.candidateDashboardDetails) {
  //     let tempCount = 0;
  //     if (
  //       !roleState?.candidateDashboardDetails?.L3ProfileScore ||
  //       (roleState?.candidateDashboardDetails?.L3ProfileScore &&
  //         roleState?.candidateDashboardDetails?.L3ProfileScore === 0)
  //     ) {
  //       tempCount += 1;
  //     }
  //     if (
  //       !roleState?.candidateDashboardDetails?.L3CodingScore ||
  //       (roleState?.candidateDashboardDetails?.L3CodingScore &&
  //         roleState?.candidateDashboardDetails?.L3CodingScore === 0)
  //     ) {
  //       tempCount += 1;
  //     }
  //     if (
  //       !roleState?.candidateDashboardDetails?.L2TechnicalScore ||
  //       (roleState?.candidateDashboardDetails?.L2TechnicalScore &&
  //         roleState?.candidateDashboardDetails?.L2TechnicalScore === 0)
  //     ) {
  //       tempCount += 1;
  //     }
  //     if (
  //       !roleState?.candidateDashboardDetails?.L3PresentationScore ||
  //       (roleState?.candidateDashboardDetails?.L3PresentationScore &&
  //         roleState?.candidateDashboardDetails?.L3PresentationScore === 0)
  //     ) {
  //       tempCount += 1;
  //     }
  //     if (tempCount === 0) {
  //       history("/CodingQuestions/1");
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [authState?.candidatedetails]);

  const onYesContinue = () => {
    props.isSubmitted(true);
    setFullSubmit(true);
    var ansLn = quizState.selectedAnswers.length;
    var result = [];
    for (var i = 0; i < ansLn; i++) {
      var o = quizState.selectedAnswers[i];
      result.push(o);
    }

    setLoadingIndicator(true);
    dispatch(
      quiz.submitCodingResults({
        code: quizState.sessionByLevel,
        email: roleState?.candidateDashboardDetails?.candidate?.user?.email,
        result: result,
        inviteID: roleState?.candidateDashboardDetails?.uid,
      })
    );
  };

  const calcTimeTaken = () => {
    let timeRemainingSec = quizState?.prevTime;
    if (timerMin) {
      let currentTimeArray = timerMin.split(".");
      timeRemainingSec = Number(currentTimeArray[0]) * 60 + Number(currentTimeArray[1]);
      // console.log("values",timeRemainingSec) // FK cleanup july 2023
    }
    let timeTakenSec = quizState?.prevTime - timeRemainingSec;
    let timeTaken = timeTakenSec / 60;
    dispatch(quiz.setPrevTime(timeRemainingSec));
    return timeTaken;
  };

  const handleTrackingReq = (eventType) => {
    let ansObj = {
      Question_ID: questionID,
      compilerId: authState.codingLang.langId,
      source: quizState.codingText,
      input: quizState.codingInput,
      language: authState.codingLang.lang,
      difficulty,
      questionsDescription,
    };

    let qTrackReq = {
      Question_ID: questionID,
      code: quizState.sessionByLevel,
      timeTaken: calcTimeTaken(),
      level: "coding",
      isAnswered: "",
      uid: roleState?.candidateDashboardDetails ? roleState?.candidateDashboardDetails?.uid : "notregistered",
      email: roleState?.candidateDashboardDetails
        ? roleState?.candidateDashboardDetails?.candidate?.user?.email
        : "notregistered",
      userAns: ansObj,
      score: "0",
      inviteId: roleState?.candidateDashboardDetails?.uid,
      eventType: eventType,
    };

    dispatch(quiz.getTrackingRequested(qTrackReq));
  };

  const onClickNext = () => {
    monacoRef.current.setScrollPosition({ scrollTop: 0 });
    setPageChange(true);
    if (questionID) {
      let ansObj = {
        Question_ID: questionID,
        compilerId: authState.codingLang.langId,
        source: quizState.codingText,
        input: quizState.codingInput,
        language: authState.codingLang.lang,
        difficulty,
        questionsDescription,
      };

      handleTrackingReq("next");

      dispatch(quiz.pushAnswer(ansObj));
    }

    if (quesRes.length === qIndex) {
      dispatch(quiz.submitAnswers(true));
      // FK cleanup comments july 2023
      // let Timetaken = calcTimeTaken()
      // console.log("hghjdfg",Timetaken)
      // setFullSubmit(true);
      // var ansLn = quizState.selectedAnswers.length;
      // var result = [];
      // for (var i = 0; i < ansLn; i++) {
      //   var o = quizState.selectedAnswers[i];
      //   result.push(o);
      // }

      // setLoadingIndicator(true);
      // dispatch(
      //   quiz.submitCodingResults({
      //     code: quizState.sessionByLevel,
      //     email: roleState?.candidateDashboardDetails?.candidate?.user?.email,
      //     result: result,
      //     inviteID:roleState?.candidateDashboardDetails?.uid
      //   })
      // );
    }
    // } else {
    //   setOpenshowBar(true);
    // }
  };

  const onClickPrev = () => {
    if (navigator.onLine) {
      monacoRef.current.setScrollPosition({ scrollTop: 0 });
      dispatch(quiz.resetCoding());
      dispatch(quiz.resetCodingSubmissionDetail());
      if (quesRes && qIndex > 1) {
        if (questionID) {
          let ansObj = {
            Question_ID: questionID,
            compilerId: authState.codingLang.langId,
            source: quizState.codingText,
            input: quizState.codingInput,
            language: authState.codingLang.lang,
            difficulty,
            questionsDescription,
          };

          handleTrackingReq("prev");

          dispatch(quiz.pushAnswer(ansObj));
        }
        history(`/CodingQuestions/${qIndex - 1}`);
      }
    } else {
      setOpenshowBar(true);
    }
  };

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      // let Timetaken = calcTimeTaken()
      // console.log("hghjdfg",Timetaken)
      setFullSubmit(true);
      var ansLn = quizState.selectedAnswers.length;
      var result = [];
      for (var i = 0; i < ansLn; i++) {
        var o = quizState.selectedAnswers[i];
        result.push(o);
      }

      setLoadingIndicator(true);
      dispatch(
        quiz.submitCodingResults({
          code: quizState.sessionByLevel,
          email: roleState?.candidateDashboardDetails?.candidate?.user?.email,
          result: result,
          inviteID: roleState?.candidateDashboardDetails?.uid,
        })
      );
      // if(event.returnValue ==''){
      //   submitAllAnswers()
      //  }
      // else {
      //   submitAllAnswers();
      // }
      //
      let message = "Are you sure you want to exit?";
      event.returnValue = message;
      return message;
      // return (event.returnValue =
      //   'Are you sure you want to exit?');
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
    // eslint-disable-next-line
  }, []);

  const handleExit = () => {
    props.isSubmitted(true);
    // let Timetaken = calcTimeTaken()
    // console.log("hghjdfg",Timetaken)
    setFullSubmit(true);
    var ansLn = quizState.selectedAnswers.length;
    var result = [];
    for (var i = 0; i < ansLn; i++) {
      var o = quizState.selectedAnswers[i];
      result.push(o);
    }

    handleTrackingReq("exit");

    setLoadingIndicator(true);
    dispatch(
      quiz.submitCodingResults({
        code: quizState.sessionByLevel,
        email: roleState?.candidateDashboardDetails?.candidate?.user?.email,
        result: result,
        inviteID: roleState?.candidateDashboardDetails?.uid,
      })
    );
    setExitopen(false);
  };

  const handleclick = () => {
    if (navigator.onLine) {
      setExitopen(true);
    } else {
      setOpenshowBar(true);
    }
  };

  const onThemeChange = (e) => {
    var val = e.target.value;
    var o = getObject("id", val, themes);
    dispatch(
      auth.setCodingTheme({
        theme: o.value,
        themeId: o.id,
      })
    );
  };

  const handleClose = () => {};

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const onLangChange = (e) => {
    dispatch(quiz.updateCodingSubmissionDetail(null));
    setLangId("");
    var val = e.target.value;
    var o = getObject("id", val, langs);
    // var tpl = quizProp.quesObject[AppConfig.compilerToTplFieldMap[o.value]];
    var tpl = quizProp.quesObject[o.template];
    setLangId(o.id);
    dispatch(
      auth.setCodingLang({
        lang: o.value,
        template: o.template,
        langId: o.id,
        cid: o.id,
        tpl: tpl,
      })
    );
  };

  const compileAndRun = () => {
    if (quizState.codingInput.trim() !== "") {
      handleTrackingReq("compileAndRun");
      setLoadingIndicator(true);
      dispatch(
        quiz.submitCodingChallenge({
          compilerId: authState.codingLang.cid,
          source: quizState.codingText,
          input: quizState.codingInput,
        })
      );
    } else {
      setToastMessage("Please provide input value");
      setOpenToast(true);
    }
  };

  useEffect(() => {
    if (quizState?.tracking && quizState?.codingChallengeSubmissionDetail) {
      dispatch(
        quiz.updateCodingCompileAndRunTrackingDetails({
          trackingDocId: quizState?.tracking._id,
          userAns: [{ ...quizState?.tracking?.userAns[0], ...quizState?.codingChallengeSubmissionDetail }],
        })
      );
    }
    // eslint-disable-next-line
  }, [quizState?.codingChallengeSubmissionDetail]);

  function setOpenFunc() {
    setOpenToast(false);
  }

  useEffect(() => {
    if (quizState?.codingStatus?.status === 1) {
      setToastMessage(quizState?.codingStatus?.message);
      setOpenToast(true);
    }
    // eslint-disable-next-line
  }, [quizState?.codingStatus?.status]);

  useEffect(() => {
    if (quizState?.statuscodingsubmission?.status === 1) {
      setLoadingIndicator(false);
      setToastMessage("Submission resource not found");
      setOpenToast(true);
    }
  }, [quizState?.statuscodingsubmission?.status]);

  useEffect(() => {
    if (quizState?.testresultstatus?.status === 1) {
      setLoadingIndicator(false);
    }
  }, [quizState?.testresultstatus?.status]);

  const timeValue = (time, minutes, seconds) => {
    if (time) {
      let sec = +seconds;
      let val = `${minutes}.${sec}`;
      setTimerMin(val);
    }
  };

  useEffect(() => {
    if (openSnackBar === true || submitAnswer === true) {
      if (questionID) {
        let ansObj = {
          Question_ID: questionID,
          compilerId: authState.codingLang.langId,
          source: quizState.codingText,
          input: quizState.codingInput,
        };
        dispatch(quiz.pushAnswer(ansObj));
      }
      setSubmitAnswer(false);
    }
    // eslint-disable-next-line
  }, [openSnackBar, submitAnswer]);

  const handleOnWarning = (remainingTime) => {
    setOpenSnackBar(true);
  };

  const handleTimeAndFocusOut = (flagMsg) => {
    if (!submitAnswer) {
      props.isSubmitted(true);
      setSubmitAnswer(true);
      handleTrackingReq(flagMsg);
      setTimeout(() => {
        if (!loadingIndicator) {
          if (quizState && quizState.selectedAnswers.length === 0) {
            // history("/TimeOutCaptureScreen");
            let tempUnQues1 = quizState.selectedAnswers.length;
            setAnsweredQuestion(tempUnQues1);
            setShowCodingDialog(true);
          } else if (quizState && quizState.selectedAnswers.length >= quizState.Min_Questions) {
            let tempUnQues = quizState.questions.length;
            if (quizState.selectedAnswers && quizState.selectedAnswers.length > 0) {
              tempUnQues = quizState.selectedAnswers.length;
            }
            // let tempunanswer = tempUnQues <=2 ? tempUnQues - 1 : tempUnQues
            setAnsweredQuestion(tempUnQues);
            setShowCodingDialog(true);
          }
        }
      }, 600);
    }
  };

  const handleOnTimeout = () => {
    setTimeoutType("timeout");
    dispatch(
      quiz.updateQuizTimeoutFlag({
        type: "Timeout",
        msg: "The time allocated for the test is over. The Coding section is submitted automatically.",
      })
    );
    handleTimeAndFocusOut("timeout");
  };

  const handleOnFocusout = () => {
    setTimeoutType("focusout");
    dispatch(
      quiz.updateQuizTimeoutFlag({
        type: "Focusout",
        msg: "You moved out of the application for more than the permitted time. The Coding section is submitted automatically.",
      })
    );
    handleTimeAndFocusOut("focustimeout");
  };

  useEffect(() => {
    if (showCodingDialog === true) {
      setTimeout(() => {
        onClickContinue();
      }, 3000);
    }
    // eslint-disable-next-line
  }, [showCodingDialog]);

  useEffect(() => {
    if (codingChallengeSubmissionDetail) {
      setLoadingIndicator(false);
    }
  }, [codingChallengeSubmissionDetail]);

  const cancelConfirmDialog = () => {
    setShowSubmitDialog(false);
    dispatch(quiz.submitAnswers(false));
  };

  const submitAllAnswers = () => {
    if (!submitAnswer) {
      props.isSubmitted(true);
      // let Timetaken = calcTimeTaken()
      // console.log("hghjdfg",Timetaken)
      setFullSubmit(true);
      setSubmitAnswer(true);
      setShowSubmitDialog(false);
      var ansLn = quizState.selectedAnswers.length;
      var result = [];
      for (var i = 0; i < ansLn; i++) {
        var o = quizState.selectedAnswers[i];
        result.push(o);
      }

      handleTrackingReq("submit");

      setLoadingIndicator(true);
      dispatch(
        quiz.submitCodingResults({
          code: quizState.sessionByLevel,
          email: roleState?.candidateDashboardDetails?.candidate?.user?.email,
          result: result,
          inviteID: roleState?.candidateDashboardDetails?.uid,
        })
      );
    }
    //dispatch(quiz.submitAnswers(false));
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        scrollerHt = window.innerHeight - 80 - 80;
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();
  let scrollerHt = window.innerHeight - 80 - 80;

  const onClickContinue = () => {
    // if (
    //   quizState &&
    //   quizState.selectedAnswers.length >= quizState.Min_Questions
    // ) {
    setShowCodingDialog(false);
    onYesContinue();
    //}
  };

  const handleDialogExit = () => {
    setShowCodingDialog(false);
    quizState.selectedAnswers = [];
    history("/candidatedashboard");
  };

  const handleCloseToast = () => {
    setOpenshowBar(false);
  };

  useEffect(() => {
    if (props.onUnexpectedBehaviour === true) {
      // submitAllAnswers();
      props.isSubmitted(true);

      // handleTrackingReq('focusout');

      handleOnFocusout();
    }
    // eslint-disable-next-line
  }, [props.onUnexpectedBehaviour]);

  // Tracking Focus out and In
  useEffect(() => {
    if (!props.onAppFocusOut) {
      handleTrackingReq("focusout");
    } else {
      handleTrackingReq("focusin");
    }
  }, [props.onAppFocusOut]);

  useEffect(() => {
    if (authState.logoDetails?.logo == "") {
      setDomainDetails({ logo: localStorage.getItem("logo"), displayName: "" });
    }
  }, [localStorage.getItem("logo")]);

  return (
    <div>
      <div className={`${classes.root}`}>
        <div className={`${classes.item1}   ${classes.center} `}>
          <Container maxWidth="xl" className={classes.container}>
            <div className={classes.header}>
              <Grid container className="Home-hero">
                <Grid item xs={4} onClick={handleclick} style={{ cursor: "pointer" }}>
                  {/* <img src={BayoneLogo} alt={"Bayone Logo"} /> */}
                  <div>
                    {domainDetails && domainDetails?.logo !== "" ? (
                      <>
                        <img
                          height="33"
                          width="157"
                          src={`${logoUrl}${domainDetails?.logo}`}
                          alt={domainDetails?.displayName}
                        />
                        <br />
                        <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
                      </>
                    ) : (
                      <a href="https://techscore.ai" target="_self" rel="noreferrer">
                        <img height="50" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
                      </a>
                    )}
                  </div>
                  <div className={classes.webCam} onClick={(e) => e.stopPropagation()}>
                    <SessionTracking
                      inviteId={roleState?.candidateDashboardDetails?.uid}
                      sessionCode={quizState.sessionByLevel}
                    />
                    {/* <ReactWebcam /> */}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Timer
                    timeValue={timeValue}
                    handleOnWarning={handleOnWarning}
                    handleOnTimeout={handleOnTimeout}
                  ></Timer>
                </Grid>
                <Grid item xs={4} className={classes.exitBtn}>
                  <Link onClick={handleclick} className={classes.linkUnderline} style={{ cursor: "pointer" }}>
                    <Typography
                      // component={Text}
                      variant2="link2"
                      align="right"
                      className={classes.exit}
                      style={theme.typography.link2}
                    >
                      Exit
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container maxWidth="xl" className={classes.container}>
          <Grid container alignItems="stretch">
            <Grid item xs={5} className={classes.questionDesc}>
              <div className="Scroll" style={{ height: `${scrollerHt}px` }}>
                <div style={{ paddingRight: "16px", marginBottom: "200px" }}>
                  <div className={classes.item3}>
                    <div
                      className={`${classes.pageInfo} ${classes.center}`}
                      style={{
                        border: categoryTheme.hasOwnProperty(category)
                          ? `1px solid ${categoryTheme[category].bgColor}`
                          : "",
                      }}
                    >
                      <Typography
                        // component={Text}
                        variant2="b2Medium"
                        align="center"
                        style={{
                          color: categoryTheme.hasOwnProperty(category) ? `${categoryTheme[category].bgColor}` : "",
                          margin: "0px 10px",
                        }}
                      >
                        {category} Section
                      </Typography>
                    </div>
                  </div>

                  <Typography
                    // component={Text}
                    variant2="b2Regular"
                    className={`${classes.progess} ${classes.regularText}`}
                    align="left"
                  >
                    Problem {`${value}`}
                  </Typography>

                  <div className={classes.question}>
                    <div className={classes.questionArea}>
                      <div className={classes.qText}>
                        <Typography
                          // component={Text}
                          variant2="b1Medium"
                          align="left"
                          id="no-copy"
                          className={classes.textMedium}
                        >
                          {question}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <Typography
                    // component={Text}
                    variant2="b2Regular"
                    className={`${classes.title}   ${classes.answer} ${classes.regularText}`}
                    align="left"
                    id="no-copy"
                  >
                    {questionDesc} <br />
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={7} className={`${classes.codeEditor}`}>
              <Grid container className={classes.item2} style={{ paddingRight: "3px" }}>
                <Grid container xs={12} justifyContent="flex-end" className={`${classes.alignItemsCls}`}>
                  <Typography
                    // component={Text}
                    variant2="b2Regular"
                    align="left"
                    className={`${classes.grayText} ${classes.regularText}`}
                  >
                    Selected language:
                  </Typography>
                  <Select
                    options={langs}
                    displayField="label"
                    label="Selected language"
                    value={langId ? langId : authState.codingLang.langId}
                    onChange={onLangChange}
                    // disable={true}
                  ></Select>
                  <Typography
                    // component={Text}
                    variant2="b2Regular"
                    align="left"
                    style={{ marginLeft: "32px" }}
                    className={`${classes.grayText} ${classes.regularText}`}
                  >
                    Selected theme:
                  </Typography>
                  <Select
                    options={themes}
                    displayField="label"
                    label="Selected theme"
                    value={authState.codingTheme.themeId}
                    onChange={onThemeChange}
                    disable={false}
                  ></Select>
                </Grid>
              </Grid>
              <CodeEditor
                isFullSubmit={fullSubmit}
                question={quizProp.quesObject}
                quizState={quizState}
                height={height}
                width={width}
                monacoRef={monacoRef}
              ></CodeEditor>
            </Grid>
          </Grid>
        </Container>
        <div className={`${classes.item5} ${classes.center} ${classes.stickToBottom}`}>
          <Container maxWidth="xl" className={classes.container}>
            <div className={classes.main}>
              <Grid container direction="row " className="Home-hero" justifyContent="flex-end" spacing={4}>
                <Grid item xs={5}>
                  {qIndex && qIndex > 1 ? (
                    <Link className={classes.linkUnderline}>
                      <Typography
                        // component={Text}
                        className={classes.font16}
                        variant2="link2"
                        align="left"
                        onClick={() => onClickPrev()}
                        data-rt-div-codeprevious="codeprevious"
                      >
                        Previous
                      </Typography>
                    </Link>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={3}>
                  <div className={`${classes.nextBtn}`}>
                    <Button
                      color={
                        quizState.codingChallengeSubmissionDetail?.status === "accepted" ? "btnSecondary" : "btnPrimary"
                      }
                      text="Compile & Run Test"
                      width="200px"
                      onClick={compileAndRun}
                      data-rt-div-compileandrun="compileandrun"
                    ></Button>
                  </div>
                </Grid>
                <Grid item xs={4} className={`${classes.justifyButtons}`}>
                  <div className={`${classes.nextBtn}`}>
                    <Button
                      color={
                        quizState?.codingChallengeSubmissionDetail?.status === "accepted"
                          ? "btnPrimary"
                          : "btnSecondary"
                      }
                      text={!isLastQuestion ? "Next" : "Submit All Solutions"}
                      width="200px"
                      disabled={false}
                      onClick={() => onClickNext()}
                      data-rt-div-codenext="codenext"
                    ></Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <LOneExampleContent openPopup={openPopup} setOpenPopup={setOpenPopup}></LOneExampleContent>
      </Popup>
      <Backdrop className={classes.backdrop} open={loadingIndicator} onClick={handleClose}>
        {loadingIndicator && (
          <div className={`${classes.roundedLoader}`}>
            <RoundedLoadingIndicator></RoundedLoadingIndicator>
            {fullSubmit === true ? (
              <LoadingMessages
                message={[
                  { msg: "Compiling", ms: 1500 },
                  { msg: "Running", ms: 1500 },
                  { msg: "Verifying", ms: 1500 },
                  { msg: "Scoring", ms: 1500 },
                ]}
              ></LoadingMessages>
            ) : (
              ""
            )}
          </div>
        )}
      </Backdrop>
      <ExitDialog
        open={exitopen}
        OnExit={handleExit}
        onClose={() => setExitopen(false)}
        category={category}
        TimeClose={() => setOpenshowBar(true)}
      ></ExitDialog>
      <SubmitConfirmationDialog
        open={isShowSubmitDialog}
        text={submitDialogContent}
        action=""
        onSubmit={submitAllAnswers}
        onClose={cancelConfirmDialog}
        TimeClose={() => setOpenSnackBar(true)}
      ></SubmitConfirmationDialog>
      <SubmitConfirmationDialog
        open={showCodingDialog}
        text={timeoutMessage}
        succesBtnLabel="Yes, submit"
        onSubmit={onClickContinue}
        onClose={handleDialogExit}
        heading={timeoutType == "focusout" ? "Focusout!!" : timeoutType == "timeout" ? "Timeout!!" : ""}
        TimoutText={"Autosubmit"}
        TimeClose={() => setOpenshowBar(true)}
      ></SubmitConfirmationDialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openshowBar}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <ErrorSnackbar
        open={openToast}
        setOpen={() => setOpenFunc()}
        vertical={"bottom"}
        horizontal={"left"}
        severity="error"
        message={toastMessage}
      />
    </div>
  );
}
export default CodingContainer;
