import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../../../components/controls/Button";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const useStyles = makeStyles((theme) => ({
  dialogPoints: {
    display: "flex",
    gap: "20px !important",
    marginTop: "30px",
    alignItems: "center",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "18px !important",
    color: `${theme.palette.text.primary} !important`,
  },
  successIcon: {
    color: "green",
    fontSize: "30px",
  },
  errorIcon: {
    color: "red",
    fontSize: "30px",
  },
}));

const PermissionsDialog = ({
  openDialog,
  loading,
  geoLocation,
  latitude,
  longitude,
  cameraPermission,
  loadingFace,
  faceDetected,
  loadingVoice,
  voiceDetected,
  networkToast,
  handleDialog,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={openDialog} maxWidth="md" PaperProps={{ style: { width: "80%" } }}>
      <DialogTitle>Camera, Mic and Location</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Grid className={classes.dialogPoints} sx={{ marginTop: 0 }}>
            {loading ? (
              <CircularProgress size={24} />
            ) : geoLocation ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>Getting geolocation permission.</Typography>
          </Grid>
          <Grid className={classes.dialogPoints}>
            {loading ? (
              <CircularProgress size={24} />
            ) : latitude !== null && longitude !== null ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>Getting coordinates and location.</Typography>
          </Grid>
          <Grid className={classes.dialogPoints}>
            {loading ? (
              <CircularProgress size={24} />
            ) : cameraPermission ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>Getting camera and mic permissions.</Typography>
          </Grid>
          <Grid className={classes.dialogPoints}>
            {loadingFace ? (
              <CircularProgress size={24} />
            ) : faceDetected ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>Detecting human face. Please wave at the camera.</Typography>
          </Grid>
          <Grid className={classes.dialogPoints}>
            {loadingVoice ? (
              <CircularProgress size={24} />
            ) : voiceDetected ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>
              Detecting human voice. Say "Hello! I am ready for this test".
            </Typography>
          </Grid>
          {loading === false &&
          loadingFace === false &&
          loadingVoice === false &&
          (!geoLocation || !cameraPermission || !faceDetected || !voiceDetected) ? (
            <Typography sx={{ color: "red", mt: 5, ml: "45px" }}>
              We couldn't capture some of the required information. It is recommended to fix them before proceeding. You
              may continue but it may affect your test evaluation.
            </Typography>
          ) : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent:
            loading === false &&
            loadingFace === false &&
            loadingVoice === false &&
            (!geoLocation || !cameraPermission || !faceDetected || !voiceDetected)
              ? "space-between"
              : "flex-end",
        }}
      >
        {loading === false &&
        loadingFace === false &&
        loadingVoice === false &&
        (!geoLocation || !cameraPermission || !faceDetected || !voiceDetected) ? (
          <Grid sx={{ my: 2, mx: 2 }}>
            <Link
              onClick={networkToast}
              data-rt-opening-notreadytostart="notreadytostart"
              className={classes.linkUnderline}
            >
              <Typography variant2={"link1"} align="center" style={{ cursor: "pointer" }} className={classes.linktext}>
                Go back to fix permissions
              </Typography>
            </Link>
          </Grid>
        ) : null}
        <Button
          sx={{ my: 2, mx: 2 }}
          onClick={handleDialog}
          text="Continue to Instructions"
          color={loading || loadingFace || loadingVoice ? "disablebtn1" : "btn1"}
          disabled={loading || loadingFace || loadingVoice}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PermissionsDialog;
