const authTypes = {
  GET_LOGO: "GET_LOGO",
  LOGO_SUCCESS: "LOGO_SUCCESS",
  LOGIN_USER: "LOGIN_USER",
  CANDIDATE_LOGIN: "CANDIDATE_LOGIN",
  SIGNUP_USER: "SIGNUP_USER",
  SIGNUP_USER_SUCCESS: "SIGNUP_USER_SUCCESS",
  SIGNUP_USER_FAILURE: "SIGNUP_USER_FAILURE",
  ACCEPT_INVITATION: "ACCEPT_INVITATION",
  ACCEPT_INVITATION_SUCCESS: "ACCEPT_INVITATION_SUCCESS",
  ACCEPT_INVITATION_FAILURE: "ACCEPT_INVITATION_FAILURE",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAILURE: "FORGET_PASSWORD_FAILURE",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
  GET_PROFILE: "GET_PROFILE",
  UPDATE_LEVEL: "UPDATE_LEVEL",
  CLEAR_STATE: "CLEAR_STATE",
  RECRUITURMENT_LIST: "RECRUITURMENT_LIST",
  RECRUITURMENT_LIST_SUCCESS: "RECRUITURMENT_LIST_SUCCESS",
  RECRUITURMENT_LIST_RESET: "RECRUITURMENT_LIST_RESET",
  ADD_RECRUITURMENT: "ADD_RECRUITURMENT",
  ADD_RECRUITURMENT_SUCCESS: "ADD_RECRUITURMENT_SUCCESS",
  CANDIDATE_LIST: "CANDIDATE_LIST",
  CANDIDATE_LIST_SUCCESS: "CANDIDATE_LIST_SUCCESS",
  CANDIDATE_LIST_RESET: "CANDIDATE_LIST_RESET",
  ADD_CANDIDATE: "ADD_CANDIDATE",
  ADD_CANDIDATE_SUCCESS: "ADD_CANDIDATE_SUCCESS",
  DELETE_RECRUTER: "DELETE_RECRUTER",
  DELETE_RECRUTER_SUCCESS: "DELETE_RECRUITER_SUCCESS",
  DELETE_CANDIDATE: "DELETE_CANDIDATE",
  DELETE_CANDIDATE_SUCCESS: "DELETE_CANDIDATE_SUCCESS",
  EDIT_RECRUITER: "EDIT_RECRUITER",
  EDIT_RECRUITER_SUCCESS: "EDIT_RECRUITER_SUCCESS",
  EDIT_CANDIDATE: "EDIT_CANDIDATE",
  EDIT_CANDIDATE_SUCCESS: "EDIT_CANDIDATE_SUCCESS",
  SET_CODING_THEME: "SET_CODING_THEME",
  SET_CODING_LANG: "SET_CODING_LANG",
  RESUME_UPLOAD: "RESUME_UPLOAD",
  DELETE_RESUME: "DELETE_RESUME",
  RESUME_UPLOAD_REQUESTED: "RESUME_UPLOAD_REQUESTED",
  RESUME_UPLOAD_FAILURE: "RESUME_UPLOAD_FAILURE",
  DELETE_RESUME_REQUESTED: "DELETE_RESUME_REQUESTED",
  DELETE_RESUME_SUCCESS: "DELETE_RESUME_SUCCESS",
  TEST_COMPLETED: "TEST_COMPLETED",
  TEST_COMPLETED_SUCCESS: "TEST_COMPLETED_SUCCESS",
  CANDIDATE_DETAILS: "CANDIDATE_DETAILS",
  CANDIDATE_DETAILS_SUCCESS: "CANDIDATE_DETAILS_SUCCESS",
  CANDIDATE_DETAILS_FAILURE: "CANDIDATE_DETAILS_FAILURE",
  CANDIDATE_PROFILE: "CANDIDATE_PROFILE",
  CANDIDATE_PROFILE_SUCCESS: "CANDIDATE_PrOFILE_SUCCESS",
  RECRUITER_DETAILS: "RECRUITER_DETAILS",
  RECRUITER_DETAILS_SUCCESS: "RECRUITER_DETAILS_SUCCESS",
  RECRUITER_DETAILS_FAILURE: "RECRUITER_DETAILS_FAILURE",
  GOT_PROFILE: "GOT_PROFILE",
  USER_EXISTS: "USER_EXISTS",
  ACTIVATE_CANDIDATE: "ACTIVATE_CANDIDATE",
  ACTIVATE_CANDIDATE_SUCCESS: "ACTIVATE_CANDIDATE_SUCCESS",
  ACTIVATE_RECRUITER: "ACTIVATE_RECRUITER",
  ACTIVATE_RECRUITER_SUCCESS: "ACTIVATE_RECRUITER_SUCCESS",
  RECRUITER_FILTER: "RECRUITER_FILTER",
  RECRUITER_FILTER_SUCCESS: "RECRUITER_FILTER_SUCCESS",
  RECRUITER_LIST_TOTAL: "RECRUITER_LIST_TOTAL",
  RECRUITER_DASHBOARD: "RECRUITER_DASHBOARD",
  RECRUITER_DASHBOARD_SUCCESS: "RECRUITER_DASHBOARD_SUCCESS",
  CANDIDATE_FILTER: "CANDIDATE_FILTER",
  CANDIDATE_FILTER_SUCCESS: "CANDIDATE_FILTER_SUCCESS",
  CANDIDATE_UPLOAD_RESUME: "CANDIDATE_UPLOAD_RESUME",
  CANDIDATE_TOTAL_COUNT: "CANDIDATE_TOTAL_COUNT",
  CANDIDATE_MY_COUNT: "CANDIDATE_MY_COUNT",
  CANDIDATE_ALL_COUNT: "CANDIDATE_ALL_COUNT",
  CANDIDATE_UPLOAD_RESUME_SUCCESS: "CANDIDATE_UPLOAD_RESUME_SUCCESS",
  CANDIDATE_REPORT: "CANDIDATE_REPORT",
  CANDIDATE_REPORT_SUCCESS: "CANDIDATE_REPORT_SUCCESS",
  CANDIDATE_SEARCH_BY_EMAIL: "CANDIDATE_SEARCH_BY_EMAIL",
  CANDIDATE_SEARCH_BY_EMAIL_SUCCESS: "CANDIDATE_SEARCH_BY_EMAIL_SUCCESS",
  REMOVE_RESUME: "REMOVE_RESUME",
  REMOVE_RESUME_SUCCESS: "REMOVE_RESUME_SUCCESS",
  DOWNLOAD_RESUME: "DOWNLOAD_RESUME",
  DOWNLOAD_RESUME_SUCCESS: "DOWNLOAD_RESUME_SUCCESS",
  UPLOAD_PROFILE: "UPLOAD_PROFILE",
  UPLOAD_PROFILE_SUCCESS: "UPLOAD_PROFILE_SUCCESS",
  CANDIDATE_DP: "CANDIDATE_DP",
  CANDIDATE_DPURL_SUCCESS: "CANDIDATE_DPURL_SUCCESS",
  CANDIDATE_DPURL_RESET: "CANDIDATE_DPURL_RESET",
  GET_AOSI: "GET_AOSI",
  GET_AOSI_SUCCESS: "GET_AOSI_SUCCESS",
  CANDIDATE_FETCH_RESUME_DETAILS: "CANDIDATE_FETCH_RESUME_DETAILS",
  CANDIDATE_FETCH_RESUME_DETAILS_SUCCESS: "CANDIDATE_FETCH_RESUME_DETAILS_SUCCESS",
  CANDIDATE_RESUME_SCAN_DETAILS: "CANDIDATE_RESUME_SCAN_DETAILS",
  CANDIDATE_RESUME_SCAN_DETAILS_SUCCESS: "CANDIDATE_RESUME_SCAN_DETAILS_SUCCESS",
  CHECK_INVITATION_STATUS: "CHECK_INVITATION_STATUS",
  CHECK_INVITATION_STATUS_SUCCESS: "CHECK_INVITATION_STATUS_SUCCESS",
  CHECK_VALIDATION_STATUS: "CHECK_VALIDATION_STATUS",
  CHECK_VALIDATION_STATUS_SUCCESS: "CHECK_VALIDATION_STATUS_SUCCESS",
  CHECK_RESET_PASSWORD_TOKEN: "CHECK_RESET_PASSWORD_TOKEN",
  CHECK_RESET_PASSWORD_TOKEN_SUCCESS: "CHECK_RESET_PASSWORD_TOKEN_SUCCESS",
  CREATE_USER_PASSWORD: "CREATE_USER_PASSWORD",
  CREATE_USER_PASSWORD_SUCCESS: "CREATE_USER_PASSWORD_SUCCESS",
  CREATE_PASSWORD: "CREATE_PASSWORD",
  CREATE_PASSWORD_SUCCESS: "CREATE_PASSWORD_SUCCESS",
  VALIDATE_EMAIL: "VALIDATE_EMAIL",
  VALIDATE_EMAIL_SUCCESS: "VALIDATE_EMAIL_SUCCESS",
  VALIDATE_EMAIL_FAILURE: "VALIDATE_EMAIL_FAILURE",
  ACCEPT_VALIDATION: "ACCEPT_VALIDATION",
  ACCEPT_VALIDATION_SUCCESS: "ACCEPT_VALIDATION_SUCCESS",
  ACCEPT_VALIDATION_FAILURE: "ACCEPT_VALIDATION_FAILURE",
  RESEND_INVITATION: "RESEND_INVITATION",
  RESEND_INVITATION_SUCCESS: "RESEND_INVITATION_SUCCESS",
  RESEND_INVITATION_FAILURE: "RESEND_INVITATION_FAILURE",
  CLEAR_RESEND_INVITATION_SUCCESS: "CLEAR_RESEND_INVITATION_SUCCESS",
  // CHECK_ENTITY_STATUS: "CHECK_ENTITY_STATUS",
  // CHECK_ENTITY_STATUS_SUCCESS: "CHECK_ENTITY_STATUS_SUCCESS",
  CHECK_SUBSCRIPTION_STATUS_CANDIDATE: "CHECK_SUBSCRIPTION_STATUS_CANDIDATE",
  CHECK_SUBSCRIPTION_STATUS_CANDIDATE_SUCCESS: "CHECK_SUBSCRIPTION_STATUS_CANDIDATE_SUCCESS",
  CHECK_SUBSCRIPTION_STATUS_INVITE: "CHECK_SUBSCRIPTION_STATUS_INVITE",
  CHECK_SUBSCRIPTION_STATUS_INVITE_SUCCESS: "CHECK_SUBSCRIPTION_STATUS_INVITE_SUCCESS",
  CHECK_SUBSCRIPTION_STATUS_ROLE: "CHECK_SUBSCRIPTION_STATUS_ROLE",
  CHECK_SUBSCRIPTION_STATUS_ROLE_SUCCESS: "CHECK_SUBSCRIPTION_STATUS_ROLE_SUCCESS",
  CHECK_SUBSCRIPTION_STATUS_USER: "CHECK_SUBSCRIPTION_STATUS_USER",
  CHECK_SUBSCRIPTION_STATUS_USER_SUCCESS: "CHECK_SUBSCRIPTION_STATUS_USER_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  SIGNUP_PLANS: "SIGNUP_PLANS",
  SIGNUP_PLANS_SUCCESS: "SIGNUP_PLANS_SUCCESS",
  SIGNUP_PLANS_FAILURE: "SIGNUP_PLANS_FAILURE",
  RESET_CHANGE_PASSWORD_SUCCESS: "RESET_CHANGE_PASSWORD_SUCCESS",
  CREATE_PAYMENT_LINK: "CREATE_PAYMENT_LINK",
  CREATE_PAYMENT_LINK_SUCCESS: "CREATE_PAYMENT_LINK_SUCCESS",
  CREATE_PAYMENT_LINK_FAILURE: "CREATE_PAYMENT_LINK_FAILURE",
  MANAGEMENT_DETAILS: "MANAGEMENT_DETAILS",
  MANAGEMENT_DETAILS_SUCCESS: "MANAGEMENT_DETAILS_SUCCESS",
  MANAGEMENT_DETAILS_FAILURE: "MANAGEMENT_DETAILS_FAILURE",
  UPDATE_ORG_DETAILS: "UPDATE_ORG_DETAILS",
  UPDATE_ORG_DETAILS_SUCCESS: "UPDATE_ORG_DETAILS_SUCCESS",
  UPDATE_ORG_DETAILS_FAILURE: "UPDATE_ORG_DETAILS_FAILURE",
  RESET_ORG_DETAILS_SUCCESS: "RESET_ORG_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_FAILURE: "UPDATE_USER_DETAILS_FAILURE",
  RESET_USER_DETAILS_SUCCESS: "RESET_USER_DETAILS_SUCCESS",
  RESEND_EMAIL_VALIDATION: "RESEND_EMAIL_VALIDATION",
  RESEND_EMAIL_VALIDATION_SUCCESS: "RESEND_EMAIL_VALIDATION_SUCCESS",
  RESEND_EMAIL_VALIDATION_FAILURE: "RESEND_EMAIL_VALIDATION_FAILURE",
  CREATE_CHECKOUT_SESSION: "CREATE_CHECKOUT_SESSION",
  CREATE_CHECKOUT_SESSION_SUCCESS: "CREATE_CHECKOUT_SESSION_SUCCESS",
  CREATE_CHECKOUT_SESSION_FAILURE: "CREATE_CHECKOUT_SESSION_FAILURE",
  CREATE_BILLING_PORTAL: "CREATE_BILLING_PORTAL",
  CREATE_BILLING_PORTAL_SUCCESS: "CREATE_BILLING_PORTAL_SUCCESS",
  CREATE_BILLING_PORTAL_FAILURE: "CREATE_BILLING_PORTAL_FAILURE",
  DELETE_INVITED_USER: "DELETE_INVITED_USER",
  DELETE_INVITED_USER_SUCCESS: "DELETE_INVITED_USER_SUCCESS",
  DELETE_INVITED_USER_FAILURE: "DELETE_INVITED_USER_FAILURE",
  BILLING_PAGE_PLANS: "BILLING_PAGE_PLANS",
  BILLING_PAGE_PLANS_SUCCESS: "BILLING_PAGE_PLANS_SUCCESS",
  BILLING_PAGE_PLANS_FAILURE: "BILLING_PAGE_PLANS_FAILURE",
  API_BILLING_PORTAL_FOR_INVOICE_HISTORY: "API_BILLING_PORTAL_FOR_INVOICE_HISTORY",
  API_BILLING_PORTAL_FOR_INVOICE_HISTORY_SUCCESS: "API_BILLING_PORTAL_FOR_INVOICE_HISTORY_SUCCESS",
  API_BILLING_PORTAL_FOR_INVOICE_HISTORY_FAILURE: "API_BILLING_PORTAL_FOR_INVOICE_HISTORY_FAILURE",
  INVOICE_HISTORY_LIST: "INVOICE_HISTORY_LIST",
  INVOICE_HISTORY_LIST_SUCCESS: "INVOICE_HISTORY_LIST_SUCCESS",
  INVOICE_HISTORY_LIST_FAILURE: "INVOICE_HISTORY_LIST_FAILURE",
  GET_IP_ADDRESS: "GET_IP_ADDRESS",
  GET_IP_ADDRESS_SUCCESS: "GET_IP_ADDRESS_SUCCESS",
  GET_IP_ADDRESS_FAILURE: "GET_IP_ADDRESS_FAILURE",
  SET_SKILLS_CONFIG: "SET_SKILLS_CONFIG",
  GET_CONFIG_SKILLS: "GET_CONFIG_SKILLS",
  GET_CONFIG_SKILLS_FAILURE: "GET_CONFIG_SKILLS_FAILURE",
  BOOK_A_DEMO: "BOOK_A_DEMO",
  BOOK_A_DEMO_SUCCESS: "BOOK_A_DEMO_SUCCESS",
  BOOK_A_DEMO_FAILURE: "BOOK_A_DEMO_FAILURE",
  EDIT_AND_ADD_RESUME: "EDIT_AND_ADD_RESUME",
  EDIT_AND_ADD_RESUME_SUCCESS: "EDIT_AND_ADD_RESUME_SUCCESS",
  EDIT_AND_ADD_RESUME_FAILURE: "EDIT_AND_ADD_RESUME_FAILURE",
  ADD_COMPLETED_RESUME: "ADD_COMPLETED_RESUME",
  ADD_COMPLETED_RESUME_SUCCESS: "ADD_COMPLETED_RESUME_SUCCESS",
  ADD_COMPLETED_RESUME_FAILURE: "ADD_COMPLETED_RESUME_FAILURE",
  UPLOAD_BULK_RESUME: "UPLOAD_BULK_RESUME",
  UPLOAD_BULK_RESUME_SUCCESS: "UPLOAD_BULK_RESUME_SUCCESS",
  UPLOAD_BULK_RESUME_FAILURE: "UPLOAD_BULK_RESUME_FAILURE",
  CLEAR_UPLOAD_BULK_RESUME_SUCCESS: "CLEAR_UPLOAD_BULK_RESUME_SUCCESS",
  GET_PENDING_RESUME_LIST: "GET_PENDING_RESUME_LIST",
  GET_PENDING_RESUME_LIST_SUCCESS: "GET_PENDING_RESUME_LIST_SUCCESS",
  GET_PENDING_RESUME_LIST_FAILURE: "GET_PENDING_RESUME_LIST_FAILURE",
  DELETE_RESUME_DOC: "DELETE_RESUME_DOC",
  DELETE_RESUME_DOC_SUCCESS: "DELETE_RESUME_DOC_SUCCESS",
  DELETE_RESUME_DOC_FAILURE: "DELETE_RESUME_DOC_FAILURE",
  MATCH_CANDIDATES: "MATCH_CANDIDATES",
  MATCH_CANDIDATES_SUCCESS: "MATCH_CANDIDATES_SUCCESS",
  MATCH_CANDIDATES_FAILURE: "MATCH_CANDIDATES_FAILURE",
  CLOSE_INVITE: "CLOSE_INVITE",
  CLOSE_INVITE_SUCCESS: "CLOSE_INVITE_SUCCESS",
  CLOSE_INVITE_FAILURE: "CLOSE_INVITE_FAILURE",
  CANDIDATE_ROLE_LIST_FOR_NEW_INVITE: "CANDIDATE_ROLE_LIST_FOR_NEW_INVITE",
  CANDIDATE_ROLE_LIST_FOR_NEW_INVITE_SUCCESS: "CANDIDATE_ROLE_LIST_FOR_NEW_INVITE_SUCCESS",
  CANDIDATE_ROLE_LIST_FOR_NEW_INVITE_FAILURE: "CANDIDATE_ROLE_LIST_FOR_NEW_INVITE_FAILURE",
  GET_CANDIDATE_WE_SUMMARY: "GET_CANDIDATE_WE_SUMMARY",
  GET_CANDIDATE_WE_SUMMARY_SUCCESS: "GET_CANDIDATE_WE_SUMMARY_SUCCESS",
  GET_CANDIDATE_WE_SUMMARY_FAILURE: "GET_CANDIDATE_WE_SUMMARY_FAILURE",
  CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE: "CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE",
  CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE_SUCCESS: "CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE_SUCCESS",
  CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE_FAILURE: "CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE_FAILURE",
  CHECK_CANDIDATE_INVITE_FOR_ROLE: "CHECK_CANDIDATE_INVITE_FOR_ROLE",
  CHECK_CANDIDATE_INVITE_FOR_ROLE_SUCCESS: "CHECK_CANDIDATE_INVITE_FOR_ROLE_SUCCESS",
  CHECK_CANDIDATE_INVITE_FOR_ROLE_FAILURE: "CHECK_CANDIDATE_INVITE_FOR_ROLE_FAILURE",
  GET_MAINTENANCE_DETAILS: "GET_MAINTENANCE_DETAILS",
  GET_MAINTENANCE_DETAILS_SUCCESS: "GET_MAINTENANCE_DETAILS_SUCCESS",
  GET_MAINTENANCE_DETAILS_FAILURE: "GET_MAINTENANCE_DETAILS_FAILURE",
  CREATE_TICKET: "CREATE_TICKET",
  CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",
  CREATE_TICKET_FAILURE: "CREATE_TICKET_FAILURE",
  CLEAR_RAISE_ISSUE_SUCCESS: "CLEAR_RAISE_ISSUE_SUCCESS",
  RAISE_ISSUE: "RAISE_ISSUE",
  RAISE_ISSUE_SUCCESS: "RAISE_ISSUE_SUCCESS",
  RAISE_ISSUE_FAILURE: "RAISE_ISSUE_FAILURE",

  API_ERROR: "API_ERROR",
};

export default authTypes;
