import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, Link, Button, Tooltip } from "@mui/material";
import { theme } from "../../styles/theme";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../redux/actions/auth";
import quiz from "../../redux/actions/quiz";
import role from "../../redux/actions/role";
import moment from "moment";
import Footer from "../../components/Footer";
import ScoreCalculationDimension from "../../components/ScoreCalculationDimension/ScoreCalculationDimension";
import { removeAnswers } from "../../services/globalService";
import profileDPPlaceholder from "../../assets/svg/profile.svg";
import AlertDialog from "../../components/AlertDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomBtn from "../../components/controls/Button";
import Notification from "../../components/AnnouncementNotification";
import ReusableDialog from "../../components/controls/ReusableDialog";

// styles for this component
const useStyles = makeStyles({
  welcome: {
    flexGrow: 1,
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    // fontWeight: `${theme.font.fontWeight} !important`,
    fontWeight: "600 !important",
    fontSize: "32px !important",
    lineHeight: "32px",
    /* identical to box height, or 100% */
    letterSpacing: "-0.02em",
    /* Neutrals/Black */
    color: `${theme.palette.black.dark} !important`,
  },
  linksty: {
    color: "#1789FC",
    fontWeight: "500 !important",
    cursor: "pointer",
    padding: "0px",
    fontSize: "16px !important",
    textDecoration: "none !important",
  },
  container: {
    // paddingLeft: "16px",
    // paddingRight: "16px",
    // paddingBottom: "100px !important"
    //marginTop: "56px",
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  containerCls: {
    paddingRight: "64px",
    paddingLeft: "64px",
    paddingBottom: "100px !important",
  },
  text: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "32px !important",
    color: `${theme.palette.black.dark} !important`,
  },
  card: {
    // border: `2px solid ${theme.palette.black.dark} !important`,
    borderRadius: "8px",
    // width: `calc(100%/${(RolesData || []).length +1})`
    width: "310px",
    height: " 120px",
    background: "#FFFBF6",
  },
  cardtext: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `600 !important`,
    fontSize: "44px !important",
    lineHeight: "40px !important",
    /* identical to box height, or 100% */

    display: "flex",
    alignItems: "flex-start",
    // textAlign: "center",
    justifyContent: "center",
    letterSpacing: "-0.02em",

    color: `${theme.palette.black.dark} !important`,
    //width: "31px",
    height: "48px",
    marginTop: "24px !important",
    paddingTop: "25px",
  },
  cardtext1: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `500 !important`,
    fontSize: "16px",
    /* identical to box height, or 100% */

    display: "flex",
    alignItems: "center",
    // textAlign: "center",
    justifyContent: "center",
    letterSpacing: "-0.02em",

    color: `${theme.palette.black.dark} !important`,
  },
  tabletext: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `600 !important`,
    fontSize: "22px !important",
    lineHeight: "44px !important",
    /* identical to box height, or 100% */

    display: "flex",
    alignItems: "center",
    // textAlign: "center",
    justifyContent: "left",
    letterSpacing: "-0.02em",
    marginTop: "20px !important",
    color: `${theme.palette.black.dark} !important`,
  },
  subTableText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `400 !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
  },
  btnCls: {
    width: 300,
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    height: "30px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "0px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletype3: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px",
  },
  linkUnderline: {
    textDecoration: "none !important",
  },
  grayText: {
    color: "#787878",
  },
  textSty: theme.typography.b2Regular,
  linkMail: {
    "& .css-1ps4owl-MuiTypography-root-MuiLink-root": {
      textDecoration: "none !important",
      color: "#1789FC !important",
    },
  },
  textAndChecked: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  profileImg: {
    marginBottom: 16,
    width: 100,
    height: 100,
    borderRadius: 50,
    cursor: "pointer",
  },
  subTitle: {
    marginBottom: "6px",
    padding: "4px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3px",
      padding: "3px",
    },
  },
  profileDPInputBtn: {
    padding: "0 !important",
    textTransform: "unset !important",
    color: "#1789FC !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
  },
});
export default function CandidateDashboard() {
  // FK cleanup comments july 2023
  const classes = useStyles();
  // const navigate = useNavigate()
  // const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const dispatch = useDispatch();
  const [sectionDisable, setSectionDisable] = useState(false);
  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const quizState = useSelector((state) => state.quiz);
  const [isProfileReset, setProfileReset] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const handleContextmenu = (e) => e.preventDefault();
  const [updatedInviteDtlsInterval, setUpdatedInviteDtlsInterval] = useState(false);
  const [updatedInviteDtlsIntervalCnt, setUpdatedInviteDtlsIntervalCnt] = useState(1);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [testExpiry, setTestExpiry] = useState(null);
  const isTestCompleted =
    roleState?.candidateDashboardDetails?.testCompleted ===
    roleState?.candidateDashboardDetails?.selectedTestingRequirement?.length;
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  const [showNotification, setShowNotification] = useState(true);
  const [openDialogText, setOpenDialogText] = useState(false);
  const [DialogText, setDialogText] = useState("");

  let Uid = localStorage.getItem("uid");

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextmenu);
    document.onkeydown = function (e) {
      // disable F12 key
      if (e.keyCode === 123) {
        return false;
      }

      // disable I key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
        return false;
      }

      // disable J key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
        return false;
      }

      // disable U key
      if (e.ctrlKey && e.keyCode === 85) {
        return false;
      }
      // disable A key
      if (e.keyCode === 65 || e.keyCode === 97) {
        return false;
      }
    };
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
      clearInterval(updatedInviteDtlsInterval);
    };
  }, []);

  // Initial render of the page
  useEffect(() => {
    dispatch(quiz.resetElapsedTime(0));
    sessionStorage.removeItem("remainigTime");
    if (!authState.userExists) {
      dispatch(quiz.resetQuizSummery());
      dispatch(quiz.resetQuizQuestions());
      dispatch(quiz.resetTestResult());
    }
    dispatch(quiz.quizSelectionPopupStatus(false));
    dispatch(quiz.quizSelectionPopupDisable(false));
    dispatch(quiz.quizSelectionTopic(""));
    dispatch(quiz.setSelectedQuesNdAns([]));
    removeAnswers();
    dispatch(quiz.setL0TestResult(null));
    // eslint-disable-next-line

    if (quizState.quizTimeout != "empty") {
      setOpenAlertDialog(true);
    }
  }, []);

  //Initial render
  useEffect(() => {
    dispatch(auth.resetCandidateDPUrl());
    dispatch(auth.getMaintenanceDetails());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isProfileReset) {
      dispatch(quiz.resetQuizSummery());
      dispatch(quiz.resetQuizQuestions());
      dispatch(quiz.resetTestResult());
      dispatch(auth.updateUserExists(false));
      setProfileReset(false);
    }
    // eslint-disable-next-line
  }, [isProfileReset]);

  // Render the API call when there is invite id
  useEffect(() => {
    if (Uid) {
      dispatch(role.candidateDashboardDetails(Uid));
    }
    // eslint-disable-next-line
  }, [Uid]);

  // let mystring = roleState?.candidateDashboardDetails?.createdBy?.uid;
  // mystring = mystring?.substring(0, 5);

  useEffect(() => {
    // Commented: AR-2023-12-15, No need to call recruiter details api
    // dispatch(auth.recruiterDetails(mystring));

    // It will update candidate picture
    if (roleState?.candidateDashboardDetails?.candidate?.photoURL !== undefined) {
      checkAndDisplayProfilePicture(roleState?.candidateDashboardDetails?.candidate?.photoURL);
    }
    // check the status of invite
    if (
      (roleState?.candidateDashboardDetails && roleState?.candidateDashboardDetails?.jobRole?.status === "inactive") ||
      (roleState?.candidateDashboardDetails && roleState?.candidateDashboardDetails?.status === "inactive")
    ) {
      // console.log('setting section disable true as inactive');
      setSectionDisable(true);
    }
    // else {
    //   // console.log('setting section disable false');
    //   setSectionDisable(false);
    // }
    //check for expired invite
    if (roleState?.candidateDashboardDetails && moment(testExpiry).isBefore(new Date())) {
      // console.log('setting section disable true as expired');
      setSectionDisable(true);
    }

    // AR: Please ignore this, it will implement soon
    if (roleState?.candidateDashboardDetails?.sectionsStatus) {
      let setIntervalFlag = false;

      for (let val of Object.values(roleState?.candidateDashboardDetails?.sectionsStatus)) {
        if (val == "started" || val == "submitted") {
          setIntervalFlag = true;
          break;
        }
      }

      if (setIntervalFlag && updatedInviteDtlsIntervalCnt <= 5) {
        clearInterval(updatedInviteDtlsInterval);
        let newInterval = setInterval(() => {
          dispatch(role.candidateDashboardDetails(Uid));
        }, 300000);
        setUpdatedInviteDtlsIntervalCnt(updatedInviteDtlsIntervalCnt + 1);
        setUpdatedInviteDtlsInterval(newInterval);
      } else {
        clearInterval(updatedInviteDtlsInterval);
      }
    }

    return () => {
      clearInterval(updatedInviteDtlsInterval);
    };
  }, [roleState?.candidateDashboardDetails]);

  useEffect(() => {
    if (roleState?.candidateDashboardDetails?.testExpiresOn) {
      setTestExpiry(roleState?.candidateDashboardDetails?.testExpiresOn);
    }
  }, [roleState?.candidateDashboardDetails]);

  // this useEffect will check and disable the section even after refreshing the page
  useEffect(() => {
    if (isTestCompleted && moment(testExpiry).isBefore(new Date())) {
      setSectionDisable(true);
    }
  }, [isTestCompleted, testExpiry]);

  useEffect(() => {
    // run updates once candidateDetails  are populated
    if (authState?.candidatedetails) {
      checkAndDisplayProfilePicture(authState?.candidatedetails?.photoURL);
    }
    // eslint-disable-next-line
  }, [authState?.candidatedetails]);

  //handle the upload profile image
  const uploadProfileImage = ({ target: { files } }) => {
    const MAX_SIZE = 500 * 1024; // 500KB in bytes

    if (files && files[0]) {
      let uploadedFile = files[0];

      if (uploadedFile.size > MAX_SIZE) {
        setOpenDialogText(true);
        setDialogText("File size exceeds 500KB. Please upload a smaller file.");
        document.getElementById("upload-profiledp-file").value = ""; // Reset the input value
        return;
      }
      let data = new FormData();
      let dpUploadedFileName = files[0].name;
      dpUploadedFileName = dpUploadedFileName.replaceAll(" ", "_");
      data.append("file", files[0], dpUploadedFileName);

      const payload = {
        data: data,
        id: roleState?.candidateDashboardDetails?.candidate?.user?.uid,
        email: roleState?.candidateDashboardDetails?.candidate?.user?.email,
      };
      if (data) {
        dispatch(auth.uploadProfile(payload));
        setProfilePic(null);
      }
    }
  };

  // run updates once candidateDetails are populated
  useEffect(() => {
    const photoURL = roleState?.candidateDashboardDetails?.candidate?.photoURL;
    const candidateDPUrl = authState?.candidateDPUrl;

    if (photoURL !== undefined) {
      if (candidateDPUrl !== null && candidateDPUrl !== photoURL) {
        setProfilePic(candidateDPUrl);
      } else {
        setProfilePic(null);
      }
    } else {
      // setProfilePic(null);
      dispatch(auth.resetCandidateDPUrl());
    }
  }, [authState?.candidateDPUrl]);

  // check profile picture exists for candidate and map candidate profile picture on UI
  const checkAndDisplayProfilePicture = (candidateProfileData) => {
    if (candidateProfileData && authState?.candidateDPUrl !== candidateProfileData) {
      // check if signed image already exists
      if (authState?.candidateDPUrl === null || profilePic === null) {
        // updates signed s3 url to display profile picture on UI
        dispatch(auth.getCandidateDPUrl(candidateProfileData));
      }
    }
  };

  function handleAlertDailog() {
    dispatch(quiz.updateQuizTimeoutFlag("empty"));
    setOpenAlertDialog(false);
  }

  const handleSubmitBtn = () => {
    let payload = {
      inviteId: roleState?.candidateDashboardDetails?.uid,
    };
    dispatch(auth.closeInvite(payload));
    // setSectionDisable(true);
  };

  useEffect(() => {
    if (authState?.closeInviteSuccess) {
      setSectionDisable(true);
      setTestExpiry(authState?.closeInviteSuccess?.testExpiresOn);
      dispatch(role.candidateDashboardDetails(Uid));
    }
  }, [authState?.closeInviteSuccess, Uid]);

  useEffect(() => {
    if (authState?.candidateDPUrl) {
      setProfilePic(authState?.candidateDPUrl);
    }
  }, [authState?.candidateDPUrl]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleDailog = () => {
    setOpenDialogText(false);
    setDialogText("");
  };

  return (
    <div style={{ userSelect: "none" }}>
      <NavBar />
      <Notification handleCloseNotification={handleCloseNotification} showNotification={showNotification} />
      <Container maxWidth="xl" className={`${classes.content} ${classes.containerCls}`}>
        <Grid style={showNotification ? { marginTop: "20px" } : { marginTop: "100px" }}>
          <Typography className={classes.welcome} align="left">
            Your Invite Details
          </Typography>
          <Grid container spacing={3} style={{ marginTop: "15px" }}>
            {/* Left Side Content */}
            <Grid item xs={4}>
              <Grid style={{ border: "1px solid #EBEFF8", padding: "16px" }}>
                <Grid container spacing={6} alignItems="center" justifyContent="space-between">
                  <Grid item xs={8}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className={classes.text}>
                          {roleState?.candidateDashboardDetails?.candidate?.user?.firstName}{" "}
                          {roleState?.candidateDashboardDetails?.candidate?.user?.lastName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <input
                          style={{ display: "none" }}
                          id="upload-profiledp-file"
                          type="file"
                          onChange={uploadProfileImage}
                        />
                        <label htmlFor="upload-profiledp-file">
                          <Button className={classes.profileDPInputBtn} component="span">
                            Upload Profile Photo
                          </Button>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <img
                      className={classes.profileImg}
                      alt="profileImg"
                      src={profilePic !== null ? profilePic : profileDPPlaceholder}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <Typography className={classes.roletype1}>Status</Typography>
                  <Typography
                    className={classes.roletype}
                    style={{
                      color: roleState?.candidateDashboardDetails?.status === "active" ? " #2F2F2F" : "#DD2E2E",
                    }}
                  >
                    {roleState?.candidateDashboardDetails?.status === "active" ? " Active" : "Inactive"}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.roletype1}>Invite ID</Typography>
                  <Typography className={classes.roletype} style={{ color: " #2F2F2F" }}>
                    {roleState?.candidateDashboardDetails?.uid}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.roletype1}>Testing Role</Typography>
                  <Typography className={classes.roletype} style={{ color: " #2F2F2F" }}>
                    {roleState?.candidateDashboardDetails?.jobRole?.name}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.roletype1}>Primary Coding Language</Typography>
                  <Tooltip title={roleState?.candidateDashboardDetails?.jobRole?.codingLang.join(" / ")}>
                    <Typography className={classes.roletype} style={{ color: " #2F2F2F" }}>
                      {roleState?.candidateDashboardDetails?.jobRole?.codingLang.join(" / ")}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Typography className={classes.roletype1}>Skills Required</Typography>
                  {Object.entries(authState?.skillsConfig || {})
                    .sort(([, a], [, b]) => a.fullName.localeCompare(b.fullName))
                    .map(([key], i) => {
                      if ((roleState?.candidateDashboardDetails?.jobRole?.selectedSkills || []).includes(key)) {
                        return (
                          <Typography
                            key={i}
                            className={classes.roletype}
                            style={{ color: " #2F2F2F", textTransform: "capitalize" }}
                          >
                            {authState?.skillsConfig[key]?.fullName}
                          </Typography>
                        );
                      }
                      return null;
                    })}
                </Grid>
                <Grid>
                  <Typography className={classes.roletype1}>Invited on</Typography>
                  <Typography className={classes.roletype} style={{ color: " #2F2F2F" }}>
                    {moment(roleState?.candidateDashboardDetails?.createdAt).format("MMM DD, YYYY")} by{" "}
                    {roleState?.candidateDashboardDetails?.createdBy?.displayName}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.roletype1}>
                    {moment(new Date()) > moment(testExpiry)
                      ? isTestCompleted && profilePic
                        ? "Submitted On"
                        : "Expired On"
                      : "Expires on"}
                  </Typography>
                  <Typography
                    className={classes.roletype}
                    style={{ color: moment(testExpiry).isBefore(moment(new Date())) ? "#DD2E2E" : "#2F2F2F" }}
                  >
                    {moment(testExpiry).format("MMM DD, YYYY hh:mm a")}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className={classes.roletype1}>Last session on</Typography>
                  <Typography className={classes.roletype1} style={{ paddingTop: "0px" }}>
                    {moment(roleState?.candidateDashboardDetails?.updatedAt).format("MMM DD, YYYY hh:mm a zz")}{" "}
                  </Typography>
                </Grid>
                <Typography className={classes.roletype1}>
                  Need help?{" "}
                  <span>
                    <Link
                      className={classes.linkMail}
                      href={`mailto:${roleState?.candidateDashboardDetails?.createdBy?.email}`}
                      style={{
                        color: "#1789FC !important",
                        fontWeight: 500,
                        cursor: "pointer",
                        textDecoration: "none !important",
                      }}
                    >
                      Contact your recruiter
                    </Link>
                  </span>
                </Typography>
              </Grid>
            </Grid>

            {/* Right Side Content */}
            <Grid item xs={8}>
              <ScoreCalculationDimension disabled={sectionDisable} />
              <Grid>
                <Typography sx={{ fontWeight: 500, marginBottom: ".5rem", mt: 4 }}>Invite Checklist</Typography>
                <Typography
                  variant2="b2Regular"
                  className={`${classes.grayText} ${classes.subTitle} ${classes.textSty} ${classes.textAndChecked}`}
                >
                  <CheckCircleIcon
                    style={{
                      color: isTestCompleted ? "green" : "gray",
                    }}
                  />{" "}
                  {roleState?.candidateDashboardDetails?.testCompleted +
                    "/" +
                    roleState?.candidateDashboardDetails?.selectedTestingRequirement?.length +
                    " Sections Completed"}
                </Typography>
                <Typography
                  variant2="b2Regular"
                  className={`${classes.grayText} ${classes.subTitle} ${classes.textSty} ${classes.textAndChecked}`}
                >
                  <CheckCircleIcon style={{ color: profilePic ? "green" : "gray" }} /> Profile Photo Uploaded (Please
                  ensure your photo is of professional quality)
                </Typography>
                {profilePic && isTestCompleted && moment(testExpiry).isAfter(moment(new Date())) ? (
                  <Typography sx={{ mt: 1 }} className={`${classes.grayText} ${classes.subTitle} ${classes.textSty}`}>
                    Submit now if you don’t wish to make further changes. It will auto-submit on{" "}
                    {moment(testExpiry).format("MMM DD, YYYY hh:mm a")}
                  </Typography>
                ) : null}
                {profilePic && isTestCompleted && moment(testExpiry).isBefore(moment(new Date())) ? (
                  <Typography sx={{ mt: 1 }} className={`${classes.grayText} ${classes.subTitle} ${classes.textSty}`}>
                    Invite submitted successfully.
                  </Typography>
                ) : null}
                {moment(testExpiry).isBefore(moment(new Date())) && (!profilePic || !isTestCompleted) ? (
                  <Typography sx={{ mt: 1 }} className={`${classes.grayText} ${classes.subTitle} ${classes.textSty}`}>
                    This invite has expired.
                  </Typography>
                ) : null}
                <Grid>
                  {moment(testExpiry).isAfter(moment(new Date())) && (
                    <CustomBtn
                      text="Submit"
                      color={
                        !profilePic || !isTestCompleted || userDetails.role !== "Candidate" ? "disablebtn1" : "btn1"
                      }
                      sx={{ mt: 2 }}
                      disabled={!profilePic || !isTestCompleted || userDetails.role !== "Candidate"}
                      onClick={handleSubmitBtn}
                    ></CustomBtn>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <AlertDialog
        open={openAlertDialog}
        text={quizState.quizTimeout?.msg}
        heading=""
        onCLickContinue={handleAlertDailog}
      ></AlertDialog>
      <ReusableDialog
        open={openDialogText}
        onClose={handleDailog}
        content={<Typography>{DialogText}</Typography>}
        actions={[{ onClick: handleDailog, color: "btn1", autoFocus: true, text: "Ok" }]}
      />

      <Footer />
    </div>
  );
}
