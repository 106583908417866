import React, { useState, useEffect } from "react";
import ReusableDialog from "./controls/ReusableDialog";
import { Typography } from "@mui/material";

export default function OnAppBlurDialog(props) {
  // eslint-disable-next-line
  const { open, text, succesBtnLabel, actionInfo, isTimerOn, ...other } = props;
  // const classes = useStyles();
  // const quizState = useSelector((state) => state.quiz);
  const [dialogTimer, setDialogTimer] = useState(30);
  const [interval, setTimeInterval] = useState("");

  const handleExitFullscreen = () => {
    // Check if the document is currently in fullscreen mode
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const onClickContinue = () => {
    setDialogTimer(20);
    clearInterval(interval);
    // console.log('continue')
    props.onCLickContinue();
  };

  useEffect(() => {
    if (isTimerOn && props.appFocusCount < 5) {
      setTimeInterval(
        setInterval(() => {
          setDialogTimer((prevState) => prevState - 1);
        }, 1000)
      );
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (dialogTimer <= 0) {
      clearInterval(interval);
      props.onAutoSubmit();
      handleExitFullscreen();
    }
  }, [dialogTimer]);

  useEffect(() => {
    if (props.appFocusCount > 4) {
      clearInterval(interval);
      props.onAutoSubmit();
      handleExitFullscreen();
    }
  }, [props.appFocusCount]);

  //   console.log('\n\n\n\n----',props.appFocusCount)

  return (
    <div>
      <ReusableDialog
        open={open}
        title="Warning!!"
        content={
          props.appFocusCount > 4 ? (
            <Typography>
              This section was out of focus for more than the permitted time. Now it will automatically submit.
            </Typography>
          ) : (
            <>
              {isTimerOn ? (
                <>
                  {dialogTimer > 0 ? (
                    <>
                      You have moved out of the application. Please return in <b>{dialogTimer} seconds</b> to continue,
                      otherwise, the section will submit automatically.
                    </>
                  ) : (
                    <>
                      This section was out of focus for more than the permitted time. Now it will automatically submit.
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )
        }
        actions={[{ onClick: onClickContinue, text: "Continue...", color: "btn1" }]}
      />
    </div>
  );
}
