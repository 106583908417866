import React, { useEffect, useMemo, useState } from "react";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import {
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../styles/theme";
import SelectMenu from "../../../components/controls/SelectMenu";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
import { useFormik } from "formik";
import Button from "../../../components/controls/Button";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import TextInput from "../../../components/controls/TextField";
import { styled } from "@mui/styles";
import Switch from "@mui/material/Switch";
import NewNameNote from "./NewNameNote";
import { transformOptions } from "../../../services/globalService";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SelectWithCheckbox from "../../../components/controls/SelectWithCheckbox";
import auth from "../../../redux/actions/auth";
import CustomBreadcrumbs from "../../../components/controls/CustomBreadcrumbs";
import SkillsDialog from "./SkillsDialog";

// styles for this component
const useStyles = makeStyles({
  container: {
    minHeight: "calc(100vh - 220px)",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "100px",
  },
  // viewText: {
  //   fontFamily: `${theme.font.fontFamily} !important`,
  //   fontStyle: `${theme.font.fontStyle} !important`,
  //   fontSize: "16px !important",
  //   marginTop: "10px",
  // },
  // roletype: {
  //   fontFamily: "Roboto !important",
  //   fontStyle: "normal",
  //   fontWeight: "500 !important",
  //   fontSize: "16px !important",
  //   lineHeight: "24px !important",
  //   color: " #787878",
  //   // textAlign:'center',
  //   paddingTop: "10px",
  // },
  roletype1: {
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  roletypetext: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
  },
  // roletypetext1: {
  //   fontFamily: "Roboto !important",
  //   fontStyle: "normal",
  //   fontWeight: "400 !important",
  //   fontSize: "14px !important",
  //   lineHeight: "24px !important",
  //   color: " #787878",
  //   // textAlign:'center',
  //   paddingTop: "10px",
  // },
  field: {
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  errorty: {
    color: "red",
    fontSize: "15px !important",
  },
  // errormsg: {
  //   color: "red",
  //   textAlign: "center",
  //   marginTop: "10px !important",
  //   fontSize: "14px !important",
  // },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  Textsty: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px",
    color: "#2F2F2F",
  },
  // item1: {
  //   borderRadius: "4px",
  //   marginTop: "16px",
  //   // minHeight: "52px",
  //   height: "48px",
  //   // width:'533px',
  //   backgroundColor: "#EBEFF8",
  //   display: "flex",
  //   alignItems: "center",
  //   textAlign: "left",
  //   "&:hover": {
  //     boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.12)",
  //     cursor: "pointer",
  //   },
  // },
  title: {
    flexGrow: 1,
  },
  // answer: {
  //   padding: "16px",
  //   whiteSpace: "pre-wrap",
  // },
  // btn: {
  //   width: "240px !important",
  //   textTransform: "none !important",
  //   background: "#1789FC !important",
  // },
  // cancel_btn: {
  //   width: "112px !important",
  //   textTransform: "none !important",
  //   background: "transparent !important",
  //   color: "#787878 !important",
  //   boxShadow: "none !important",
  // },
  // down_arrow: {
  //   padding: "8px",
  //   marginTop: "10px",
  // },
  textareasty: {
    width: "100%",
    height: "250px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
  // btnCls: {
  //   width: 300,
  //   fontFamily: `${theme.font.fontFamily} !important`,
  //   fontStyle: `${theme.font.fontStyle} !important`,
  //   fontWeight: `${theme.font.fontWeight} !important`,
  //   fontSize: "14px !important",
  //   lineHeight: "24px !important",
  //   borderRadius: "4px !important",
  //   textTransform: "none !important",
  //   height: "30px !important",
  //   // height: '40px',
  //   color: "#1789FC !important",
  // },
  item2: {
    borderRadius: "4px",
    marginTop: "16px",
    // minHeight: "52px",
    height: "48px",
    // width:'533px',
    backgroundColor: "#61A2E4",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "&:hover": {
      boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.12)",
      cursor: "pointer",
    },
  },
  selectText: {
    "& .MuiSelect-select": {
      background: "#EBEFF8 !important",
      border: "none !important",
      padding: "3px 12px !important",
      fontSize: "12px",
    },
  },
  percent: {
    position: "absolute",
    right: "12px",
    cursor: "pointer",
  },
  selectMenu: {
    "& .css-vamk89-MuiFormControl-root .MuiFormControl-root": {
      width: "85% !important",
      margin: "0px !important",
    },
  },

  field1: {
    // height: 48,
    //marginTop: 8,
    borderRadius: "5px !important",
    // width: '533px !important',
    //border:'1px solid !important',
    backgroundColor: theme.palette.btnPrimary.light,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  textareasty1: {
    width: "100%",
    height: "400px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  textareastyNotes: {
    width: "100%",
    height: "150px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },

  messageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  textview: {
    marginRight: "100px",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "20px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  // backdrop: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   color: "#fff",
  // },
  cursorPointer: {
    cursor: "pointer",
  },
  skillsColumns: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    background: "#FFFBF6",
    paddingLeft: "5px",
    marginTop: "10px",
    height: "46px",
    alignItems: "center",
  },
});

const codingLangMenuProps = {
  PaperProps: {
    style: {
      width: 200,
      padding: 12,
      marginLeft: 46,
    },
  },
};

const AntSwitch = styled(Switch)({
  width: 28,
  height: 16,
  padding: "11px !important",
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 14,
    marginLeft: 1,
    bottom: 0,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
});

const CreateJobRole = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleState = useSelector((state) => state.role);
  const authState = useSelector((state) => state.auth);
  const location = useLocation();
  const { id } = useParams();

  let pathName = location.pathname;
  let editpath = `/editJobRole/${id}`;

  const [showForm, setShowForm] = useState(false);
  const [selectedJobRoleType, setSelectedJobRoleType] = useState({
    id: "paste_a_jd",
    displayName: "Paste a Job Description",
  });
  const [selectedJobRoleTitle, setSelectedJobRoleTitle] = useState("");
  const [selectedJobRoleCategory, setSelectedJobRoleCategory] = useState(null);
  const [selectedDegree, setSelectedDegree] = useState(null);
  const [selectedYearsOfExperience, setSelectedYearsOfExperience] = useState("");
  const [selectedSelectivity, setSelectedSelectivity] = useState("");
  const [roleFeaturesLoading, setRoleFeaturesLoading] = useState(false);
  const [roleSkillsLoading, setRoleSkillsLoading] = useState(false);
  const [selectCoding, setSelectCoding] = useState([]);
  const [percentage, setPercentage] = useState([]);
  const [totalper, setTotalper] = useState(0);
  const [selectedSkillsAndWightages, setSelectedSkillsAndWightages] = useState([]);
  const [relevantSkills, setRelevantSkills] = useState([]);
  const [isTechnicalChecked, setIsTechnicalChecked] = useState(true);
  const [isBehavioralChecked, setIsBehavioralChecked] = useState(true);
  const [isCodingChecked, setIsCodingChecked] = useState(true);
  const [switchcheck, setSwitchcheck] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [values, setValues] = useState({});
  const [allSkills, setAllSkills] = useState([]);
  const [isMaxLimitZero, setIsMaxLimitZero] = useState(false);
  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  const initialSections = ["Profile"];
  if (isTechnicalChecked) initialSections.push("Technical");
  if (isBehavioralChecked) initialSections.push("Presentation");
  if (isCodingChecked) initialSections.push("Coding");
  const [selectedSections, setSelectedSections] = useState(initialSections);
  const [open, setOpen] = useState(false);
  const [selectedFromAllSkills, setSelectedFromAllSkills] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const initialValues = {
    textAreaValuejob: "",
    rolename: "",
    textAreaValue: "",
  };

  const formik = useFormik({
    initialValues,
  });

  const isRoleNameDuplicate = roleState?.rolelist.some((role) => role.name.trim() === formik.values.rolename.trim());

  // first time render to get the data for the dropdown
  useEffect(() => {
    dispatch(role.jobRoleData());
    dispatch(auth.checkSubscriptionStatusRole());
  }, []);

  // first render for edit role
  useEffect(() => {
    if (id && pathName === editpath) {
      dispatch(role.roleDetails(id));
    }
  }, [id]);

  const removeSpecialChar = (str) => {
    if (typeof str !== "string" || str === undefined) {
      return ""; // Or you can return str if you want to keep it as is
    }
    // Use replaceAll only if str is a non-empty string
    return str.replaceAll(/[^\w\s]/gi, "");
  };

  const sortedJobRoleTypes = useMemo(() => {
    return Object.values(roleState?.jobRoleDataSuccess?.jobRoleTypes || {}).sort((a, b) =>
      removeSpecialChar(a?.id) > removeSpecialChar(b?.id) ? -1 : 1
    );
  }, [roleState?.jobRoleDataSuccess?.jobRoleTypes]);

  const handleChangeRoleType = (displayName) => {
    setSelectedJobRoleType(displayName);
    let templateJD = "";
    for (let key in roleState?.jobRoleDataSuccess?.jobRoleTypes) {
      if (roleState?.jobRoleDataSuccess?.jobRoleTypes[key].displayName === displayName.displayName) {
        templateJD = roleState?.jobRoleDataSuccess?.jobRoleTypes[key].templateJD;
        break;
      }
    }
    formik.setFieldValue("textAreaValuejob", templateJD);
  };

  const handleChangeCategory = (value) => {
    setSelectedJobRoleCategory(value);
  };

  const handleChangeDegree = (value) => {
    setSelectedDegree(value);
  };

  const handleChangeYear = (value) => {
    setSelectedYearsOfExperience(value);
  };

  const handleChangeSelectivity = (value) => {
    setSelectedSelectivity(value);
  };

  const GenerateProfile = () => {
    let payload = {
      jd: JSON.stringify(formik.values.textAreaValuejob),
    };
    dispatch(role.getRoleFeatures(payload));
    dispatch(role.getRoleSkills(payload));
    setRoleFeaturesLoading(true);
    setRoleSkillsLoading(true);
    setShowForm(true);
  };

  useEffect(() => {
    if (roleState?.getRoleFeaturesSuccess?.roleMetadata) {
      setRoleFeaturesLoading(false);
    }

    if (roleState?.getRoleFeaturesSuccess?.roleTitle) {
      setSelectedJobRoleTitle(roleState?.getRoleFeaturesSuccess?.roleTitle);
    }
    if (roleState?.getRoleFeaturesSuccess?.roleCategory) {
      const categories = transformOptions(roleState?.jobRoleDataSuccess?.roleCategories);
      const matchedCategory = categories?.find(
        (category) =>
          category?.id === roleState?.getRoleFeaturesSuccess?.roleCategory ||
          category?.displayName === roleState?.getRoleFeaturesSuccess?.roleCategory
      );
      setSelectedJobRoleCategory(matchedCategory);
    }
    if (roleState?.getRoleFeaturesSuccess?.degree) {
      const degrees = transformOptions(roleState?.jobRoleDataSuccess?.degrees);
      const matchedDegree = degrees?.find(
        (degree) =>
          degree?.id === roleState?.getRoleFeaturesSuccess?.degree ||
          degree?.displayName === roleState?.getRoleFeaturesSuccess?.degree
      );
      setSelectedDegree(matchedDegree);
    }
    if (roleState?.getRoleFeaturesSuccess?.yearOfExperience) {
      const yearsOfExperience = transformOptions(roleState?.jobRoleDataSuccess?.yearsOfExperience);
      const matchedYearOfExperience = yearsOfExperience?.find(
        (yearOfExperience) =>
          yearOfExperience?.id === roleState?.getRoleFeaturesSuccess?.yearOfExperience ||
          yearOfExperience?.displayName === roleState?.getRoleFeaturesSuccess?.yearOfExperience
      );
      setSelectedYearsOfExperience(matchedYearOfExperience);
    }
    if (roleState.getRoleFeaturesSuccess?.roleTitle) {
      formik.setFieldValue("rolename", roleState.getRoleFeaturesSuccess.roleTitle);
    }
    if (roleState?.getRoleFeaturesSuccess?.selectivity) {
      const selectivity = transformOptions(roleState?.jobRoleDataSuccess?.selectivity);
      const matchedSelectivity = selectivity?.find(
        (selectivity) =>
          selectivity?.id === roleState?.getRoleFeaturesSuccess?.selectivity ||
          selectivity?.displayName === roleState?.getRoleFeaturesSuccess?.selectivity
      );
      setSelectedSelectivity(matchedSelectivity);
    }
  }, [roleState?.getRoleFeaturesSuccess]);

  useEffect(() => {
    setRoleSkillsLoading(false);

    if (roleState?.getRoleSkillsSuccess?.relevantSkills) {
      const relevantSkillsAndWeightages = roleState.getRoleSkillsSuccess.relevantSkills.map((skill) => ({
        skill: skill.skillName,
        weightage: skill.weightage,
        skillKey: skill.skillKey,
      }));
      setRelevantSkills(relevantSkillsAndWeightages);
    }

    if (roleState?.getRoleSkillsSuccess?.languages) {
      setSelectCoding(roleState?.getRoleSkillsSuccess?.languages);
    }

    if (roleState?.getRoleSkillsSuccess?.selectedSkills) {
      const selectedSkillsAndWeightages = roleState.getRoleSkillsSuccess.selectedSkills.map((skill) => ({
        skill: skill.skillName,
        weightage: skill.weightage,
        skillKey: skill.skillKey,
      }));

      setSelectedSkillsAndWightages(selectedSkillsAndWeightages);

      // Calculate the total percentage
      const totalPercentage = selectedSkillsAndWeightages.reduce((sum, skill) => sum + skill.weightage, 0);
      setTotalper(totalPercentage);
    }

    if (roleState?.getRoleSkillsSuccess?.allSkills) {
      setAllSkills(roleState?.getRoleSkillsSuccess?.allSkills);
    }
  }, [roleState?.getRoleSkillsSuccess]);

  const handleSortJobRoleCodingLanguages = () => {
    //Coding Languages
    let codingLanguages = [];
    // adding only language skills to codingLanguages
    for (let skill in authState?.skillsConfig) {
      if (
        authState?.skillsConfig[skill].isLanguageSkill
        // && authState?.skillsConfig[skill].isRoleSkill
      ) {
        codingLanguages.push({
          id: skill,
          displayName: authState?.skillsConfig[skill].fullName,
        });
      }
    }
    codingLanguages = codingLanguages.sort((a, b) =>
      removeSpecialChar(a?.displayName) > removeSpecialChar(b?.displayName) ? 1 : -1
    );

    return codingLanguages?.map((i) => i);
  };

  const handleChangeCoding = (value) => {
    setSelectCoding(value);
  };

  const onSelectAllCodingLang = () => {
    const selectedIds = handleSortJobRoleCodingLanguages().map((language) => language.id);
    setSelectCoding(selectedIds);
  };

  const onClearAllCodingLang = () => {
    setSelectCoding([]);
  }; //End

  const switchChange = (event) => {
    if (event.target.checked) {
      setSwitchcheck(true);
    } else {
      setSwitchcheck(false);
    }
  };

  useEffect(() => {
    // Calculate the total percentage
    const totalPercentage = selectedSkillsAndWightages.reduce((sum, skill) => sum + skill.weightage, 0);
    setTotalper(totalPercentage);
  }, [selectedSkillsAndWightages]);

  const handleCheckboxChange = (skill, weightage, skillKey) => {
    // Check if the skill is already selected
    const isSkillSelected = selectedSkillsAndWightages.some((selectedSkill) => selectedSkill.skill === skill);

    if (isSkillSelected) {
      // If the skill is already selected, remove it from the selectedSkillsAndWightages array
      setSelectedSkillsAndWightages((prevSkills) =>
        prevSkills.filter((selectedSkill) => selectedSkill.skill !== skill)
      );

      // Add the skill back to the relevantSkills array
      setRelevantSkills((prevSkills) => [...prevSkills, { skill, weightage, skillKey }]);

      // Remove the skill from the percentage state
      setPercentage((prevPercentage) => {
        const newPercentage = { ...prevPercentage };
        delete newPercentage[skill];
        return newPercentage;
      });
    } else {
      // If the skill is not selected, add it to the selectedSkillsAndWightages array
      const skillToAdd = { skill, weightage, skillKey };
      setSelectedSkillsAndWightages((prevSkills) => [...prevSkills, skillToAdd]);

      // Remove the skill from the relevantSkills array
      setRelevantSkills((prevSkills) => prevSkills.filter((relevantSkill) => relevantSkill.skill !== skill));

      // Add the skill to the percentage state with the provided weightage
      setPercentage((prevPercentage) => ({
        ...prevPercentage,
        [skill]: { select: weightage },
      }));
    }
  };

  let selectpercentage = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"];

  const HandleChangePercentage = (event, selectedSkill) => {
    let newPercentage = parseInt(event.target.value);
    if (isNaN(newPercentage)) {
      newPercentage = 0;
    }

    setPercentage((prevPercentage) => {
      const updatedPercentage = {
        ...prevPercentage,
        [selectedSkill.skill]: { select: newPercentage },
      };

      setSelectedSkillsAndWightages((prevSkills) => {
        const updatedSkills = prevSkills.map((skill) =>
          skill.skill === selectedSkill.skill ? { ...skill, weightage: newPercentage } : skill
        );

        return updatedSkills;
      });

      return updatedPercentage;
    });
  };

  useEffect(() => {
    if (selectCoding?.length === 0) {
      setIsCodingChecked(false);
      setIsTechnicalChecked(true);
      setSelectedSections((prevSections) => {
        let newSections = prevSections.filter((sec) => sec !== "Coding");
        if (!newSections.includes("Technical")) {
          newSections.push("Technical");
        }
        return newSections;
      });
    } else {
      setIsCodingChecked(true);
      setSelectedSections((prevSections) => {
        let newSections = [...prevSections];
        if (!newSections.includes("Coding")) {
          newSections.push("Coding");
        }
        return newSections;
      });
    }
  }, [selectCoding]);

  const handleCheckbox = (section, isChecked) => {
    if (!isChecked && selectedSections?.length === 1) {
      return;
    }
    if (isChecked) {
      setSelectedSections((prevSections) => [...prevSections, section]);
    } else {
      setSelectedSections((prevSections) => prevSections.filter((sec) => sec !== section));
    }
  };

  const ContinueNext = () => {
    setShowSummary(true);
    let userDetails = JSON.parse(localStorage.getItem("user_details"));
    // this payload is for show values in summary

    let relevantSkillsByJD = Object.keys(allSkills).reduce((acc, category) => {
      acc.push(...allSkills[category].filter((skill) => skill.importance > 0));
      return acc;
    }, []);

    relevantSkillsByJD = relevantSkillsByJD.map((skill) => skill.skillKey);
    relevantSkillsByJD = [...relevantSkillsByJD, ...selectedSkillsAndWightages.map((skill) => skill.skillKey)];
    relevantSkillsByJD = [...new Set(relevantSkillsByJD)];

    let payload = {
      selectrole: selectedJobRoleTitle,
      catagory: selectedJobRoleCategory?.displayName,
      degree: selectedDegree?.displayName,
      year: selectedYearsOfExperience?.displayName,
      selectCoding: selectCoding,
      rolename: formik.values.rolename,
      textAreaValue: formik.values.textAreaValue,
      testingRequirement: selectedSections,
      isOverridePermitted: switchcheck,
      skillDetails: selectedSkillsAndWightages,
      totalWeightage: totalper,
      roleMetadata: {
        ...roleState?.getRoleFeaturesSuccess?.roleMetadata,
        ...roleState?.getRoleSkillsSuccess?.roleMetadata,
      },
      selectivity: selectedSelectivity?.displayName,
      // this payload we will use for submit the data to backend
      payload: {
        name: formik.values.rolename,
        note: formik.values.textAreaValue,
        type: selectedJobRoleType.displayName,
        // selectedSkills: skillNames,
        category: selectedJobRoleCategory?.id,
        degree: selectedDegree?.id,
        yoe: selectedYearsOfExperience?.id,
        codingLang: selectCoding,
        testingRequirement: selectedSections,
        createdBy: userDetails.name,
        skillDetails: selectedSkillsAndWightages.map((skill) => ({
          // remove || object.keys(authState?.skillsConfig).... after some time 2024 march 28
          skill:
            skill.skillKey ||
            Object.keys(authState?.skillsConfig).find((key) => authState?.skillsConfig[key].fullName === skill.skill),
          weightage: skill.weightage,
        })),
        totalWeightage: totalper,
        isOverridePermitted: switchcheck,
        roleMetadata:
          pathName === editpath
            ? {
                ...roleState?.roledetails?.roleMetadata,
                selectedSkillsAndWightages,
                relevantSkills,
                allSkills: allSkills,
                relevantSkillsByJD,
              }
            : {
                selectedSkills: roleState?.getRoleSkillsSuccess?.selectedSkills,
                ...roleState?.getRoleFeaturesSuccess?.roleMetadata,
                ...roleState?.getRoleSkillsSuccess?.roleMetadata,
                selectedSkillsAndWightages,
                relevantSkills,
                allSkills: allSkills,
                relevantSkillsByJD,
              },

        selectivity: selectedSelectivity?.id,
      },
    };
    // console.log(payload, "payload");
    setValues(payload);
  };

  const cancelBtn = () => {
    if (pathName === editpath) {
      navigate(generatePath("/newRoleView/:id", { id }));
    } else {
      navigate("/rolelist");
    }
  };

  const previousList = () => {
    setShowSummary(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSkillClick = (skillName, weightage, skillKey) => {
    // Check if the skill is already selected
    const isSkillSelected = selectedFromAllSkills.some((skill) => skill.skill === skillName);

    // If selected, remove it; otherwise, add it to the selected skills
    const updatedSkills = isSkillSelected
      ? selectedFromAllSkills.filter((skill) => skill.skill !== skillName)
      : [...selectedFromAllSkills, { skill: skillName, weightage, skillKey }];

    setSelectedFromAllSkills(updatedSkills);
  };

  const handleAddSkills = () => {
    // Combining selectedFromAllSkills and relevantSkills arrays
    const updatedRelevantSkills = [...relevantSkills, ...selectedFromAllSkills];

    setRelevantSkills(updatedRelevantSkills);

    // clear the selectedFromAllSkills array after merging
    setSelectedFromAllSkills([]);
    setOpen(false);
  };

  const handleResetSelection = () => {
    setSelectedFromAllSkills([]);
    if (pathName === editpath) {
      setRelevantSkills(roleState?.roledetails?.roleMetadata?.relevantSkills);
      setSelectedSkillsAndWightages(roleState?.roledetails?.roleMetadata?.selectedSkillsAndWightages);
    } else {
      const relevantSkillsAndWeightages = roleState.getRoleSkillsSuccess.relevantSkills.map((skill) => ({
        skill: skill.skillName,
        weightage: skill.weightage,
        skillKey: skill.skillKey,
      }));
      setRelevantSkills(relevantSkillsAndWeightages);
      const selectedSkillsAndWeightages = roleState.getRoleSkillsSuccess.selectedSkills.map((skill) => ({
        skill: skill.skillName,
        weightage: skill.weightage,
        skillKey: skill.skillKey,
      }));

      setSelectedSkillsAndWightages(selectedSkillsAndWeightages);
    }
    setOpen(false);
  };

  // edit role useEffect
  useEffect(() => {
    if (roleState?.roledetails && pathName === editpath) {
      setShowForm(true);
      if (roleState?.roledetails?.category?.[0]) {
        const categories = transformOptions(roleState?.jobRoleDataSuccess?.roleCategories);
        const matchedCategory = categories?.find(
          (category) =>
            category?.id === roleState?.roledetails?.category?.[0] ||
            category?.displayName === roleState?.roledetails?.category?.[0]
        );
        setSelectedJobRoleCategory(matchedCategory || "");
      }
      if (roleState?.roledetails?.degree?.[0]) {
        const degrees = transformOptions(roleState?.jobRoleDataSuccess?.degrees);
        const matchedDegree = degrees?.find(
          (degree) =>
            degree?.id === roleState?.roledetails?.degree?.[0] ||
            degree?.displayName === roleState?.roledetails?.degree?.[0]
        );
        setSelectedDegree(matchedDegree || "");
      }
      if (roleState?.roledetails?.yoe?.[0]) {
        const yearsOfExperience = transformOptions(roleState?.jobRoleDataSuccess?.yearsOfExperience);
        const matchedYearOfExperience = yearsOfExperience?.find(
          (yearOfExperience) =>
            yearOfExperience?.id === roleState?.roledetails?.yoe?.[0] ||
            yearOfExperience?.displayName === roleState?.roledetails?.yoe?.[0]
        );
        setSelectedYearsOfExperience(matchedYearOfExperience || "");
      }
      if (roleState?.roledetails?.selectivity) {
        const selectivity = transformOptions(roleState?.jobRoleDataSuccess?.selectivity);
        const matchedSelectivity = selectivity?.find(
          (selectivity) =>
            selectivity?.id === roleState?.roledetails?.selectivity ||
            selectivity?.displayName === roleState?.roledetails?.selectivity
        );
        setSelectedSelectivity(matchedSelectivity || "");
      }
      if (roleState?.roledetails?.codingLang?.length > 0) {
        setSelectCoding(
          pathName === editpath && !roleState?.roledetails?.codingLang?.includes("Not Required")
            ? roleState?.roledetails?.codingLang
            : []
        );
      }
      if (roleState?.roledetails?.testingRequirement?.length > 0) {
        setSelectedSections(roleState?.roledetails?.testingRequirement);
      }
      if (roleState?.roledetails?.isOverridePermitted) {
        setSwitchcheck(roleState?.roledetails?.isOverridePermitted);
      }
      if (roleState?.roledetails?.roleMetadata?.selectedSkillsAndWightages?.length > 0) {
        setSelectedSkillsAndWightages(roleState?.roledetails?.roleMetadata?.selectedSkillsAndWightages);
      }
      if (roleState?.roledetails?.name) {
        formik.setFieldValue("rolename", roleState?.roledetails?.name);
      }
      if (roleState?.roledetails?.type) {
        const typeOptions = transformOptions(roleState?.jobRoleDataSuccess?.jobRoleTypes);
        const matchedType = typeOptions?.find(
          (type) =>
            type?.id === roleState?.roledetails?.type ||
            (type?.displayName && type?.displayName.id === roleState?.roledetails?.type) ||
            (type?.displayName && type?.displayName.displayName === roleState?.roledetails?.type)
        );

        if (matchedType) {
          const transformedType = {
            id: matchedType.id,
            displayName: matchedType.displayName.displayName,
          };

          setSelectedJobRoleType(transformedType);
        }
      }
      if (roleState?.roledetails?.note) {
        formik.setFieldValue("textAreaValue", roleState?.roledetails?.note);
      }
      if (roleState?.roledetails?.roleMetadata?.job_description) {
        const formattedText = JSON.parse(roleState?.roledetails?.roleMetadata?.job_description);
        formik.setFieldValue("textAreaValuejob", formattedText);
      }
      if (roleState?.roledetails?.roleMetadata?.allSkills) {
        setAllSkills(roleState?.roledetails?.roleMetadata?.allSkills);
      }
      if (roleState?.roledetails?.roleMetadata?.relevantSkills) {
        setRelevantSkills(roleState?.roledetails?.roleMetadata?.relevantSkills);
      }
    }
  }, [roleState?.roledetails, roleState?.jobRoleDataSuccess]);

  // sorting the skills based on weightage
  const sortSkills = (skills) => {
    return skills.sort((a, b) => {
      if (a.weightage > b.weightage) return -1;
      if (a.weightage < b.weightage) return 1;

      // If weightage is the same, sort alphabetically
      return a?.skill?.localeCompare(b.skill);
    });
  };

  const sortSkillsByAlphabet = (skills) => {
    return skills.sort((a, b) => a.skill.localeCompare(b.skill));
  };

  const displayToastMSg = (msg) => {
    setToastMessage(msg);
    setOpenToast(true);
  };

  const handleCloseSnackBar = () => {
    setOpenToast(false);
  };

  useEffect(() => {
    if (authState?.checkSubscriptionStatusRoleData?.maxLimit === 0) {
      setIsMaxLimitZero(true);
    }
  }, [authState?.checkSubscriptionStatusRoleData]);

  return (
    <>
      {isMaxLimitZero ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <NavBar />
          <Container maxWidth="xl">
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ width: "50%", margin: "auto", textAlign: "center" }}
            >
              <Grid item className={classes.messageContainer}>
                {userDetails.roleSubType === "TeamAdmin" ? (
                  <Typography>
                    {" "}
                    You have reached your usage limit for creating a role or your subscription is not active. Please
                    upgrade your subscription.
                  </Typography>
                ) : (
                  <Typography>
                    You have reached your usage limit for creating a role or your subscription is not active. Please
                    contact Team Admin to upgrade your subscription.
                  </Typography>
                )}
              </Grid>
              <Grid item style={{ marginTop: "30px" }} className={classes.buttonContainer}>
                <Typography sx={{ mr: "30px" }} className={classes.textview}>
                  <a href="/rolelist">Back to Roles page</a>
                </Typography>
                {userDetails.roleSubType === "TeamAdmin" ? (
                  <Button
                    variant="contained"
                    color="btn"
                    onClick={() => navigate("/accountSettings", { state: { selectedSection: "billing" } })}
                    text="Upgrade"
                  ></Button>
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <>
          {showSummary !== true ? (
            <>
              <NavBar />
              <Container maxWidth="xl" className={classes.container}>
                <Grid style={{ marginTop: "130px", minWidth: "560px" }}>
                  <Grid style={{ display: "flex" }}>
                    <CustomBreadcrumbs
                      links={[
                        { name: "Home", path: "/home" },
                        { name: "Roles", path: "/rolelist" },
                        { name: pathName === editpath ? "Edit Role" : "Create New Role" },
                      ]}
                    />
                  </Grid>
                  <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Grid className={classes.spaceflex}>
                        <Typography className={classes.roletext}>
                          {pathName === editpath ? "Edit Role" : "Create New Role"}
                        </Typography>
                        <Typography className={classes.roletext}>
                          {pathName === editpath ? `Role ID ${roleState?.roledetails?.uid}` : ""}
                        </Typography>
                      </Grid>
                      <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                      <Grid>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.roletype1}>Role Description</Typography>
                            <Typography className={classes.roletypetext}>
                              Paste a job description or select a standard role type to get a template job description.
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <SelectMenu
                              selectvalue={sortedJobRoleTypes}
                              selectHandleChange={(value) => handleChangeRoleType(value)}
                              prevSelectedValue={selectedJobRoleType}
                              disabled={pathName === editpath}
                            />
                          </Grid>
                          <Grid xs={2}></Grid>
                        </Grid>
                      </Grid>
                      <Grid className={classes.spaceflex}>
                        <textarea
                          id="textAreaValuejob"
                          value={formik.values.textAreaValuejob}
                          disabled={pathName === editpath}
                          style={{ resize: "none", color: pathName === editpath ? "grey" : "black" }}
                          onChange={formik.handleChange}
                          placeholder="Paste a job description here and let our AI extract and fill out all the details to create a standardized job role. If you don’t have a job description then select A Standard Role Type from the dropdown above."
                          maxLength="5000"
                          rows={5}
                          cols={5}
                          className={formik.values.textAreaValuejob === "" ? classes.textareasty : classes.textareasty1}
                        />
                      </Grid>
                      <Grid display={"flex"} justifyContent={"end"} sx={{ mt: 2 }}>
                        <Button
                          data-rt-role-view=" Generateprofile"
                          color={formik.values.textAreaValuejob.length >= 50 ? "btnCls" : "btnCls2"}
                          text={" Generate Role"}
                          onClick={formik.values.textAreaValuejob.length >= 50 ? GenerateProfile : undefined}
                          width={199}
                          variant="outlined"
                          disabled={pathName === editpath}
                          endIcon={<AutoAwesomeIcon />}
                        ></Button>
                      </Grid>
                      {showForm ? (
                        <Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />

                          {roleFeaturesLoading ? (
                            <Grid>
                              <Grid container spacing={3}>
                                <Grid item xs={6} sx={{ my: 2 }}>
                                  <Skeleton variant="text" />
                                  <Skeleton variant="text" />
                                </Grid>
                                <Grid item xs={4}>
                                  <Skeleton variant="rect" height={48} />
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={6} sx={{ my: 2 }}>
                                  <Skeleton variant="text" />
                                  <Skeleton variant="text" />
                                </Grid>
                                <Grid item xs={4}>
                                  <Skeleton variant="rect" height={48} />
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={6} sx={{ my: 2 }}>
                                  <Skeleton variant="text" />
                                  <Skeleton variant="text" />
                                </Grid>
                                <Grid item xs={4}>
                                  <Skeleton variant="rect" height={48} />
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={6} sx={{ my: 2 }}>
                                  <Skeleton variant="text" />
                                  <Skeleton variant="text" />
                                </Grid>
                                <Grid item xs={4}>
                                  <Skeleton variant="rect" height={48} />
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={6} sx={{ my: 2 }}>
                                  <Skeleton variant="text" />
                                  <Skeleton variant="text" />
                                </Grid>
                                <Grid item xs={4}>
                                  <Skeleton variant="rect" height={48} />
                                </Grid>
                                <Grid item xs={2}></Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <Typography className={classes.roletype1}>Role Title</Typography>
                                <Typography className={classes.roletypetext}>
                                  Give a name to your role that is unique and easy for you to search.
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <TextInput
                                  classes={{ root: classes.customTextField }}
                                  customFontSize={true}
                                  name="rolename"
                                  placeholder="Ex. Walmart Java Backend"
                                  className={formik.values.rolename === "" ? classes.field : classes.field1}
                                  id="rolename"
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.rolename && formik.errors.rolename
                                      ? Boolean(formik.errors.rolename)
                                      : null
                                  }
                                  onBlur={formik.handleBlur}
                                  value={formik.values.rolename}
                                ></TextInput>
                                {formik.touched.rolename && formik.errors.rolename ? (
                                  <div className="error">
                                    <Typography className={classes.errorty} style={theme.typography.error}>
                                      {formik.errors.rolename}
                                    </Typography>
                                  </div>
                                ) : isRoleNameDuplicate ? (
                                  <div className="error">
                                    <Typography className={classes.errorty} style={theme.typography.error}>
                                      Role name already exists.
                                    </Typography>
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={6}>
                                <Typography className={classes.roletype1}>Role Category</Typography>
                                <Typography className={classes.roletypetext}>
                                  Change role category based on complexity and work expectations from the role.
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <SelectMenu
                                  selectvalue={roleState?.jobRoleDataSuccess?.roleCategories}
                                  selectHandleChange={handleChangeCategory}
                                  prevSelectedValue={selectedJobRoleCategory}
                                />
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={6}>
                                <Typography className={classes.roletype1}>Required Degree</Typography>
                                <Typography className={classes.roletypetext}>
                                  This is the recommended degree for the role. Change if needed.
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <SelectMenu
                                  selectvalue={roleState?.jobRoleDataSuccess?.degrees}
                                  selectHandleChange={(value) => handleChangeDegree(value)}
                                  prevSelectedValue={selectedDegree}
                                />
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={6}>
                                <Typography className={classes.roletype1}>Years of Experience</Typography>
                                <Typography className={classes.roletypetext}>
                                  Our AI recommends this. Adjust if required.
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <SelectMenu
                                  selectvalue={roleState?.jobRoleDataSuccess?.yearsOfExperience}
                                  selectHandleChange={(value) => handleChangeYear(value)}
                                  prevSelectedValue={selectedYearsOfExperience}
                                />
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={6}>
                                <Typography className={classes.roletype1}>Selectivity</Typography>
                                <Typography className={classes.roletypetext}>
                                  Decide how stringent you want to keep your selection criteria.
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <SelectMenu
                                  selectvalue={roleState?.jobRoleDataSuccess?.selectivity}
                                  selectHandleChange={(value) => handleChangeSelectivity(value)}
                                  prevSelectedValue={selectedSelectivity}
                                />
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={6}>
                                <Typography className={classes.roletype1}>Coding Languages</Typography>
                                <Typography className={classes.roletypetext}>
                                  Candidate can select one of these languages during the coding test.
                                </Typography>
                              </Grid>
                              {roleSkillsLoading ? (
                                <Grid item xs={4} sx={{ marginLeft: "3px" }}>
                                  <Skeleton variant="rect" height={48} />
                                </Grid>
                              ) : (
                                <Grid item xs={4} sx={{ marginTop: "15px", marginLeft: "3px" }}>
                                  <SelectWithCheckbox
                                    title="Choose Language"
                                    options={handleSortJobRoleCodingLanguages()}
                                    handleSelectOption={(value) => handleChangeCoding(value)}
                                    MenuProps={codingLangMenuProps}
                                    width="100%"
                                    checkLength={selectCoding?.length ?? 0}
                                    BgColor={selectCoding?.length !== 0 && "#EBEFF8"}
                                    defaultValue={selectCoding}
                                    handleSelectAll={onSelectAllCodingLang}
                                    handleClearAll={onClearAllCodingLang}
                                    disabledCapitalize
                                  />
                                </Grid>
                              )}
                              <Grid item xs={2}></Grid>
                            </Grid>
                          )}

                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography className={classes.roletype1} sx={{ mt: 3 }}>
                                Technical Skills
                              </Typography>
                              <Typography className={classes.roletypetext}>
                                Select up to 5 skills and their weightage. Our AI recommends the following skills.
                                Update the selection as you see fit.
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography className={classes.roletype1} sx={{ fontSize: "16px !important" }}>
                                Selected Skills
                              </Typography>

                              {roleSkillsLoading ? (
                                <Grid sx={{ mt: 2 }}>
                                  <Skeleton variant="rect" height={480} />
                                </Grid>
                              ) : (
                                <Grid>
                                  {sortSkills(selectedSkillsAndWightages || []).map((skill, i) => (
                                    <div key={skill.skill} className={classes.skillsColumns}>
                                      <div>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={selectedSkillsAndWightages.some(
                                                (selectedSkill) => selectedSkill.skill === skill.skill
                                              )}
                                              onChange={() =>
                                                handleCheckboxChange(skill.skill, skill.weightage, skill.skillKey)
                                              }
                                            />
                                          }
                                          label={skill.skill}
                                        />
                                      </div>

                                      <FormControl sx={{ m: 1, minWidth: 60 }} className={classes.selectMenu}>
                                        <Select
                                          value={skill.weightage || percentage[skill.skill]?.select}
                                          onChange={(event) => HandleChangePercentage(event, skill)}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          className={classes.selectText}
                                          IconComponent={() => <Typography className={classes.percent}>%</Typography>}
                                        >
                                          {(selectpercentage || []).map((page) => (
                                            <MenuItem key={page} value={page}>
                                              {page}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  ))}
                                  {roleSkillsLoading ? null : (
                                    <>
                                      <Grid
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          paddingLeft: "5px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <Typography className={classes.roletypetext}>Total</Typography>
                                        <Typography
                                          className={classes.roletypetext}
                                          style={{
                                            marginRight: "12px",
                                            color: totalper === 100 ? "" : "#DD2E2E",
                                          }}
                                        >
                                          {totalper}%
                                        </Typography>
                                      </Grid>
                                      <Grid>
                                        {" "}
                                        {totalper !== 100 ? (
                                          <Typography className={classes.errorty} style={theme.typography.error}>
                                            Total skill weightage should add up to 100 %
                                          </Typography>
                                        ) : null}
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={5}>
                              <Grid
                                sx={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  gap: 1,
                                }}
                              >
                                <Typography className={classes.roletype1} sx={{ fontSize: "16px !important" }}>
                                  Other Relevant Skills
                                </Typography>
                                <Typography
                                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                                  className={classes.roletypetext}
                                  onClick={handleOpen}
                                >
                                  Click here to see the complete skill set
                                </Typography>
                              </Grid>

                              {roleSkillsLoading ? (
                                <Grid sx={{ mt: 2 }}>
                                  <Skeleton variant="rect" height={480} />
                                </Grid>
                              ) : (
                                <Grid>
                                  {sortSkillsByAlphabet(relevantSkills)
                                    ?.filter(
                                      (skill) =>
                                        !selectedSkillsAndWightages.some(
                                          (selectedSkill) => selectedSkill.skill === skill.skill
                                        )
                                    )
                                    // .sort((a, b) => b.importance - a.importance)
                                    // .slice(0, 10)
                                    .map((skill) => (
                                      <div key={skill.skill} className={classes.skillsColumns}>
                                        <div>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                disabled={selectedSkillsAndWightages?.length === 5}
                                                checked={selectedSkillsAndWightages.some(
                                                  (selectedSkill) => selectedSkill.skill === skill.skill
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange(skill.skill, skill.weightage, skill.skillKey)
                                                }
                                              />
                                            }
                                            label={skill.skill}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.roletype1}>Sections Included in the Test</Typography>
                              <Typography className={classes.roletypetext}>
                                Select sections that must be included in the test. The profile section is mandatory and
                                requires only a resume. At least one of the Coding and Technical sections must also be
                                selected.
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Grid container spacing={3}>
                                {roleSkillsLoading ? (
                                  <>
                                    <Grid item xs={6}>
                                      <div>
                                        <label className={classes.cursorPointer}>
                                          <Skeleton variant="rect" width="100%" height={50} />
                                        </label>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        <label className={classes.cursorPointer}>
                                          <Skeleton variant="rect" width="100%" height={50} />
                                        </label>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        <label className={classes.cursorPointer}>
                                          <Skeleton variant="rect" width="100%" height={50} />
                                        </label>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div>
                                        <label className={classes.cursorPointer}>
                                          <Skeleton variant="rect" width="100%" height={50} />
                                        </label>
                                      </div>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item xs={6}>
                                      <div style={{ backgroundColor: "#FFFBF6" }}>
                                        <label className={classes.cursorPointer}>
                                          <Checkbox
                                            checked={true}
                                            onChange={() => displayToastMSg("Profile section is mandatory")}
                                          />
                                          <span>Profile Section (Mandatory)</span>
                                        </label>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div
                                        style={{ backgroundColor: "#FFFBF6" }}
                                        onClick={() => {
                                          if (selectCoding?.length === 0) {
                                            displayToastMSg(
                                              "Atleast one of the Coding and Technical sections is mandatory."
                                            );
                                          }
                                        }}
                                      >
                                        <label className={classes.cursorPointer}>
                                          <Checkbox
                                            checked={
                                              pathName === editpath
                                                ? selectedSections.includes("Technical")
                                                : isTechnicalChecked
                                            }
                                            onChange={(e) => {
                                              if (!e.target.checked && !isCodingChecked) {
                                                return;
                                              }
                                              setIsTechnicalChecked(e.target.checked);
                                              handleCheckbox("Technical", e.target.checked);
                                            }}
                                          />
                                          <span>Technical Section</span>
                                        </label>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div
                                        style={{ backgroundColor: "#FFFBF6" }}
                                        onClick={() => {
                                          if (selectCoding?.length === 0) {
                                            displayToastMSg("To select this section, choose a coding language.");
                                          } else {
                                            displayToastMSg("Coding is mandatory if you select coding a language.");
                                          }
                                        }}
                                      >
                                        <label className={classes.cursorPointer}>
                                          <Checkbox
                                            disabled={selectCoding?.length === 0}
                                            checked={isCodingChecked}
                                            onChange={(e) => {
                                              if (!e.target.checked && !isTechnicalChecked) {
                                                return;
                                              }
                                            }}
                                          />
                                          <span>Coding Section</span>
                                        </label>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div style={{ backgroundColor: "#FFFBF6" }}>
                                        <label className={classes.cursorPointer}>
                                          <Checkbox
                                            checked={
                                              pathName === editpath
                                                ? selectedSections.includes("Presentation")
                                                : isBehavioralChecked
                                            }
                                            onChange={(e) => {
                                              setIsBehavioralChecked(e.target.checked);
                                              handleCheckbox("Presentation", e.target.checked);
                                            }}
                                          />
                                          <span>Behavioral Section</span>
                                        </label>
                                      </div>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={12}>
                              <Grid sx={{ display: "flex" }}>
                                <div>
                                  <AntSwitch
                                    inputProps={{ "aria-label": "ant design" }}
                                    onChange={switchChange}
                                    checked={switchcheck}
                                  />
                                </div>
                                <Typography className={classes.Textsty} style={{ marginTop: "7px" }}>
                                  Override Permitted
                                </Typography>
                              </Grid>
                              <Typography className={classes.roletypetext}>
                                Turn on this flag if you want to allow changes in section selection at the time of
                                sending invites.
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.roletype1} style={{ color: "#2F2F2F" }}>
                                Internal Notes
                              </Typography>
                              <textarea
                                style={{
                                  resize: "none",
                                  height: "140px !important",
                                }}
                                value={formik.values.textAreaValue}
                                id="textAreaValue"
                                onChange={formik.handleChange}
                                placeholder="Add any additional notes here about this role. These notes are for your team only. They are not shown to the candidates."
                                rows={5}
                                cols={5}
                                className={classes.textareastyNotes}
                                maxLength="500"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  data-rt-role-add="cancel"
                                  color="cancel_btn"
                                  text="Cancel"
                                  onClick={cancelBtn}
                                  width={112}
                                ></Button>
                                <>
                                  {formik.values.rolename === "" ||
                                  isRoleNameDuplicate ||
                                  totalper !== 100 ||
                                  !selectedJobRoleCategory ||
                                  !selectedDegree ||
                                  !selectedYearsOfExperience ||
                                  !selectedSelectivity ? (
                                    <Button
                                      color="disablebtn"
                                      width={240}
                                      data-rt-role-add="ContinuetoReview"
                                      text={"Continue to Review"}
                                    />
                                  ) : (
                                    <Button
                                      color="btn"
                                      width={240}
                                      data-rt-role-add="ContinuetoReview"
                                      text={"Continue to Review"}
                                      type="submit"
                                      onClick={ContinueNext}
                                    />
                                  )}
                                </>
                              </Grid>
                            </Grid>
                          </Grid>
                          <SkillsDialog
                            open={open}
                            handleClose={handleClose}
                            allSkills={allSkills}
                            selectedFromAllSkills={selectedFromAllSkills}
                            selectedSkillsAndWightages={selectedSkillsAndWightages}
                            relevantSkills={relevantSkills}
                            handleSkillClick={handleSkillClick}
                            handleResetSelection={handleResetSelection}
                            handleAddSkills={handleAddSkills}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
              </Container>
            </>
          ) : (
            <>
              (
              <NewNameNote values={values} previousList={(previous) => previousList(previous)} />)
            </>
          )}
          <Footer />
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openToast}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
            message={toastMessage}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </>
      )}
    </>
  );
};

export default CreateJobRole;
