import React from "react";
import {
  CssBaseline,
  Typography,
  Paper,
  Grid,
  TextField,
  Container,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "../../components/Footer";
import { theme } from "../../styles/theme";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "../../components/controls/Button";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../redux/actions/auth";

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "calc(100vh - 170px)",
    padding: "40px 20px",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    zIndex: 100,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  sideHeadingContainer: {
    padding: "15px 0px",
    marginTop: "80px",
  },
  sideHeading: {
    fontSize: "28px !important",
    fontWeight: "800 !important",
  },
  formText: {
    padding: "40px 0px",
  },
  text: {
    fontSize: "16px !important",
  },
});

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  // phone: yup.string().required("Phone number is required"),
  orgName: yup.string().required("Company name is required"),
  notes: yup.string().required("Message is required"),
  agreeToTerms: yup.boolean().oneOf([true], "You must agree to the Terms of Conditions and Privacy Policy."),
});

const ContactUs = () => {
  const classes = useStyles();
  const logoUrl = process.env.REACT_APP_ASSETS_URL;
  const headerImageUrl = `${logoUrl}techscore.svg`;
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      orgName: "",
      notes: "",
      agreeToTerms: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(auth.createTicket(values));
      // console.log(values);
    },
  });

  return (
    <>
      <CssBaseline />
      <Grid className={classes.logocls}>
        <a href="https://techscore.ai" target="_blank" rel="noreferrer" style={{ display: "contents" }}>
          <img height="50" width="157" src={headerImageUrl} alt="TechScore" />
        </a>
      </Grid>
      {authState?.createTicketSuccess?.status === 0 ? (
        <div className={`${classes.mainContainer}`}>
          <Container component="main" maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 4, textAlign: "center" }}>
              <Box
                sx={{
                  borderRadius: "50%",
                  height: 150,
                  width: 150,
                  background: "#F8FAF5",
                  margin: "0 auto",
                }}
              >
                <Typography
                  component="i"
                  variant="h2"
                  sx={{
                    color: "#9ABC66",
                    fontSize: 80,
                    lineHeight: "150px",
                    marginLeft: "-15px",
                  }}
                >
                  ✓
                </Typography>
              </Box>
              <Typography variant="h5" component="h5" color="primary" sx={{ fontWeight: 600, my: 2 }}>
                Success
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ fontSize: 18, margin: 0 }}>
                Thank you for your interest in TechScore. One of our representatives will get in touch with you soon.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Go back to{" "}
                <a href="https://techscore.ai" target="_self" rel="noreferrer">
                  Home
                </a>{" "}
              </Typography>
            </Paper>
          </Container>
        </div>
      ) : (
        <Grid className={classes.mainContainer}>
          <Paper sx={{ p: 2, mx: { xs: 0, sm: 1, md: 10, lg: 25, xl: 50 } }} elevation={0}>
            <Grid className={classes.sideHeadingContainer}>
              <Typography className={classes.sideHeading}>Contact Us</Typography>
            </Grid>
            <Grid className={classes.formText}>
              <Typography className={classes.text}>
                Please share your contact information so our team can get in touch with you. We can help answer your
                questions, set up a demo, or sign you up for a free trial subscription.
              </Typography>
            </Grid>
            <Grid>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lname"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Work Email"
                      name="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="phone"
                      label="Phone Number"
                      name="phone"
                      autoComplete="tel"
                      value={formik.values.phone}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="orgName"
                      label="Company Name"
                      name="orgName"
                      autoComplete="organization"
                      value={formik.values.orgName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.orgName && Boolean(formik.errors.orgName)}
                      helperText={formik.touched.orgName && formik.errors.orgName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="notes"
                      label="Message"
                      name="notes"
                      multiline
                      rows={4}
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.notes && Boolean(formik.errors.notes)}
                      helperText={formik.touched.notes && formik.errors.notes}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agreeToTerms"
                          checked={formik.values.agreeToTerms}
                          onChange={formik.handleChange}
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="body2">
                          I agree with the{" "}
                          <a href="/terms&conditions" target="_self">
                            Terms
                          </a>{" "}
                          and the{" "}
                          <a href="/privacypolicy" target="_self">
                            Privacy Policy
                          </a>
                          .
                        </Typography>
                      }
                    />
                    {formik.touched.agreeToTerms && formik.errors.agreeToTerms && (
                      <Typography className={classes.errorty}>{formik.errors.agreeToTerms}</Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid sx={{ mt: 4 }}>
                  <Button
                    type="submit"
                    text="Submit"
                    color={!formik.dirty || !formik.isValid || formik.isSubmitting ? "disableBtn" : "btn"}
                    className={classes.submit}
                    disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                  />
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Footer />
    </>
  );
};

export default ContactUs;
