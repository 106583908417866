import React, { useEffect, useMemo, useState } from "react";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Typography,
  Grid,
  Tooltip,
  Box,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Paper,
  TextField,
  IconButton,
  Link,
} from "@mui/material";
import GridTable from "../../../components/Table";
import { theme } from "../../../styles/theme";
import Button from "../../../components/controls/Button";
import GraphScoreMeter from "../../../components/GraphScoreMeter/GraphScoreMeter";
import { useNavigate, generatePath, useParams } from "react-router-dom";
import SimpleSnackbar from "../../../components/controls/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
import Footer from "../../../components/Footer";
import moment from "moment";
import DeactivateModel from "../../../components/controls/DeactiviteModel";
import ReactiveModel from "../../../components/controls/ReactiveModel";
import { TimeZonePT, applySearch } from "../../../services/globalService";
import { sortJson, codingLanguageDisplay } from "../../../services/globalService";
import CandidateComparison from "./CandidateComparison";
import SearchBar from "../../../components/controls/search";
import CheckSelectMenu from "../../../components/controls/Checkmenu";
import auth from "../../../redux/actions/auth";
import CloseIcon from "@mui/icons-material/Close";
import CustomPagination from "../../../components/controls/CustomPagination";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button as MuiButton } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SelectWithCheckbox from "../../../components/controls/SelectWithCheckbox";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ColoredLinearProgress from "../../../components/controls/coloredLoadingProgress";
import ReusableDialog from "../../../components/controls/ReusableDialog";
import CustomBreadcrumbs from "../../../components/controls/CustomBreadcrumbs";

const useStyles = makeStyles({
  loader: {
    position: "fixed",
    top: "60px",
    width: "100%",
    zIndex: 1400,
    [theme.breakpoints.up("tablet")]: {
      top: "80px",
      zIndex: 1,
    },
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
    display: "flex",
    flexWrap: "nowrap",
  },
  roletypeNum: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: "#2F2F2F",
    textAlign: "end",
    paddingTop: "10px",
    flexWrap: "nowrap",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
  },
  down_arrow: {
    padding: "8px",
    marginTop: "10px",
  },
  btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "#1789FC !important",
  },
  cancel_btn: {
    width: "112px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#787878 !important",
    boxShadow: "none !important",
  },
  previous_btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#1789FC !important",
    boxShadow: "none !important",
    border: "1px solid #C4C4C4 !important",
    marginRight: "10px !important",
  },
  field: {
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  textareasty: {
    width: "513px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
  graphScoreMeterContainer: {
    minWidth: "500px",
    marginTop: "20px",
    position: "relative",
    height: "250px",
    border: "12px solid #FFFBF6",
  },
  comparison: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px !important",
  },
  comparisonLink: {
    textDecoration: "none !important",
    color: "#3672CA !important",
    fontWeight: "500 !important",
    cursor: "pointer",
  },
  muiBtn: {
    textTransform: "none !important",
    color: "gray !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    borderRadius: "4px !important",
    // '&:hover': {
    //   backgroundColor: '#1789FC !important',
    // },
  },
  muiBtnActive: {
    textTransform: "none !important",
    color: "primary !important",
    fontSize: "20px !important",
    fontWeight: "500 !important",
    borderRadius: "4px !important",
  },

  boldText: theme.typography.b1Medium,
  stats: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5%",
  },
  card: {
    borderRadius: "4px",
    background: "#FFFBF6",
    cursor: "pointer",
    padding: "3px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  SelectedCard: {
    borderRadius: "4px",
    background: "#FFFBF6",
    cursor: "pointer",
    padding: "3px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    border: `1px solid ${theme.palette.mainPaletteBlue} !important`,
  },
  cardNumber: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `600 !important`,
    fontSize: "24px !important",
    color: `${theme.palette.mainPaletteBlue} !important`,
  },
  cardtext: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `500 !important`,
    fontSize: "14px !important",
    display: "flex",
    alignItems: "end",
    padding: "0px 0px 5px 0px",
    color: `${theme.palette.black.dark} !important`,
    whiteSpace: "nowrap",
  },
  dotFlashing: {
    "&::after": {
      display: "inline-block",
      marginLeft: "4px",
      content: '""',
      animation: `$dots 2s infinite`,
    },
  },
  // Keyframes for the dots animation
  "@keyframes dots": {
    "0%": { content: '""' },
    "25%": { content: '"."' },
    "50%": { content: '".."' },
    "75%": { content: '"..."' },
    // "100%": { content: '"."' }, // Back to one dot, restart animation
  },
  flashingIcon: {
    animation: `$flash 1s infinite`,
  },
  // keyframes for the flashing icon
  "@keyframes flash": {
    "0%": {
      opacity: 1,
    },
    "25%": {
      opacity: 0.75,
    },
    "50%": {
      opacity: 0.5,
    },
    "75%": {
      opacity: 0.25,
    },
  },
});

const addedByMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      padding: 12,
      marginLeft: 46,
    },
  },
};

const strictlyMatchMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      padding: 12,
      marginLeft: 46,
    },
  },
};

const candidateCategoryMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      padding: 12,
      marginLeft: 46,
    },
  },
};

const skillsFilterMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      padding: 12,
      marginLeft: 46,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  outline: "none",
  width: "300px",
};

const RoleView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const [snacktext, setsnacktext] = useState("");
  const [opendialog, setOpendialog] = useState(false);
  const [reactiveopen, setReactiveopen] = useState(false);
  const [publishstatus, setPublishstatus] = useState(false);
  const [deactiverole, setDeactivaterole] = useState(false);
  const [reactiverole, setReactiverole] = useState(false);
  const [userid, setUserid] = useState(null);
  const [inviteview, setInviteview] = useState(false);
  const [deactivebtn, setDeactivebtn] = useState(false);
  const [activebtn, setActivebtn] = useState(false);
  const [publishbtn, setPublishbtn] = useState(false);
  const isAuthLoading = useSelector((state) => state.role.isroleLoading);
  const [candidateInviteList, setCandidateInviteList] = useState(roleState?.candidateinvitelist);
  const authState = useSelector((state) => state.auth);
  const [addedByFilter, setAddedByFilter] = useState([]);
  const [strictlyFilter, setStrictlyFilter] = useState([]);
  const [candidateCategory, setCandidateCategory] = useState([]);

  const [addedBy, setAddedBy] = useState([]);
  const [strictlyArray, setstrictlyArray] = useState([]);
  const [candidateCategoryArray, setCandidateCategoryArray] = useState([]);
  const [skillsArray, setSkillsArray] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [addedBylength, setAddedBylength] = useState(0);
  const [strictlyMatchlength, setStrictlyMatchlength] = useState(0);
  const [candidateCategorylength, setCandidateCategorylength] = useState(0);
  const [skillsArraylength, setSkillsArraylength] = useState(0);
  const [addedBColor, setAddedBColor] = useState("");
  const [strictlyBColor, setStrictlyBColor] = useState("");
  const [candidateCategoryBColor, setCandidateCategoryBColor] = useState("");
  const [skillsBColor, setSkillsBColor] = useState("");
  const [matchingRows, setMatchingRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextInvited, setSearchTextInvited] = useState("");
  const [categoryToDisplay, setCategoryToDisplay] = useState([]);
  const [matchUiSortModel, setMatchUiSortModel] = useState([
    {
      field: "matchLevel",
      sort: "desc",
    },
  ]);
  const [showCandidateComparison, setShowCandidateComparison] = useState(false);
  const [showInvitedCandidates, setShowInvitedCandidates] = useState(false);
  const [showMatchAndSearchCandidates, setShowMatchAndSearchCandidates] = useState(false);
  const [skillsOptions, setSkillsOptions] = useState([]);

  const [firstFilter, setFirstFilter] = useState("all");
  const [isCustomDateModalOpen, setCustomDateModalOpen] = useState(false);
  const [customDate, setCustomDate] = useState("Custom Date Range...");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  // const [customDateFilter, setCustomDateFilter] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sentInvites, setSentInvites] = useState([]);
  const [openMatchModal, setOpenMatchModal] = useState(false);

  const { id } = useParams();
  const [uiSortModel, setUiSortModel] = useState([
    {
      field: "uid",
      sort: "desc",
    },
  ]);
  // stats candidate invite list
  const [invitedCandidatesCount, setInvitedCandidatesCount] = useState(0);
  const [respondedCandidatesCount, setRespondedCandidatesCount] = useState(0);
  const [completedCandidatesCount, setCompletedCandidatesCount] = useState(0);
  const [targetAchievedCandidatesCount, setTargetAchievedCandidatesCount] = useState(0);
  const [fieldsData, setFieldsData] = useState({});

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showLinearLoading, setShowLinearLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("invited");

  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  useEffect(() => {
    if (id) {
      dispatch(role.roleDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (roleState?.roledetailsFailure?.status) {
      navigate("/pageNotFound", { state: { data: roleState?.roledetailsFailure } });
    }
  }, [roleState?.roledetailsFailure]);

  useEffect(() => {
    //dispatch(role.roleDetails(id))
    dispatch(role.candidateinviteList({ jobRoleId: id }));
  }, []);

  // console.log(roleState?.roledetails?.status, "roleState?.roledetails");
  useEffect(() => {
    if (Array.isArray(roleState?.candidateinvitelist)) {
      let invitedCount = 0;
      let respondedCount = 0;
      let completedCount = 0;
      let targetAchievedCount = 0;

      roleState?.candidateinvitelist?.forEach((candidate) => {
        invitedCount++;
        if (candidate?.candidateResponded) {
          respondedCount++;
        }
        if (candidate?.isCompleted === 1) {
          completedCount++;
        }
        if (candidate?.proportionalScore >= candidate?.jobRoleTargetScore) {
          targetAchievedCount++;
        }
      });

      setCandidateInviteList(roleState?.candidateinvitelist);
      setInvitedCandidatesCount(invitedCount);
      setRespondedCandidatesCount(respondedCount);
      setCompletedCandidatesCount(completedCount);
      setTargetAchievedCandidatesCount(targetAchievedCount);

      if (invitedCount >= 2 && completedCount >= 2) {
        setShowCandidateComparison(true);
        setShowMatchAndSearchCandidates(false);
        setShowInvitedCandidates(false);
      } else if (invitedCount > 1) {
        setShowInvitedCandidates(true);
        setShowMatchAndSearchCandidates(false);
        setShowCandidateComparison(false);
      } else if (invitedCount <= 1) {
        setShowMatchAndSearchCandidates(true);
        setShowInvitedCandidates(false);
        setShowCandidateComparison(false);
      }
    }
  }, [roleState?.candidateinvitelist]);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    switch (filter) {
      case "invited":
        setCandidateInviteList(roleState.candidateinvitelist);
        break;
      case "responded":
        setCandidateInviteList(roleState.candidateinvitelist.filter((candidate) => candidate.candidateResponded));
        break;
      case "completed":
        setCandidateInviteList(roleState.candidateinvitelist.filter((candidate) => candidate.isCompleted === 1));
        break;
      case "targetAchieved":
        setCandidateInviteList(
          roleState.candidateinvitelist.filter(
            (candidate) => candidate.proportionalScore >= candidate.jobRoleTargetScore
          )
        );
        break;
      default:
        setCandidateInviteList(roleState.candidateinvitelist);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // you can also use 'auto' behaviour in place of 'smooth'
    });
  };

  const PublishRole = () => {
    if (userDetails.roleSubType !== "Contributor") {
      let payload = {
        status: "active",
      };
      let payloadrole = {
        id: id,
        data: payload,
      };
      dispatch(role.roleStatus(payloadrole));
      setPublishstatus(true);
      setPublishbtn(true);
    }
  };

  const DeactivateRole = (value) => {
    let payload = {
      status: "inactive",
      note: value,
    };
    let payloadrole1 = {
      id: id,
      data: payload,
    };
    dispatch(role.roleStatus(payloadrole1));
    setDeactivaterole(true);
    setDeactivebtn(true);
  };

  const handleReactive = () => {
    let payload = {
      status: "active",
      // notesLog:value
    };
    let payloadrole2 = {
      id: id,
      data: payload,
    };
    dispatch(role.roleStatus(payloadrole2));
    setReactiverole(true);
    setActivebtn(true);
  };

  useEffect(() => {
    if (publishstatus === true && roleState?.rolestatus?.status === 0) {
      setOpen(true);
      setPublishstatus(false);
      setsnacktext("Published Role");
      let add_id = roleState?.rolestatus?.data?.uid;
      setTimeout(() => {
        navigate(generatePath("/newRoleView/:id", { id: add_id }));
        setPublishbtn(false);
      }, 500);
    } else if (deactiverole === true && roleState?.rolestatus?.status === 0) {
      setOpen(true);
      setsnacktext("Role has been deactivated");
      setOpendialog(false);
      setDeactivaterole(false);

      setTimeout(() => {
        navigate(generatePath("/newRoleView/:id", { id }));
        setDeactivebtn(false);
      }, 1000);
    } else if (reactiverole === true && roleState?.rolestatus?.status === 0) {
      setOpen(true);
      setsnacktext("Role is now active");
      setReactiveopen(false);
      setReactiverole(false);
      setTimeout(() => {
        navigate(generatePath("/newRoleView/:id", { id }));
        setActivebtn(false);
      }, 1000);
    } else if (roleState?.rolestatus?.status === 1) {
      setOpen(true);
      setsnacktext("Something went wrong");
      setActivebtn(false);
    }
    // eslint-disable-next-line
  }, [roleState?.rolestatus?.status]);

  const OpenDeleteRecruiter = () => {
    if (userDetails.roleSubType !== "Contributor") {
      setOpendialog(true);
    }
  };
  const EditRole = () => {
    if (userDetails.roleSubType !== "Contributor") {
      navigate(generatePath("/editJobRole/:id", { id }));
    }
  };

  const handleSortChange = (sortInfo) => {
    setUiSortModel([sortInfo]);
    let sortedOrder = sortJson(
      [...candidateInviteList],
      sortInfo.sort,
      sortInfo.field,
      sortInfo.field === "createdAt" ? "date" : ""
    );
    setCandidateInviteList(sortedOrder);
  };

  const RecruiterActive = () => {
    if (userDetails.roleSubType !== "Contributor") {
      setReactiveopen(true);
    }
  };

  let headerdata1 = [
    {
      field: "uid",
      headerName: "Invite ID",
      flex: 1,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.uid}</div>;
      },
    },

    {
      field: "newFirstName",
      headerName: "First Name",
      flex: 1,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row?.newFirstName}</div>;
      },
    },
    {
      field: "newLastName",
      headerName: "Last Name",
      flex: 1,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.newLastName}</div>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.email}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer", textTransform: "capitalize" }}>{params.row.status}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Invited On",
      type: "date",
      flex: 0.9,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{moment(params.row.createdAt).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "targetScore",
      headerName: "Target",
      type: "number",
      flex: 0.9,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.targetScore}</div>;
      },
    },
    {
      field: "proportionalScore",
      headerName: "Score",
      type: "number",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.proportionalScore < 0 || params.row.proportionalScore === undefined) {
          return <div>--</div>;
        } else if (params.row.proportionalScore < params.row.targetScore) {
          return <div style={{ color: "#FF941C" }}>{params.row.proportionalScore}</div>;
        } else if (params.row.proportionalScore >= params.row.targetScore) {
          return <div style={{ color: "#28BB3F" }}>{params.row.proportionalScore}</div>;
        }
      },
    },
    {
      field: "testsTakenCount",
      headerName: "Completed",
      type: "number",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.testsTakenCount} / {params.row.selectedTestingRequirement.length}
          </div>
        );
      },
    },
  ];

  const InviteCandidateView = () => {
    // navigate("/inviteaddcandidates");
    navigate("/inviteaddcandidates", { state: { roledata: roleState?.roledetails } });
  };

  // handle row click of invite list of candidate
  const loadClickedInviteDetail = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setUserid(id);
      setInviteview(true);
      dispatch(role.inviteDetails(id));
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (inviteview && roleState?.invitedetails?.uid) navigate(generatePath("/invitecandidateview/:id", { id: userid }));
    // eslint-disable-next-line
  }, [inviteview && roleState?.invitedetails?.uid]);

  const truncate = (str) => {
    return str?.length > 30 ? str?.substring(0, 20) + "..." : str;
  };
  // console.log(fieldsData?.testingRequirement,"fieldsDatafieldsData");
  useEffect(() => {
    if (roleState?.roledetails) {
      setFieldsData(roleState?.roledetails);
    }
  }, [roleState?.roledetails]);

  /* Sorting the array of objects by weightage in descending order and then by skill in ascending order. */

  const handleSort = useMemo(() => {
    return fieldsData?.skillDetails?.sort((a, b) => {
      return (
        b.weightage - a.weightage ||
        authState?.skillsConfig[a.skill]?.fullName.localeCompare(authState?.skillsConfig[b.skill]?.fullName)
      );
    });
  }, [fieldsData?.skillDetails, authState?.skillsConfig]);

  let skillData = [];
  let skillWeightage = [];
  // eslint-disable-next-line
  (handleSort || []).map((item, i) => {
    skillData.push(item.skill);
    skillWeightage.push(item.weightage);
  });

  const handleChangeAddedBy = (value) => {
    setAddedBy(value);
    setShowReset(true);
    if (value.length === addedBy?.length) {
      setAddedBylength(0);
    } else {
      setAddedBylength(value.length);
    }
    setAddedBColor("#EBEFF8");
  };

  // Function to transform skill names to objects with additional details
  const transformSkills = (selectedSkills, skillsConfig) => {
    if (!selectedSkills || !skillsConfig) {
      return [];
    }

    return selectedSkills
      .map((skillName) => {
        const skillDetails = skillsConfig[skillName];
        if (skillDetails) {
          let id = skillName;
          if (skillDetails.accessCategory === "Custom" && skillDetails.resumeSkill) {
            id = skillDetails.resumeSkill;
          }
          return {
            id: id,
            displayName: skillDetails.fullName,
          };
        } else {
          // Handle the case where skill details are not found
          return null; // or any other appropriate value
        }
      })
      .filter(Boolean); // Remove any null values from the result
  };

  useEffect(() => {
    setSkillsOptions(transformSkills(roleState?.roledetails?.selectedSkills, authState?.skillsConfig));
  }, [roleState?.roledetails?.selectedSkills, authState?.skillsConfig]);

  const handleChangeSkills = (value) => {
    setSkillsArray(value);
    setShowReset(true);
    if (value.length === skillsArray?.length) {
      setSkillsArraylength(0);
    } else {
      setSkillsArraylength(value.length);
    }
    setSkillsBColor("#EBEFF8");
  };

  const handleChangeStrictlyMatch = (value) => {
    setstrictlyArray(value);
    setShowReset(true);
    if (value.length === strictlyArray?.length) {
      setStrictlyMatchlength(0);
    } else {
      setStrictlyMatchlength(value.length);
    }
    setStrictlyBColor("#EBEFF8");
  };

  const handleChangeCandidateCategory = (value) => {
    setShowReset(true);
    setCandidateCategoryBColor("#EBEFF8");

    // Check if "Never Invited to This Role" is selected
    const neverInvitedSelected = value.includes("Never Invited to This Role");

    // Check if "Never Invited to Any Role" is selected
    const neverInvitedBeforeSelected = value.includes("Never Invited to Any Role");

    const targetAchievedSelected = value.includes("Achieved Target Score");

    // If both are selected, keep only the last selected one
    if (neverInvitedSelected && neverInvitedBeforeSelected) {
      const lastSelected = value.pop();
      setCandidateCategoryArray([lastSelected]);
      setCandidateCategorylength(1);
    } else if (targetAchievedSelected && neverInvitedBeforeSelected) {
      const lastSelected = value.pop();
      setCandidateCategoryArray([lastSelected]);
      setCandidateCategorylength(1);
    } else {
      setCandidateCategoryArray(value);
      setCandidateCategorylength(value.length);
    }
  };

  const onSelectAllAddedBy = () => {
    setAddedBy(addedByFilter);
    setAddedBylength(0);
    setAddedBColor("");
    setShowReset(false);
  };

  const onClearAllAddedBy = () => {
    setAddedBy([]);
    setAddedBylength(0);
    setAddedBColor("");
    setShowReset(true);
  };

  const onSelectAllSkills = () => {
    setSkillsArray(skillsOptions.map((i) => i.id));
    setSkillsArraylength(0);
    setSkillsBColor("");
    setShowReset(false);
  };

  const onClearAllSkills = () => {
    setSkillsArray([]);
    setSkillsArraylength(0);
    setSkillsBColor("");
    setShowReset(true);
  };

  const onSelectAllStrictlyArray = () => {
    setstrictlyArray(strictlyFilter);
    setStrictlyMatchlength(0);
    setStrictlyBColor("");
    setShowReset(false);
  };

  const onClearAllStrictlyArray = () => {
    setstrictlyArray([]);
    setStrictlyMatchlength(0);
    setStrictlyBColor("");
    setShowReset(true);
  };

  const onClearAllcandidateCategory = () => {
    setCandidateCategoryArray([]);
    setCandidateCategorylength(0);
    setCandidateCategoryBColor("");
    setShowReset(true);
  };

  const circleChildCls = {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "1px solid #C4C4C4",
    display: "inline-block",
  };

  const headerData = [
    {
      field: "candidateId",
      headerName: "Candidate ID",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      flex: 1.5,
      disableColumnMenu: true,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "addedOn",
      headerName: "Added On",
      type: "date",
      align: "center",
      headerAlign: "center",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{moment(params.row.addedOn).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "matchLevel",
      headerName: "Skill Match",
      align: "center",
      headerAlign: "center",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        const matchLevel = params.value;

        // Function to create an array of 7 circle elements based on matchLevel
        const createCircles = (matchLevel) => {
          const circles = Array.from({ length: 7 }, (_, index) => {
            const circleStyle = {
              ...circleChildCls,
              backgroundColor: getCircleColor(index, matchLevel),
              marginRight: 4, // for gap
            };

            return <div key={index} style={circleStyle} />;
          });
          return circles;
        };

        // Function to determine circle color based on matchLevel
        const getCircleColor = (index, matchLevel) => {
          if (index < matchLevel) {
            if (matchLevel === 1) {
              return "#ff0000"; // Red for match level 1
            } else if (matchLevel >= 2 && matchLevel <= 4) {
              return "#ffa500"; // Orange for match levels 2, 3, 4
            } else {
              return "#6aa84f"; // Green for match levels 5, 6, 7
            }
          } else {
            return "transparent"; // Transparent for unfilled circles
          }
        };

        return <div style={{ display: "flex", justifyContent: "center" }}>{createCircles(matchLevel)}</div>;
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleSendInviteClick(params.row);
              }}
              disabled={sentInvites.includes(params.row.candidateId)}
              title="Quick Send Invite"
            >
              <MailOutlineIcon
                sx={
                  roleState?.roledetails?.status === "active"
                    ? sentInvites.includes(params.row.roleId)
                      ? { color: "grey !important", cursor: "not-allowed" }
                      : { color: "#1976d2 !important" }
                    : { color: "grey !important", cursor: "not-allowed" }
                }
                fontSize="large"
              />
            </IconButton>
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();

                if (roleState?.roledetails?.status === "active") {
                  setOpenMatchModal(true);
                  handleOpenJobProfile(params.row);
                } else {
                  handleAlertModal(params.row, "report");
                }
              }}
              title="Generate Profile Match Report"
            >
              <OpenInNewOutlinedIcon
                sx={{
                  color: roleState?.roledetails?.status === "active" ? "#1976d2 !important" : "grey !important",
                  cursor: roleState?.roledetails?.status === "active" ? "pointer" : "not-allowed",
                }}
                fontSize="large"
              />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleAlertModal = (value, type) => {
    setOpenAlertDialog(true);
    if (type === "invite") {
      setAlertText(<>This is an Inactive role. Please activate the role to send the invite.</>);
    } else {
      setAlertText(<>This is an inactive role. Please activate the role to generate the report.</>);
    }
  };
  const handleAlertDailog = () => {
    setOpenAlertDialog(false);
  };

  const onResetAllFilters = () => {
    setAddedBy(addedByFilter);
    setstrictlyArray(strictlyFilter);
    setCandidateCategoryArray(["Never Invited to This Role"]);
    setSkillsArray(skillsOptions.map((i) => i.id));
    setSearchText("");
    setShowReset(false);
    setAddedBColor("");
    setSkillsBColor("");
    setAddedBylength(0);
    setStrictlyBColor("");
    setCandidateCategoryBColor("");
    setStrictlyMatchlength(0);
    setCandidateCategorylength(0);
    setSkillsArraylength(0);
    setFirstFilter("all");
    setCustomDate("Custom Date Range...");
  };

  useEffect(() => {
    let payload = {
      roleId: id,
    };
    dispatch(auth.matchCandidates(payload));
  }, []);

  useEffect(() => {
    setAddedBy(addedByFilter);
  }, [addedByFilter]);

  useEffect(() => {
    setSkillsArray(skillsOptions.map((i) => i.id));
  }, [skillsOptions]);

  useEffect(() => {
    setstrictlyArray(strictlyFilter);
  }, [strictlyFilter]);

  useEffect(() => {
    setCandidateCategoryArray(["Never Invited to This Role"]);
  }, [candidateCategory]);

  useEffect(() => {
    setMatchingRows(authState.matchCandidatesSuccess);
  }, [authState.matchCandidatesSuccess]);

  const degreeMappings = {
    Other: "Any Degree/Diploma",
    Bachelors: "Bachelors Degree",
    Masters: "Master Degree",
    PhD: "PhD",
  };

  const degreeFilterName = degreeMappings[fieldsData?.degree] || "Required Degree";

  const experienceMappings = {
    1: "1 Year of Experience",
    2: "2 Years of Experience",
    3: "3 Years of Experience",
    4: "4 Years of Experience",
    5: "5 Years of Experience",
    6: "6 Years of Experience",
    7: "7 Years of Experience",
    8: "8 Years of Experience",
    9: "9 Years of Experience",
  };

  const experienceFilterName = experienceMappings[fieldsData?.yoe] || `${fieldsData?.yoe} Years of Experience`;

  // set initial selected values
  useEffect(() => {
    const dataArray = authState?.matchCandidatesSuccess;
    const uniqueAddedBy = [...new Set(dataArray?.map((i) => i.addedBy))];
    const sortedUniqueAddedBy = uniqueAddedBy.sort((a, b) => a?.localeCompare(b));
    setAddedByFilter(sortedUniqueAddedBy);
    setStrictlyFilter([degreeFilterName, experienceFilterName]);
    setCandidateCategory(["Never Invited to This Role", "Never Invited to Any Role", "Achieved Target Score"]);
  }, [authState?.matchCandidatesSuccess, degreeFilterName, experienceFilterName]);

  const handleSortChangeMatch = (sortInfo) => {
    setMatchUiSortModel([sortInfo]);

    let sortedOrder = [...matchingRows];

    if (sortInfo.sort) {
      const fieldType = sortInfo.field === "addedOn" ? "date" : "";
      sortedOrder = sortJson([...matchingRows], sortInfo.sort, sortInfo.field, fieldType);
    }

    setMatchingRows(sortedOrder);
  };

  // Appy search filter on Match candidate data
  const applySearchFilter = (rawData) => {
    let visibleKeys = [
      "addedBy",
      "addedOn",
      "candidateId",
      "degree",
      "email",
      "firstName",
      "jobTitle",
      "lastName",
      "maxScore",
      "matchLevel",
      "skills",
      "yoe",
      "location",
    ];
    let filteredData = applySearch(rawData, searchText, visibleKeys);
    return filteredData;
  };

  // Extract Filter Data
  const dataFilter = (rawData, filterData, key) => {
    // Make sure all filters are in lowercase
    filterData = filterData.map((i) => i.toLowerCase());
    return rawData.filter(
      // eslint-disable-next-line
      (item) => eval(`item.${key}`) && filterData.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
    );
  };

  const applyFilterOnData = () => {
    // Raw Data
    let rawData = [...(authState?.matchCandidatesSuccess ?? [])];

    if (rawData.length !== 0) {
      // AddedBy Filter
      rawData = dataFilter(rawData, addedBy, "addedBy");

      // Degree Filter
      if (strictlyArray?.includes(degreeFilterName)) {
        const rolesDegrees = fieldsData?.degree;

        rawData = rawData.filter((item) => {
          const candidateDegree = item.degree;

          // Check if the candidate's degree is included in the rolesDegrees
          if (rolesDegrees?.includes(candidateDegree)) {
            return true;
          }

          // Check if the candidate's degree is higher than any of the rolesDegrees
          const higherDegreeIndex = rolesDegrees?.findIndex((deg) => {
            // order of degrees ('Other' < 'Bachelors' < 'Masters' < 'PhD')
            const degreeOrder = { Other: 0, Bachelors: 1, Masters: 2, PhD: 3 };

            return degreeOrder[candidateDegree] > degreeOrder[deg];
          });

          return higherDegreeIndex !== -1;
        });
      }

      if (strictlyArray?.includes(experienceFilterName)) {
        const selectedYoe = fieldsData?.yoe;

        rawData = rawData.filter((item) => {
          const candidateYoe = parseInt(item.yoe); // Convert yoe to an integer for numerical comparison

          // Check if the candidate's yoe is included in the selectedYoe
          if (selectedYoe?.includes(candidateYoe?.toString())) {
            return true;
          }

          // Check if the candidate's yoe is greater than any of the selectedYoe
          const higherYoeIndex = selectedYoe?.findIndex((yoe) => candidateYoe > parseInt(yoe));

          return higherYoeIndex !== -1;
        });
      }

      // mapping function to check name of skills from role and convert it to label name
      const mapSkillName = (name) => {
        const configKeys = Object.keys(authState?.skillsConfig);
        const matchingKey = configKeys.find(
          (key) => authState?.skillsConfig[key]?.fullName?.toLowerCase() === name?.toLowerCase()
        );
        return matchingKey ? matchingKey : name; // Return matching key or original name if not found
      };

      // Convert selectedSkills
      const convertedSelectedSkills = skillsArray?.map((skill) => mapSkillName(skill));

      // Compare with skillsArray
      if (convertedSelectedSkills?.length) {
        rawData = rawData.filter((item) => {
          const result = convertedSelectedSkills.every((skill) =>
            item.skills?.some((itemSkill) => itemSkill?.toLowerCase().trim() === skill?.toLowerCase().trim())
          );
          return result;
        });
      }

      const filters = [];

      if (candidateCategoryArray?.includes("Never Invited to This Role")) {
        filters.push((item) => {
          let roleIdNotIn = !item.rolesInvitedTo.includes(fieldsData.uid);
          if (item.rolesInvitedTo.length === 0 || roleIdNotIn) {
            return item;
          }
        });
      }
      if (candidateCategoryArray?.includes("Never Invited to Any Role")) {
        filters.push((item) => item?.rolesInvitedTo?.length === 0);
      }

      if (candidateCategoryArray?.includes("Achieved Target Score")) {
        filters.push((item) => item?.targetAchieved);
      }

      // Apply filters
      if (filters.length > 0) {
        rawData = rawData.filter((item) => filters.every((filter) => filter(item)));
      }
    }

    return rawData;
  };

  // Set Candidate filtered List
  const setCandidateFilteredList = () => {
    let filteredData = applyFilterOnData();
    filteredData = applySearchFilter(filteredData);

    let fieldType, field;

    fieldType = matchUiSortModel[0].field === "addedOn" ? "date" : "";
    field = matchUiSortModel[0].field;

    if (matchUiSortModel[0].field === "jobRoleInvites") {
      field = "inviteCountTuple";
      fieldType = "tuple";
    }

    filteredData = sortJson([...filteredData], matchUiSortModel[0]?.sort, field, fieldType);

    // Apply additional filter based on the selected duration
    if (firstFilter === "Last 7 Days") {
      const last7Days = moment().subtract(7, "days").toDate();
      filteredData = filteredData.filter((item) => moment(item.addedOn).isAfter(last7Days));
    } else if (firstFilter === "Last 30 Days") {
      const last30Days = moment().subtract(30, "days").toDate();
      filteredData = filteredData.filter((item) => moment(item.addedOn).isAfter(last30Days));
    } else if (firstFilter === "Last 60 Days") {
      const last60Days = moment().subtract(60, "days").toDate();
      filteredData = filteredData.filter((item) => moment(item.addedOn).isAfter(last60Days));
    } else if (firstFilter === "Last 90 Days") {
      const last90Days = moment().subtract(90, "days").toDate();
      filteredData = filteredData.filter((item) => moment(item.addedOn).isAfter(last90Days));
    } else if (firstFilter === "custom date" && startDate && endDate) {
      // Apply custom date range filter
      filteredData = filteredData.filter(
        (item) => moment(item.addedOn).isSameOrAfter(startDate) && moment(item.addedOn).isSameOrBefore(endDate)
      );
    }

    setMatchingRows(filteredData);
  }; //End

  // Search Value side effect
  useEffect(() => {
    if (authState?.matchCandidatesSuccess?.length !== 0) {
      setCandidateFilteredList();
    }
    setTablePaginationPageNumber(0);
  }, [
    searchText,
    authState?.matchCandidatesSuccess,
    addedBy,
    strictlyArray,
    candidateCategoryArray,
    skillsArray,
    firstFilter,
    customDate,
  ]);

  const handleSearch = (value) => {
    let searchValue = value.target.value;
    searchValue = searchValue.replace(/,/g, "");
    setSearchText(searchValue);
    setShowReset(true);
  };

  useEffect(() => {
    if (searchText !== "") {
      setShowReset(true);
    } else {
      setShowReset(false);
    }
  }, [searchText]);

  const handleRowClickMatch = (row) => {
    const candidateId = row.candidateId;
    // Navigate to the candidate view with the candidateId
    window.open(`/candidateview/${candidateId}`, "_blank");
  };

  // eslint-disable-next-line
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [tablePaginationPageNumber, setTablePaginationPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (_, newPage) => {
    setPaginationPageNumber(newPage);
    setTablePaginationPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePaginationPageNumber(0);
    setPaginationPageNumber(1);
  };

  useEffect(() => {
    if (roleState?.roleCategories && fieldsData?.category) {
      const categoryMapping = roleState?.roleCategories;
      if (categoryMapping && Array.isArray(fieldsData?.category)) {
        const categoryDisplayValue = fieldsData?.category?.map((category) => categoryMapping[category]);
        setCategoryToDisplay(categoryDisplayValue);
      }
    }
  }, [roleState?.roleCategories, fieldsData?.category]);

  const firstFilterHandleChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === "custom date") {
      setCustomDateModalOpen(false);
      setCustomDateModalOpen(true);
      setFirstFilter(selectedValue);
      // console.log(selectedValue, "custom");
    }

    setFirstFilter(selectedValue);
    setShowReset(true);
  };

  const handleClearAll = () => {
    setCustomDate("Custom Date Range...");
    setCustomDateModalOpen(false);
    setFirstFilter("all");
  };

  const handleStartDateChange = (date) => {
    date.setHours(0, 0, 1, 0);
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    date.setHours(23, 59, 59, 999);
    setEndDate(date);
  };

  const handleCustumDateChange = (event) => {
    setCustomDateModalOpen(false);
    setCustomDate(startDate.toDateString() + " to " + endDate.toDateString());
    // setCustomDateFilter("custom date Filter");
  };

  const handleCandidateComparison = () => {
    setShowCandidateComparison(true);
    setShowInvitedCandidates(false);
    setShowMatchAndSearchCandidates(false);
  };

  const handleInvitedCandidates = () => {
    setShowCandidateComparison(false);
    setShowInvitedCandidates(true);
    setShowMatchAndSearchCandidates(false);
  };

  const handleMatchAndSearchCandidates = () => {
    setShowCandidateComparison(false);
    setShowInvitedCandidates(false);
    setShowMatchAndSearchCandidates(true);
  };

  const handleSendInviteClick = (row) => {
    dispatch(auth.checkCandidateInviteForRole({ candidateId: row.candidateId, roleId: id }));
    setSelectedRow(row);
    setShowLinearLoading(true);
  };

  const handleOpenJobProfile = (row) => {
    let payload = {
      candidateId: row.candidateId,
      jobRoleId: id,
    };
    dispatch(role.getCandidateJobRoleProfileToken(payload));
  };

  useEffect(() => {
    if (authState?.checkCandidateInviteForRoleSuccess) {
      setShowLinearLoading(false);
      if (roleState?.roledetails?.status === "active") {
        setOpenConfirm(true);
      } else {
        handleAlertModal(selectedRow, "invite");
      }
    }
  }, [authState?.checkCandidateInviteForRoleSuccess, roleState?.roledetails?.status]);

  useEffect(() => {
    if (authState?.checkCandidateInviteForRoleFailure) {
      setShowLinearLoading(false);
    }
  }, [authState?.checkCandidateInviteForRoleFailure]);

  useEffect(() => {
    if (roleState?.getCandidateJobRoleProfileTokenSuccess?.data?.profile) {
      window.open(roleState?.getCandidateJobRoleProfileTokenSuccess?.data?.profile);
      setOpenMatchModal(false);
    }
  }, [roleState?.getCandidateJobRoleProfileTokenSuccess?.data]);

  useEffect(() => {
    if (
      roleState?.getCandidateJobRoleProfileTokenFailure?.error === true ||
      roleState?.getCandidateJobRoleProfileTokenFailure.message
    ) {
      setOpenMatchModal(false);
    }
  }, [roleState?.getCandidateJobRoleProfileTokenFailure]);

  const handleClose = () => {
    setOpenConfirm(false);
  };

  const handleCloseMatchModal = () => {
    setOpenMatchModal(false);
  };

  const handleConfirm = () => {
    let payload = {
      candidates: [selectedRow.candidateId],
      jobRole: { uid: id },
      selectedTestingRequirement: fieldsData?.testingRequirement,
      useExistingInfoForProfileAndPresentation: true,
      shareResultsWithCandidates: false,
      testExpiresOn: moment().add(7, "days").hours(11).minutes(59).seconds(59).milliseconds(0).toISOString(),
      noteForCandidate: "",
      note: "",
    };

    dispatch(role.addInvite(payload));
    setSentInvites([...sentInvites, selectedRow.candidateId]);
    setOpenConfirm(false);
    setOpen(true);
    setsnacktext("Invite sent successfully");
  };

  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.replace(/,/g, "");
    setSearchTextInvited(searchValue);
  };

  useEffect(() => {
    if (roleState?.candidateinvitelist) {
      setCandidateFilteredListInvited();
    }
  }, [searchTextInvited]);

  const setCandidateFilteredListInvited = () => {
    let filteredData = applyFilterOnDataInvited();
    filteredData = applySearchFilterInvited(filteredData);

    if (selectedFilter === "invited") {
      // Apply filter for invited candidates
      filteredData = filteredData.filter((candidate) => candidate.isCompleted !== undefined);
    } else if (selectedFilter === "responded") {
      // Apply filter for responded candidates
      filteredData = filteredData.filter((candidate) => candidate.candidateResponded);
    } else if (selectedFilter === "completed") {
      // Apply filter for completed candidates
      filteredData = filteredData.filter((candidate) => candidate.isCompleted === 1);
    } else if (selectedFilter === "targetAchieved") {
      // Apply filter for candidates who achieved the target score
      filteredData = filteredData.filter((candidate) => candidate.proportionalScore >= candidate.jobRoleTargetScore);
    }

    filteredData = sortJson([...filteredData], uiSortModel[0]?.sort, uiSortModel[0]?.field);

    setCandidateInviteList(filteredData);
  };

  const applySearchFilterInvited = (rawData) => {
    let visibleKeys = [
      "jobRoleId",
      "candidateCreatedByDisplayName",
      "inviteCreatedBy",
      "createdAt",
      "candidateId",
      "email",
      "firstName",
      "jobRoleName",
      "lastName",
      "proportionalScore",
      "jobRoleSkillsArray",
      "jobRoleTargetScore",
      "uid",
    ];
    let filteredData = applySearch(rawData, searchTextInvited, visibleKeys);
    return filteredData;
  };

  const applyFilterOnDataInvited = () => {
    // Raw Data
    let rawData = [...(roleState?.candidateinvitelist ?? [])];

    if (rawData.length !== 0) {
    }

    return rawData;
  };

  return (
    <>
      <div>
        <NavBar />
        {showLinearLoading === true && (
          <div className={classes.loader}>
            <ColoredLinearProgress />
          </div>
        )}
        <Container maxWidth="xl" className={classes.container}>
          {!isAuthLoading ? (
            <Grid style={{ marginTop: "130px" }}>
              <Grid style={{ display: "flex" }}>
                <CustomBreadcrumbs
                  links={[
                    { name: "Home", path: "/home" },
                    { name: "Roles", path: "/rolelist" },
                    { name: `${fieldsData?.name || ""}` },
                  ]}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Grid className={classes.spaceflex}>
                    <Typography className={classes.roletext}>{fieldsData?.name}</Typography>
                    <Grid>
                      {fieldsData?.status === "draft" ? (
                        <Grid style={{ display: "flex", gap: "14px" }}>
                          <Button
                            data-rt-role-view="Editrole"
                            color="btnCls"
                            text={"Edit Role"}
                            disabled={userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor"}
                            onClick={EditRole}
                            width={199}
                            variant="outlined"
                          ></Button>
                          <Button
                            disabled={userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor"}
                            data-rt-role-view="Publishrole"
                            color={publishbtn === true ? "disablebtn" : "btn"}
                            // width="100%"
                            text="Publish Role"
                            width={199}
                            onClick={publishbtn === true ? null : PublishRole}
                          ></Button>
                        </Grid>
                      ) : (
                        <Grid>
                          <Button
                            data-rt-role-view={fieldsData?.status === "active" ? "deactivate" : "activate"}
                            color="btnCls"
                            text={fieldsData?.status === "active" ? "Deactivate Role" : "Activate Role"}
                            disabled={userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor"}
                            onClick={fieldsData?.status === "active" ? OpenDeleteRecruiter : RecruiterActive}
                            width={199}
                            variant="outlined"
                          ></Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <Grid item xs={12}>
                    <Grid style={{ marginTop: "20px" }}>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        Role Details
                      </Typography>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={7}>
                        <Grid container display={"flex"} justifyContent={"center"}>
                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}>Status</Typography>
                            <Typography
                              className={classes.roletype}
                              style={{
                                color: fieldsData?.status === "active" ? "#2F2F2F" : "#DD2E2E",
                                textTransform: "capitalize",
                              }}
                            >
                              {fieldsData?.status}
                            </Typography>
                          </Grid>

                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}> Role ID</Typography>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {fieldsData?.uid}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}> Role Name</Typography>
                            <Tooltip title={fieldsData?.name} placement="bottom-start">
                              <Typography
                                className={classes.roletype}
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: "#2F2F2F",
                                }}
                              >
                                {truncate(fieldsData?.name)}
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}>Role Category</Typography>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {categoryToDisplay.length > 0 ? categoryToDisplay : []}
                            </Typography>
                          </Grid>

                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}>Required Degree</Typography>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {fieldsData?.degree}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}>Years of Experience</Typography>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {fieldsData?.yoe} {fieldsData?.yoe > 1 ? "years" : "year"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}>Coding Languages</Typography>
                            <Tooltip
                              title={fieldsData?.codingLang ? fieldsData?.codingLang.sort().join(" / ") : ""}
                              placement="bottom-start"
                            >
                              <Typography
                                className={classes.roletype}
                                style={{ overflow: "hidden", textOverflow: "ellipsis", color: "#2F2F2F" }}
                              >
                                {codingLanguageDisplay(fieldsData?.codingLang ? fieldsData?.codingLang.sort() : [])}
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}>Created By</Typography>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {typeof fieldsData?.createdBy === "string"
                                ? fieldsData?.createdBy
                                : fieldsData?.createdBy?.displayName}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}>Created On</Typography>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {moment(fieldsData?.createdAt).format("MMM DD, YYYY")}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} style={{ marginTop: "20px" }}>
                            <Typography className={classes.roletype1}>Selectivity</Typography>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              Top {fieldsData?.selectivity}%
                            </Typography>
                          </Grid>
                          <Grid item xs={8} style={{ marginTop: "20px", display: "flex" }}>
                            <Grid>
                              <Typography className={classes.roletype1}>Internal Notes</Typography>
                              {(fieldsData?.notesLog || []).map((note, i) => {
                                return (
                                  <Grid key={i}>
                                    {note.msg && (
                                      <Typography
                                        className={classes.roletype}
                                        style={{ color: "#2F2F2F", paddingTop: "0px" }}
                                      >
                                        {note.msg}
                                      </Typography>
                                    )}
                                    <Typography
                                      className={classes.roletype}
                                      style={{ color: "#2F2F2F", paddingTop: "0px" }}
                                    >
                                      {note.what} by {note.who.displayName} on {TimeZonePT(note.when)}
                                    </Typography>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={0.75}></Grid>
                      <Grid item xs={12} sm={9} md={6} lg={4}>
                        <div className={classes.graphScoreMeterContainer}>
                          <GraphScoreMeter
                            threeLineWithLink={true}
                            line1="target score"
                            targetScore={fieldsData?.targetScore}
                            scoreMeterCls={classes.scoreMeter}
                            className={`${classes.scoreMeter}`}
                          ></GraphScoreMeter>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <Grid>
                    <Grid style={{ marginTop: "20px" }}>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        Testing Requirements
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.roletype1}>Sections to Test</Typography>
                            {(fieldsData?.testingRequirement || []).map((item, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                                  {item === "Presentation" ? "Behavioral" : item}
                                </Typography>
                              );
                            })}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.roletype1}>Override Permitted</Typography>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {fieldsData?.isOverridePermitted === false ? "No" : "Yes"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={5} style={{ marginTop: "20px" }}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography className={classes.roletype1}>Skills to Test</Typography>
                            {(handleSort || []).map((item, i) => {
                              return (
                                <>
                                  <Typography key={i} className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                    {/* {item.skill} */}
                                    {authState?.skillsConfig[item.skill]?.fullName}
                                  </Typography>
                                </>
                              );
                            })}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.roletype1} sx={{ textAlign: "end" }}>
                              Assigned Weight
                            </Typography>
                            {(handleSort || []).map((item, i) => {
                              return (
                                <>
                                  <Typography key={i} className={classes.roletypeNum}>
                                    {item.weightage}%
                                  </Typography>
                                </>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  {fieldsData?.status === "draft" ? null : (
                    <Grid sx={{ display: "flex", justifyContent: "space-around" }}>
                      <MuiButton
                        className={showCandidateComparison ? classes.muiBtnActive : classes.muiBtn}
                        onClick={handleCandidateComparison}
                      >
                        Candidate Comparison Chart
                      </MuiButton>
                      <MuiButton
                        className={showInvitedCandidates ? classes.muiBtnActive : classes.muiBtn}
                        onClick={handleInvitedCandidates}
                      >
                        Invited Candidates
                      </MuiButton>
                      <MuiButton
                        className={showMatchAndSearchCandidates ? classes.muiBtnActive : classes.muiBtn}
                        onClick={handleMatchAndSearchCandidates}
                      >
                        Match and Search Candidates
                      </MuiButton>
                    </Grid>
                  )}
                  {authState?.matchCandidatesSuccess?.length > 0 &&
                    showMatchAndSearchCandidates &&
                    fieldsData?.status !== "draft" && (
                      <>
                        <Grid sx={{ mt: 4 }}>
                          <Grid
                            style={{
                              display: "flex",
                              gap: "14px",
                              paddingBottom: "20px",
                              paddingTop: "15px",
                            }}
                          >
                            <SearchBar
                              placeholdertitle="Search Candidate"
                              onChange={(value) => handleSearch(value)}
                              value={searchText}
                              width="310px"
                            />
                            <CheckSelectMenu
                              Title={"Added By"}
                              Data={addedByFilter}
                              HandleChangeValue={(value) => handleChangeAddedBy(value)}
                              MenuProps={addedByMenuProps}
                              width={addedBylength === 0 ? 112 : 144}
                              text={"Addedby"}
                              checkLength={addedBylength}
                              BgColor={addedBylength !== 0 && addedBColor}
                              selectValue={addedBy}
                              OnSelectAll={onSelectAllAddedBy}
                              OnClearAll={onClearAllAddedBy}
                            />
                            <CheckSelectMenu
                              Title={"Minimum Requirement"}
                              Data={strictlyFilter}
                              HandleChangeValue={(value) => handleChangeStrictlyMatch(value)}
                              MenuProps={strictlyMatchMenuProps}
                              width={strictlyMatchlength === 0 ? 210 : 230}
                              checkLength={strictlyMatchlength}
                              BgColor={strictlyMatchlength !== 0 && strictlyBColor}
                              selectValue={strictlyArray}
                              OnSelectAll={onSelectAllStrictlyArray}
                              OnClearAll={onClearAllStrictlyArray}
                            />
                            <CheckSelectMenu
                              Title="Candidate Category"
                              Data={candidateCategory}
                              HandleChangeValue={(value) => handleChangeCandidateCategory(value)}
                              MenuProps={candidateCategoryMenuProps}
                              width={candidateCategorylength === 0 ? 186 : 210}
                              checkLength={candidateCategorylength}
                              BgColor={candidateCategorylength !== 0 && candidateCategoryBColor}
                              selectValue={candidateCategoryArray}
                              // OnSelectAll={onSelectAllcandidateCategory}
                              OnClearAll={onClearAllcandidateCategory}
                            />
                            <SelectWithCheckbox
                              title="Skills"
                              options={skillsOptions}
                              handleSelectOption={(value) => handleChangeSkills(value)}
                              MenuProps={skillsFilterMenuProps}
                              width={skillsArraylength === 0 ? 85 : 105}
                              checkLength={skillsArraylength}
                              BgColor={skillsArraylength !== 0 && skillsBColor}
                              defaultValue={skillsArray}
                              handleSelectAll={onSelectAllSkills}
                              handleClearAll={onClearAllSkills}
                              disabledCapitalize
                            />
                            <Box>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={firstFilter}
                                  label="Filter by Duration"
                                  onChange={firstFilterHandleChange}
                                  size="small"
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    "& .MuiSelect-select": {
                                      background: "transparent !important",
                                      border: "1px solid #787878 !important",
                                      padding: "8px 12px",
                                    },
                                  }}
                                >
                                  <MenuItem value={"all"}>All Dates</MenuItem>
                                  <MenuItem value={"Last 7 Days"}>Last 7 Days</MenuItem>
                                  <MenuItem value={"Last 30 Days"}>Last 30 Days</MenuItem>
                                  <MenuItem value={"Last 60 Days"}>Last 60 Days</MenuItem>
                                  <MenuItem value={"Last 90 Days"}>Last 90 Days</MenuItem>
                                  <MenuItem value={"custom date"}>{customDate}</MenuItem>
                                  {firstFilter === "custom date" && (
                                    <MenuItem value={"all"}>
                                      <Button
                                        text="Clear Custom Date Range"
                                        color="btn1"
                                        onClick={(e) => {
                                          handleClearAll();
                                        }}
                                      />
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                            <Modal
                              open={isCustomDateModalOpen}
                              onClose={handleClearAll}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Paper
                                style={{
                                  width: "auto",
                                  padding: 16,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      components={{
                                        OpenPickerIcon: AccessTimeIcon,
                                      }}
                                      renderInput={(props) => (
                                        <div>
                                          <Typography sx={{ marginBottom: "10px" }}>Select Start Date</Typography>
                                          <TextField {...props} placeholder="Select Start Date" />
                                        </div>
                                      )}
                                      value={startDate}
                                      onChange={handleStartDateChange}
                                      maxDate={endDate}
                                    />
                                  </LocalizationProvider>
                                  <div
                                    style={{
                                      marginLeft: "20px",
                                    }}
                                  ></div>
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      components={{
                                        OpenPickerIcon: AccessTimeIcon,
                                      }}
                                      renderInput={(props) => (
                                        <div>
                                          <Typography sx={{ marginBottom: "10px" }}>Select End Date</Typography>

                                          <TextField {...props} placeholder="Select End Date" />
                                        </div>
                                      )}
                                      views={["year", "month", "day"]}
                                      onChange={handleEndDateChange}
                                      minDate={startDate}
                                      maxDate={new Date()}
                                      value={endDate}
                                      showButtons={false}
                                      toolbarTitle="Select End Date"
                                    />
                                  </LocalizationProvider>
                                </div>
                                <Button
                                  sx={{ mt: 2 }}
                                  variant="contained"
                                  color="btn"
                                  onClick={handleCustumDateChange}
                                  style={{ marginTop: "16px" }}
                                  disabled={startDate === null}
                                  text="Apply"
                                />
                              </Paper>
                            </Modal>
                            {showReset === true && (
                              <div style={{ minWidth: "140px" }}>
                                <Button
                                  sx={{
                                    float: "right",
                                    textTransform: "none",
                                    color: "#2F2F2F",
                                  }}
                                  variant="text"
                                  endIcon={<CloseIcon />}
                                  text="Reset Filters"
                                  onClick={onResetAllFilters}
                                />
                              </div>
                            )}
                          </Grid>
                          <Grid sx={{ mt: 1 }}>
                            <GridTable
                              columns={headerData}
                              rows={matchingRows}
                              checkbox={false}
                              getRowId={(matchingRows) => matchingRows.candidateId}
                              onResetFilters={onResetAllFilters}
                              height="calc(100vh - 450px)"
                              handleRowClick={handleRowClickMatch}
                              onSortChange={handleSortChangeMatch}
                              sortModel={matchUiSortModel}
                              showPagination={true}
                              tablePaginationPageNumber={tablePaginationPageNumber}
                              setTablePaginationPageNumber={setTablePaginationPageNumber}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                              rowsPerPage={rowsPerPage}
                              setRowsPerPage={setRowsPerPage}
                              handleChangePage={handleChangePage}
                            />
                            <div style={{ display: "flex", marginTop: "-5px", marginLeft: "-10px" }}>
                              <CustomPagination
                                TotalCount={matchingRows.length}
                                rows={matchingRows || []}
                                tablePaginationPageNumber={tablePaginationPageNumber}
                                setTablePaginationPageNumber={setTablePaginationPageNumber}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                handleChangePage={handleChangePage}
                                nextIconButtonProps={{ style: { display: "none" } }}
                                backIconButtonProps={{ style: { display: "none" } }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  {fieldsData?.status === "draft" ? null : (
                    <Grid>
                      {showInvitedCandidates && (
                        <Grid sx={{ mt: 6 }}>
                          <>
                            {roleState?.candidateinvitelist && roleState?.candidateinvitelist.length > 0 ? (
                              <>
                                <Grid className={classes.stats}>
                                  <SearchBar
                                    placeholdertitle="Search Candidate"
                                    onChange={(value) => onChangeSearch(value)}
                                    value={searchTextInvited}
                                    width="310px"
                                  />
                                  <Grid
                                    className={selectedFilter === "invited" ? classes.SelectedCard : classes.card}
                                    onClick={() => handleFilterClick("invited")}
                                  >
                                    <Typography className={classes.cardNumber}>{invitedCandidatesCount}</Typography>
                                    <Typography className={classes.cardtext}>Invited</Typography>
                                  </Grid>
                                  <Grid
                                    className={selectedFilter === "responded" ? classes.SelectedCard : classes.card}
                                    onClick={() => handleFilterClick("responded")}
                                  >
                                    <Typography className={classes.cardNumber}>{respondedCandidatesCount}</Typography>
                                    <Typography className={classes.cardtext}>Responded</Typography>
                                  </Grid>
                                  <Grid
                                    className={selectedFilter === "completed" ? classes.SelectedCard : classes.card}
                                    onClick={() => handleFilterClick("completed")}
                                  >
                                    <Typography className={classes.cardNumber}>{completedCandidatesCount}</Typography>
                                    <Typography className={classes.cardtext}>Submitted</Typography>
                                  </Grid>
                                  <Grid
                                    className={
                                      selectedFilter === "targetAchieved" ? classes.SelectedCard : classes.card
                                    }
                                    onClick={() => handleFilterClick("targetAchieved")}
                                  >
                                    <Typography className={classes.cardNumber}>
                                      {targetAchievedCandidatesCount}
                                    </Typography>
                                    <Typography className={classes.cardtext}>Target Achieved</Typography>
                                  </Grid>
                                </Grid>
                                <Grid sx={{ mt: 3 }}>
                                  <GridTable
                                    columns={headerdata1}
                                    rows={candidateInviteList || []}
                                    checkbox={false}
                                    getRowId={(row) => row._id || 0}
                                    height="calc(100vh - 450px)"
                                    dispatchValue={role.candidateinviteList}
                                    handleRowClick={loadClickedInviteDetail}
                                    onSortChange={handleSortChange}
                                    sortModel={uiSortModel}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <Grid
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  textAlign: "center",
                                  backgroundColor: "#FFFBF6",
                                  padding: "15px",
                                  mt: 4,
                                  height: "calc(100vh - 450px)",
                                }}
                              >
                                <Typography
                                  className={classes.roletype1}
                                  style={{ color: "#2F2F2F", paddingBottom: "15px" }}
                                >
                                  No invited candidates yet.
                                </Typography>
                                {roleState?.roledetails?.status === "active" && (
                                  <Button
                                    data-rt-role-view="Sendinvites"
                                    color="btnCls"
                                    text="Send Invites"
                                    // type="submit"
                                    onClick={InviteCandidateView}
                                    variant="outlined"
                                    width={199}
                                  ></Button>
                                )}
                              </Grid>
                            )}
                          </>
                        </Grid>
                      )}
                      {showCandidateComparison && fieldsData?.status !== "draft" && (
                        <Grid container>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={10}>
                            {fieldsData?.testingRequirement && (
                              <CandidateComparison
                                sectionToTest={fieldsData?.testingRequirement}
                                skill={skillData}
                                skillWeightage={skillWeightage}
                              />
                            )}
                          </Grid>
                          <Grid item xs={1}></Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Container>
        {!isAuthLoading ? <Footer /> : null}
        <DeactivateModel
          open={opendialog}
          setOpen={setOpendialog}
          DeleteListData={(values) => DeactivateRole(values)}
          Title={"Deactivate Role"}
          Text={
            "An inactive role will no longer be available for inviting candidates. Role deactivation is recommended for fulfilled roles or no longer available roles. Role reactivation can be done immediately. To continue, please provide a reason below."
          }
          deactiveText="Why are you deactiving this role?"
          Deactivebtn={deactivebtn}
        />
        <ReactiveModel
          open={reactiveopen}
          OnReactive={handleReactive}
          onClose={() => setReactiveopen(false)}
          Text={
            <span>
              Setting a role as active allows recruiters to invite candidates to test for this role. Are you sure you
              want to activate?
            </span>
          }
          Activatebtn={activebtn}
        />
      </div>
      <SimpleSnackbar open={open} setOpen={setOpen} text={snacktext} />
      <ReusableDialog
        open={openConfirm}
        onClose={handleClose}
        title={authState?.checkCandidateInviteForRoleSuccess?.length > 0 ? "Warning" : "Confirm"}
        content={
          <>
            {authState?.checkCandidateInviteForRoleSuccess?.length > 0 && (
              <Typography sx={{ mb: 2 }}>
                There is an existing invite{" "}
                <span className={classes.boldText}>
                  {" "}
                  <Link
                    href={`/invitecandidateview/${authState?.checkCandidateInviteForRoleSuccess[0]?.inviteId}`}
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                  >
                    {authState?.checkCandidateInviteForRoleSuccess[0]?.inviteId}
                  </Link>{" "}
                </span>{" "}
                created on{" "}
                <span className={classes.boldText}>
                  {moment(authState?.checkCandidateInviteForRoleSuccess[0]?.createdAt).format("DD MMM, YYYY")}
                </span>{" "}
                for this candidate and role.
              </Typography>
            )}
            <Typography>
              {authState?.checkCandidateInviteForRoleSuccess?.length > 0
                ? "Do you still want to send the assessment invite to"
                : "Are you sure you want to send assessment invite to"}{" "}
              <span className={classes.boldText}>
                {selectedRow?.firstName} {selectedRow?.lastName} ({selectedRow?.candidateId})
              </span>{" "}
              for{" "}
              <span className={classes.boldText}>
                {fieldsData?.name} ({fieldsData?.uid})
              </span>
              ?
            </Typography>
          </>
        }
        actions={[
          { onClick: handleClose, color: "btnCls1", variant: "outlined", text: "Cancel" },
          { onClick: handleConfirm, color: "btn1", autoFocus: true, text: "Confirm" },
        ]}
      />
      <ReusableDialog
        open={openAlertDialog}
        onClose={handleAlertDailog}
        title="Inactive Role"
        content={<Typography>{alertText}</Typography>}
        actions={[{ onClick: handleAlertDailog, color: "btn1", autoFocus: true, text: "Ok" }]}
      />
      <Modal open={openMatchModal} onClose={handleCloseMatchModal}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AutoAwesomeIcon sx={{ color: "#1976d2 !important", mr: 2 }} className={classes.flashingIcon} />
            <Typography variant="body1">Generating Profile Match Report</Typography>
            <span className={classes.dotFlashing}></span>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RoleView;
