import moment from "moment";
import store from "../redux/store";
import authState from "../redux/actions/auth";
import quiz from "../redux/actions/quiz";
// import {orgDetails} from '../org'

var selectedAnswers = [];
var progess = 0;

function calculateElapsedTime(remainingTime, state) {
  if (state?.config?.Time_Mins) {
    let totalSeconds = state?.config?.Time_Mins * 60;
    return remainingTime < totalSeconds ? totalSeconds - remainingTime : totalSeconds - 1;
  }
  return 0;
}

function removeAnswers() {
  selectedAnswers = [];
  localStorage.removeItem("selectedAnswers");
}

function insertAnswer(ansObj, total) {
  if (ansObj && total) {
    selectedAnswers.push(ansObj);
    if (selectedAnswers.length === 0 && localStorage.selectedAnswer) {
      selectedAnswers = localStorage.selectedAnswers && JSON.parse(localStorage.selectedAnswers);
    }
    if (selectedAnswers) {
      selectedAnswers = getUnique(selectedAnswers, "questionID");
    }
    if (selectedAnswers.length !== 0) {
      selectedAnswers = selectedAnswers.filter((obj) => {
        return obj && obj.answer && obj.answer.length !== 0;
      });
      localStorage.selectedAnswers = selectedAnswers;
    }
    if (selectedAnswers) {
      progess = (100 * selectedAnswers.length) / total;
      progess = Math.round(progess);
      // console.log("progess", progess);
    }
  }
}

function getUnique(arr, comp) {
  const unique = arr
    .map((e) => e[comp])

    .map((e, i, final) => final.lastIndexOf(e) === i && i)

    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
}

function getObject(key, value, array) {
  let obj = null;
  if (array) {
    obj = array.find((item) => {
      return item[key] === value;
    });
  }
  return obj;
}

function getObjectThatContains(key, value, array) {
  let obj = null;
  if (array) {
    obj = array.find((item) => {
      return item[key].includes(value);
    });
  }
  return obj;
}

function getIdx(key, value, array) {
  if (array) {
    var ln = array.length;
    for (var i = 0; i < ln; i++) {
      if (array[i][key] === value) {
        return i;
      }
    }
  }
  return -1;
}

function saveQuestionWiseAnswers(questionId, answers) {
  // console.log('saveQuestionWiseAnswers: ', questionId, answers);
}

const ImagebaseUrl = "https://d2po7vz9ilj7b0.cloudfront.net/";

function TimeZonePT(datevalue) {
  const date = moment(datevalue);
  // Fk cleanup comments july 2023
  // const pst = date.toLocaleString('en-US', {
  //   timeZone: 'America/Los_Angeles',
  //   month: 'short',
  //   day: '2-digit',
  //   hour: '2-digit',
  //   minute: '2-digit',

  // });
  // return pst
  return date.format("MMM D YYYY, HH:mm a zz");
}

function getTestExpiryDate(date, daysFromNow) {
  let then = moment(date).add(daysFromNow, "days").hours(11).minutes(59).seconds(59).milliseconds(0);
  return then;
}

//sets the optional value for a field
function setOptValue(val) {
  if (!val || val.length === "") {
    return "None";
  } else {
    return val;
  }
}

/**
 * Sort Json Object
 * @param {*} json
 * @param {*} order
 * @param {*} key
 * @returns
 */
const sortJson = (json, order, key, fieldType = "") => {
  if (order.toLowerCase() === "asc") {
    return json.sort((a, b) => {
      if (fieldType === "date") {
        return (
          Object.hasOwn(a, key) &&
          Object.hasOwn(b, key) &&
          moment(a[key]).format("YYYYMMDD") - moment(b[key]).format("YYYYMMDD")
        );
      }

      if (fieldType === "tuple") {
        let aVal = a[key][1] === 0 ? 0 : a[key][0] / a[key][1];
        let bVal = b[key][1] === 0 ? 0 : b[key][0] / b[key][1];

        if (aVal > bVal) {
          return 1;
        }
        if (aVal < bVal) {
          return -1;
        }
        if (a[key][1] > b[key][1]) {
          return 1;
        }
        return -1;
      }

      if (Number.isInteger(parseInt(a[key]))) {
        return Object.hasOwn(a, key) && Object.hasOwn(b, key) && a[key] - b[key];
      }
      return Object.hasOwn(a, key) && Object.hasOwn(b, key) && a[key].toString().localeCompare(b[key].toString());
    });
  }

  return json.sort((a, b) => {
    if (fieldType === "date") {
      return (
        Object.hasOwn(a, key) &&
        Object.hasOwn(b, key) &&
        moment(b[key]).format("YYYYMMDD") - moment(a[key]).format("YYYYMMDD")
      );
    }

    if (fieldType === "tuple") {
      let aVal = a[key][1] === 0 ? 0 : a[key][0] / a[key][1];
      let bVal = b[key][1] === 0 ? 0 : b[key][0] / b[key][1];

      if (aVal > bVal) {
        return -1;
      }
      if (aVal < bVal) {
        return 1;
      }
      if (a[key][1] > b[key][1]) {
        return -1;
      }
      return 1;
    }

    if (Number.isInteger(parseInt(a[key]))) {
      return Object.hasOwn(a, key) && Object.hasOwn(b, key) && b[key] - a[key];
    }
    return Object.hasOwn(a, key) && Object.hasOwn(b, key) && b[key].toString().localeCompare(a[key].toString());
  });
}; //End

// search for a set of words in an object.
function searchObj(obj, words, searchKeys) {
  // Extract visible keys only
  let objVisibleKeys = {};

  for (let key of searchKeys) {
    objVisibleKeys[key] = obj[key];
  }

  const valString = Object.values(objVisibleKeys).join().toLowerCase();

  return words.map((word) => valString.includes(word)).reduce((i, j) => i && j);
}

//apply search string on a set of data
function applySearch(data, searchString, searchKeys) {
  searchString = searchString.replace(/,/g, "");
  // searchString = searchString.replace(/  +/g, ' ').trim();

  const searchWords = searchString.toLowerCase().split(/\s+/);
  return data?.filter((item) => searchObj(item, searchWords, searchKeys));
}

function logout() {
  store.dispatch(authState.clearState());
  localStorage.removeItem("token1");
  localStorage.removeItem("userid");
  localStorage.removeItem("user_details");
  localStorage.removeItem("uid");
  localStorage.removeItem("nextMaintenanceCheck");
  store.dispatch(quiz.clearState());
  // window.location.href = "/"
}

function getDomainDetails(url) {
  let domainName = new URL(url);
  domainName = domainName.hostname.replace("www.", "");
  domainName = domainName.split(".")[0];
  domainName = domainName.trim().toLowerCase();
  // console.log(domainName)

  return { subdomainFromUrl: domainName };

  // if(domainName === '' || !Object.hasOwn(orgDetails,domainName) || domainName === 'yourtechscore' || domainName === 'localhost') {
  //   return {...orgDetails['default'],subdomainFromUrl:domainName};
  // }
  // else {
  //   return {...orgDetails[domainName],subdomainFromUrl:domainName};
  // }
}

// Display coding languages for invite jobrole
function codingLanguageDisplay(languages, arrLen = 3) {
  let langs = [...languages];
  langs = langs.sort();

  langs = langs.splice(0, arrLen);

  let visibleCnt = 0;
  let str = "";

  for (let i in langs) {
    str += langs[i];
    if (str.length <= 15) {
      if (i == langs.length - 1) {
        visibleCnt = +i + 1;
        break;
      }
      continue;
    } else {
      visibleCnt = +i;
      break;
    }
  }

  if (languages.length === 1 && visibleCnt === 1) {
    return langs.splice(0, visibleCnt).toString();
  }

  return languages.length === visibleCnt
    ? langs.splice(0, visibleCnt).join(" / ")
    : langs.splice(0, visibleCnt).join(" / ") + " ...";
}

export const transformOptions = (options) => {
  let updatedOptions = [];
  if (Array.isArray(options)) {
    updatedOptions = options.map((ele) => {
      if (typeof ele === "object") {
        return { id: ele?.key, displayName: ele?.displayName };
      } else {
        return { id: String(ele), displayName: String(ele) };
      }
    });
  } else if (options && typeof options === "object") {
    updatedOptions = Object.entries(options).map(([key, value]) => ({ id: key, displayName: value }));
  }
  return updatedOptions;
};

export const checkCameraAndMicrophonePermission = async (isStream, stream = undefined) => {
  try {
    // console.log("checkCameraAndMicrophonePermission:", isStream, stream);
    // Initilize camera and mic as false
    let returnObj = {
      camera: false,
      mic: false,
    };

    // Check if stream available
    if (isStream) {
      // Check if video tracks available
      if (stream && stream.getVideoTracks().length > 0) {
        returnObj["camera"] = true;
      } else {
        returnObj["camera"] = false;
      }

      // Check if audio tracks available
      if (stream && stream.getAudioTracks().length > 0) {
        returnObj["mic"] = true;
      } else {
        returnObj["mic"] = false;
      }
    } else {
      // If stream is not available then create new stream to check video and audio tracks
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      // console.log("If stream is not available then create new stream to check video and audio tracks");
      mediaStream.getTracks().forEach((track) => track.stop());
      returnObj["camera"] = true;
      returnObj["mic"] = true;
    }

    return returnObj;
  } catch (error) {
    console.error("Error requesting camera permission:", error);
    return {
      camera: false,
      mic: false,
    };
  }
};

export const checkGeolocationPermission = async () => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          permission: true,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        console.error("Error requesting geolocation permission:", error);
        resolve({
          permission: false,
          latitude: null,
          longitude: null,
        });
      }
    );
  });
};

//checks if two dates are on the same day.
export const isSameDay = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

// adds a specified number of days to a date and returns the new date.
export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// function will return true false if maintenece is active or not we will send start date and end date
export const isMaintenanceHappening = (maintenanceDetails) => {
  if (!maintenanceDetails) return false;

  const currentDate = new Date();
  const startDate = new Date(maintenanceDetails.startDate);
  const endDate = new Date(maintenanceDetails.endDate);

  return currentDate >= startDate && currentDate <= endDate;
};

export {
  setOptValue,
  getTestExpiryDate,
  removeAnswers,
  insertAnswer,
  progess,
  getObjectThatContains,
  getUnique,
  getObject,
  getIdx,
  saveQuestionWiseAnswers,
  calculateElapsedTime,
  TimeZonePT,
  ImagebaseUrl,
  sortJson,
  applySearch,
  logout,
  getDomainDetails,
  codingLanguageDisplay,
};
